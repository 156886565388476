import request from "../../lib/axios/request";
import { IApiResponse } from "../../utils/models/common";
import { config } from "src/utils";
import { ICommonModule, IConfigData, IFile, IPresignData } from "./commonModule.model";
import { isCSV } from "src/utils/helperFunction";
import axios from "axios";
import { Configurations, StoragePath } from "src/utils/constants/constant";

const HTTP_STATUS_BAD_REQUEST = 400;
class CommonModuleService {
  ENDPOINT = config.baseApiMasters + "/common";

  public searchCommonModuleData = async (
    data: any
  ): Promise<IApiResponse<ICommonModule>> => {
    const url = `${this.ENDPOINT}/address`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  getPresignedURL = async (data: IPresignData): Promise<any> => {
    try {
      const url = `${this.ENDPOINT}/presigned-url`;
      return request({ url, method: 'GET', params: data });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  s3FileUpload = async (file:IFile, type: StoragePath, bucket?: string) => {
    if (!file) {
      return { data: { fileUrl: null }, status: HTTP_STATUS_BAD_REQUEST };
    }
    const params = {
      file_name: file.name,
      mime_type: file.type,
      bucket_name: bucket ? bucket : process.env.REACT_APP_NEXT_PUBLIC_s3_public_bucket,
      storage_path: type
    };
    if (file.type === '') {
      if (isCSV(file.name)) {
        params.mime_type = 'text/csv';
      }
    }
    try {
      const response = await this.getPresignedURL(params);
      const data = response.data.data;
      const signedRequest = data.preSignedUrl;
      const fileUrl = data.fileName;
      if (signedRequest != null && signedRequest !== undefined) {
        const options = { headers: { 'Content-Type': params.mime_type } };
        const s3response = await axios.put(signedRequest, file, options);
        return { data: { fileUrl }, status: s3response.status };
      }
      return { data: { fileUrl: null }, status: HTTP_STATUS_BAD_REQUEST };
    } catch (error:any) {
      return {
        data: error.response,
        status: error.response && error.response.status
      };
    }
  };

  uploadFileFromFormData = async (
    valuesData:any,
    storagePath=StoragePath.DEFAULT
  ) => {
    let result = valuesData;
    if (valuesData instanceof File) {
      const fileData = await this.s3FileUpload(valuesData ,storagePath);
      if (fileData.data?.fileUrl) {
        result = fileData.data.fileUrl;
      } else {
        throw new Error('Failed to upload file. Please try again later.');
      }
    }
    else if (Array.isArray(valuesData)) {
      for (let index = 0; index < valuesData.length; index++) {
        result[index] = await this.uploadFileFromFormData(
          valuesData[index],
          storagePath
        );
      }
    }
    else if (
      typeof valuesData === 'object' &&
      !(valuesData instanceof Date || valuesData === null)
    ) {
      for (const key in valuesData) {
        if (Object.prototype.hasOwnProperty.call(valuesData, key)) {
          result[key] = await this.uploadFileFromFormData(
            valuesData[key],
            storagePath
          );
        }
      }
    }
    return result;
  };

  public getConfigurationsDetailsByCode =async (data:Configurations[]):Promise<IApiResponse<IConfigData>> => {
    const url =`${this.ENDPOINT}/getConfigurationDetailsByCodes`;
    return request({url,method:"POST",data}).then((res)=>{
      return res.data
    })
  }
}

export default new CommonModuleService();
