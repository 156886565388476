import request from "src/lib/axios/request";
import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import { IIncomeDetailsRecord } from "./income.model";

class IncomeService {
  ENDPOINT = config.baseApiIncome;

  public searchIncomeData = async (
    data: IIncomeDetailsRecord | URLSearchParams
  ): Promise<IApiResponse<IIncomeDetailsRecord>> => {
    const url = `${this.ENDPOINT}/invoices/get-income`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public incomeCsvEmail = async (
    data: IIncomeDetailsRecord | URLSearchParams
  ): Promise<IApiResponse<IIncomeDetailsRecord>> => {
    const url = `${this.ENDPOINT}/invoices/income-csv-email`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public incomeTellyReportCsvEmail = async (
    data: IIncomeDetailsRecord | URLSearchParams
  ): Promise<IApiResponse<IIncomeDetailsRecord>> => {
    const url = `${this.ENDPOINT}/invoices/income-tally-report`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
}

export default new IncomeService();
