import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICityRecord } from "src/pages/city/city.model";
import cityService from "src/services/city/city.service";

export const searchCityData = createAsyncThunk(
  "city/searchCityData",
  async (data: any) => {
    return cityService.searchCityData(data);
  }
);

export const createNewCity = createAsyncThunk(
  "city/createNewCity",
  async (data: ICityRecord) => {
    return cityService.createNewCity(data);
  }
);
export const editCityById = createAsyncThunk(
  "city/editCityById",
  async (data: ICityRecord) => {
    return cityService.editCityById(data);
  }
);
export const removeCityById = createAsyncThunk(
  "city/removeCityById",
  async (id: number) => {
    return cityService.removeCityById(id);
  }
);
