import request from "src/lib/axios/request";
import {
  ICreateTempPayload,
  IEditTempPayload,
  ITempPayload,
} from "src/pages/SubCourseTemplate/SubCourseTemplate.model";
import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import {
  ITemplateResponse,
  ITemplateSigningSheetRecord,
  IUpdateTemplateStatus,
  IUpdateTemplateTopicsAndSubTopicsStatus,
} from "./templateSigningSheet.model";

class templateSigningSheetService {
  ENDPOINT = config.baseApiBatch + "/template-shining-sheet";

  public searchTemplateSigningSheetDataByScID = async (data: {
    [key: string]: string | URLSearchParams;
  }): Promise<IApiResponse<ITemplateSigningSheetRecord>> => {
    const url = `${this.ENDPOINT}/${data.subcourse_id}`;
    return request({ url, method: "GET", params: data.searchParams }).then(
      (res) => {
        return res.data;
      }
    );
  };

  public createNewTemplateSigningSheet = async (
    data: ICreateTempPayload
  ): Promise<IApiResponse<ITemplateResponse>> => {
    const url = `${this.ENDPOINT}/${data.subcourse_id}`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public getTemplateShiningSheetBySubCourseIdAndId = async (data: {
    [key: string]: string | URLSearchParams ;
  }) => {
    const url = `${this.ENDPOINT}/${data.subcourse_id}/${data.template_id}/topics`;
    return request({ url, method: "GET", params: data.searchParams }).then(
      (res) => {
        return res.data;
      }
    );
  };

  public editTemplateShiningSheetBySubCourseIdAndId = async (
    data: IEditTempPayload
  ): Promise<IApiResponse<ITemplateResponse>> => {
    const url = `${this.ENDPOINT}/${data.subcourse_id}/${data.id}`;
    const { id, ...rest } = data;
    return request({ url, method: "PUT", data: rest }).then((res) => {
      return res.data;
    });
  };

  public deleteTemplateShiningSheetId = async (id: number) => {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public updateTemplateShiningSheetStatus = async (
    data: IUpdateTemplateStatus
  ): Promise<IApiResponse<ITemplateResponse>> => {
    const url = `${this.ENDPOINT}/${data.subcourse_id}/${data.id}/status`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

  public updateTemplateShiningSheetTopicStatus = async (
    data: IUpdateTemplateTopicsAndSubTopicsStatus
  ): Promise<IApiResponse<ITemplateResponse>> => {
    const url = `${this.ENDPOINT}/template-sub-topic/${data.id}/status`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

  public sendTemplateInMail = async (
    data: ITempPayload
  ): Promise<IApiResponse<ITemplateResponse>> => {
    const url = `${this.ENDPOINT}/${data.subcourse_id}/${data.id}/send-template-pdf-mail`;
    return request({ url, method: "GET", params: {} }).then((res) => {
      return res.data;
    });
  };
}

export default new templateSigningSheetService();
