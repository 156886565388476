import { createAsyncThunk } from "@reduxjs/toolkit";
import { ISubcategoryRecord } from "../../pages/SubCategory/Subcategory.model";
import { ISubCategoryStatus } from "../../services/subCategory/subCategory.model";
import SubCategoryService from "../../services/subCategory/subCategory.service";

export const removeSubCategoryById = createAsyncThunk(
  "subCategory/removeSubCategoryById",
  async (id: number) => {
    return SubCategoryService.removeSubCategoryById(id);
  }
);

export const updateSubCategoryStatus = createAsyncThunk(
  "subCategory/updateSubCategoryStatus",
  async (data: ISubCategoryStatus) => {
    return SubCategoryService.updateSubCategoryStatus(data);
  }
);

export const editSubCategoryById = createAsyncThunk(
  "subCategory/editSubCategoryById",
  async (data: ISubcategoryRecord) => {
    return SubCategoryService.editSubCategoryById(data);
  }
);

export const createNewSubCategory = createAsyncThunk(
  "subCategory/createNewSubCategory",
  async (data: ISubcategoryRecord) => {
    return SubCategoryService.createNewSubCategory(data);
  }
);

export const searchSubCategoryData = createAsyncThunk(
  "subCategory/searchSubCategoryData",
  async (data: any) => {
    return SubCategoryService.searchSubCategoryData(data);
  }
);
