import {
  Badge,
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Modal,
  message,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import ContainerHeader from "src/components/ContainerHeader";
import { currencyFormat, trimObject } from "src/utils/helperFunction";
import { useSearchParams } from "react-router-dom";
import FloatLabel from "src/components/Form/FloatLabel";
import TableComponent from "src/components/DataTable";
import { ColumnsType } from "antd/lib/table";
import { AppDispatch } from "src/state/app.model";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/state/app.hooks";
import { userSelector } from "src/state/users/user.reducer";
import { branchSelector } from "src/state/branch/branch.reducer";
import { searchBranchData } from "src/state/branch/branch.action";
import { IHolidayRecord } from "../Holidays/Holiday.model";
import {
  schoolWiseStudentCount,
  schoolWiseStudentCountCsv,
} from "src/state/overdueIncome/overdueIncome.action";
import {
  clearRemoveMessage,
  overdueIncomeSelector,
} from "src/state/overdueIncome/overdueIncome.reducer";
import { ISchoolWiseStudentCount, SchoolWiseStudentCountData } from "src/services/overdueIncome/overdueIncome.model";
import { zoneSelector } from "src/state/zone/zone.reducer";
import { searchZoneData } from "src/state/zone/zone.action";
import { IBranchDetails } from "src/services/branch/branch.model";
import { IFilterFormSubmit } from "./SchoolWiseStudentCount.model";
import { Common, RoleType, ZoneType } from "src/utils/constants/constant";
import {
  FilterFilled,
  FileTextOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { Can } from "src/ability/can";
import { useWatch } from "antd/lib/form/Form";

const SchoolWiseStudentCount = () => {
  const [data, setData] = useState<ISchoolWiseStudentCount>();
  const reportState = useAppSelector(overdueIncomeSelector);
  const zoneState = useAppSelector(zoneSelector);
  const { userData } = useAppSelector(userSelector);
  const branchState = useAppSelector(branchSelector);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [formValues, setFormValues] = useState({});
  const [form] = Form.useForm();
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState(
    searchParams.get("skip") && searchParams.get("take")
      ? Number(searchParams.get("skip")) / Number(searchParams.get("take")) + 1
      : 1
  );
  const dispatch = useDispatch<AppDispatch>();

  const storageID: string | null = localStorage.getItem("myStorageID");
  const currentUserRole = userData.data.user_roles.find(
    (role) => role.id === Number(storageID)
  )?.role;
  const currentUserZone = userData.data.user_roles.find(
    (role) => role.id === Number(storageID)
  )?.zone;

  const { Option } = Select;
  const selectedZoneIds = useWatch("selectedZoneIds", form);

  const dataConvertFromSearchParm = () => {
    let data = {};
    const splitArray = ["selectedZoneIds", "selectedBranchIds"];

    for (const entry of Array.from(searchParams.entries())) {
      const [key, value] = entry;

      if (value === "") {
        setSearchParams("");
        data = "";
      } else if (splitArray.includes(key)) {
        Object.assign(data, {
          [key]: value.split(","),
        });
      } else {
        Object.assign(data, {
          [key]: value,
        });
      }
    }

    return data;
  };

  const onCancel = () => {
    setFilterModalOpen(false);
  };

  const handleCsv = () => {
    dispatch(schoolWiseStudentCountCsv(dataConvertFromSearchParm()));
  };

  const columns: ColumnsType<SchoolWiseStudentCountData> = [
    {
      title: "No.",
      dataIndex: "id",
      sorter: false,
      align: "center",
      width: "5%",
      render: (text, record, index) => (
        <>{(page - 1) * Number(searchParams.get("take")) + index + 1}</>
      ),
    },
    {
      title: "School/College Name",
      dataIndex: "name",
    },
    {
      title: "No Of Student",
      dataIndex: "student_count",
    },
  ];

  useEffect(() => {
    if (Object.keys(formValues).length > 0) {
      form.resetFields();
    }
  }, [formValues]);

  useEffect(() => {
    dispatch(
      searchBranchData({ noLimit: true, orderBy: "name", order: "ASC" })
    );
    dispatch(
      searchZoneData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        type: ZoneType.PUBLIC,
        parent_id: currentUserZone?.id,
      })
    );

    const data = {
      ...setFormValues,
    };
    for (const entry of Array.from(searchParams.entries())) {
      const [key, value] = entry;
      if (key === "selectedZoneIds") {
        Object.assign(data, {
          ["selectedZoneIds"]: value.split(",")?.map(Number),
        });
      } else if (key === "selectedBranchIds") {
        Object.assign(data, {
          ["selectedBranchIds"]: value.split(",")?.map(Number),
        });
      } else {
        Object.assign(data, {
          [key]: value,
        });
      }
    }
    setFormValues(data);
  }, []);

  useEffect(() => {
    dispatch(schoolWiseStudentCount(dataConvertFromSearchParm())).then(() => {
      setLoading(false);
    });
  }, [searchParams]);

  useEffect(() => {
    setData(reportState.schoolWiseStudentCount.data);
    if (searchParams.get("skip") && searchParams.get("take")) {
      setPage(
        searchParams.get("skip") && searchParams.get("take")
          ? Number(searchParams.get("skip")) /
              Number(searchParams.get("take")) +
              1
          : 1
      );
    }
  }, [reportState.schoolWiseStudentCount.data]);

  useEffect(() => {
    if (reportState.schoolWiseStudentCountCsvData.message) {
      if (reportState.schoolWiseStudentCountCsvData.hasErrors) {
        message.error(reportState.schoolWiseStudentCountCsvData.message);
      } else {
        message.success(reportState.schoolWiseStudentCountCsvData.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [reportState.schoolWiseStudentCountCsvData.message]);

  

  const onFinish = (values: IHolidayRecord) => {
    let data = Object.fromEntries(
      new URLSearchParams(trimObject(searchParams))
    );

    values = { ...data, ...values };
    const { ...rest } = values;
    const newData = Object.assign(rest);
    Object.keys(newData).forEach(
      (key) => newData[key] === undefined && delete newData[key]
    );
    let queryString = Object.entries(trimObject(newData))
      .filter(
        ([key, values]) =>
          values !== undefined &&
          values !== "" &&
          (Array.isArray(values) ? values.length > 0 : values)
      )
      .map(([key, values]) => key + "=" + encodeURIComponent(values as string))
      .join("&");
    setSearchParams(queryString);
    setFilterModalOpen(false);
  };

  const onReset = () => {
    setSearchParams({});
    setFormValues(" ");
    form.resetFields();
    setCount(0);
  };
  useEffect(() => {
    let sum = 0;
    const data = Object.fromEntries(new URLSearchParams(searchParams));
    for (const [key, value] of Object.entries(data)) {
      if (
        key !== "orderBy" &&
        key !== "order" &&
        key !== "skip" &&
        key !== "take" &&
        data[key] !== ""
      ) {
        sum += 1;
      }
    }
    setCount(sum);
  }, [window.location.search]);
  
  const branchData = useMemo(() => {
    const dynamicBranchData = branchState.branchesData.data.rows.filter(
      (branch) => selectedZoneIds?.length ? selectedZoneIds.includes(branch.zone?.parent_id[0].id) : branch
    );

    return dynamicBranchData || [];
  }, [selectedZoneIds , branchState.branchesData.data.rows]);

  return (
    <div className="rnw-main-content">
      <Skeleton loading={loading} active avatar>
        <Row
          align="middle"
          justify="space-between"
          gutter={24}
          className="mb-20"
        >
          <Col xxl={12}>
            <ContainerHeader title="School/College Wise Student Count" />
            <p className="gx-mt-1 gx-mb-1">
              {`Total No Of Student : ${data && data.totalStudents && currencyFormat(data.totalStudents)}`}
            </p>
          </Col>
          <Col xxl={12} className="text-align-right">
            <Button
              icon={<FilterFilled />}
              onClick={() => setFilterModalOpen(true)}
            >
              Filter
              <span>
                <Badge count={count}></Badge>
              </span>
            </Button>
            <Can
              I={Common.Actions.CAN_EXPORT_DATA}
              a={Common.Modules.REPORT.SCHOOL_WISE_STUDENT_COUNT}
            >
              <Button
                icon={<FileTextOutlined />}
                onClick={() => {
                  Modal.confirm({
                    title:
                      "Are you sure want to download school wise student count csv file?",
                    okText: "Yes",
                    cancelText: "No",
                    icon: <DownloadOutlined />,
                    onOk() {
                      handleCsv();
                    },
                  });
                }}
              >
                CSV
              </Button>
            </Can>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="filter" style={{ height: "auto" }}>
              <Drawer
                className="filter-drawer"
                title="School/College Wise Student Count Filter"
                width="1000"
                visible={filterModalOpen}
                onClose={() => {
                  setFilterModalOpen(false);
                }}
                footer={[
                  <div className="gx-d-flex gx-justify-content-center">
                    <Button
                      className="cancel-filter gx-mr-0"
                      key="back"
                      onClick={onCancel}
                    >
                      <span className="gx-d-none gx-d-sm-block">Cancel</span>
                      <i className="fa fa-close gx-d-sm-none"></i>
                    </Button>
                    <Button
                      className="btn-apply-filter gx-mx-2"
                      key="submit"
                      type="primary"
                      loading={loading}
                      htmlType="submit"
                      form="myForm"
                    >
                      Apply Filter
                    </Button>
                    <Button
                      className="reset-filter"
                      type="default"
                      htmlType="reset"
                      form="myForm"
                    >
                      <span className="gx-d-none gx-d-sm-block">Reset</span>
                      <i className="fa fa-refresh gx-d-sm-none"></i>
                    </Button>
                  </div>,
                ]}
              >
                <Form
                  id="myForm"
                  onFinish={onFinish}
                  form={form}
                  onReset={onReset}
                  initialValues={formValues}
                >
                  {currentUserZone?.type !== ZoneType.PRIVATE &&
                    zoneState.zonesData.data.rows.length > 0 && (
                      <FloatLabel
                        label="Select Zone"
                        placeholder="Select Zone"
                        name="selectedZoneIds"
                      >
                        <Form.Item name="selectedZoneIds">
                          <Select
                            getPopupContainer={(trigger) => trigger.parentNode}
                            allowClear
                            showSearch
                            showArrow
                            mode="multiple"
                            size="large"
                            filterOption={(value, option) =>
                              (option?.children?.toString() || "")
                                .toLowerCase()
                                .includes(value.toLowerCase().trim())
                            }
                            onChange={() => form.setFieldValue("selectedBranchIds" , undefined)}
                          >
                            {zoneState.zonesData.data.rows
                              .filter(
                                (zone) =>
                                  zone?.id === currentUserZone?.id ||
                                  zone?.parent_id?.id === currentUserZone?.id
                              )
                              .filter((zone) => zone.status === true)
                              .map((zone) => (
                                <Option value={zone.id}>
                                  {zone.code + "-" + zone.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </FloatLabel>
                    )}

                  {!(currentUserRole?.type === RoleType.FACULTY) &&
                    currentUserZone?.type !== ZoneType.PRIVATE && (
                      <FloatLabel
                        label="Select Branch"
                        placeholder="Select Branch"
                        name="selectedBranchIds"
                      >
                        <Form.Item name="selectedBranchIds">
                          <Select
                            getPopupContainer={(trigger) => trigger.parentNode}
                            allowClear
                            showSearch
                            showArrow
                            mode="multiple"
                            size="large"
                            filterOption={(value, option) =>
                              (option?.children?.toString() || "")
                                .toLowerCase()
                                .includes(value.toLowerCase().trim())
                            }
                          >
                            {branchData?.length &&
                              branchData
                                ?.filter((branch) => branch.status === true)
                                .map((branch) => (
                                  <Option value={branch.id}>
                                    {branch.code}
                                  </Option>
                                ))}
                          </Select>
                        </Form.Item>
                      </FloatLabel>
                    )}
                  <Row gutter={24}>
                    <Col xs={24}>
                      <FloatLabel
                        label="Name"
                        placeholder="Enter School/College Name"
                        name="school_name"
                      >
                        <Form.Item name="school_name">
                          <Input size="large" />
                        </Form.Item>
                      </FloatLabel>
                    </Col>

                    <Col xs={24}>
                      <FloatLabel
                        label="No Of Students"
                        placeholder=""
                        name="number_of_student"
                      >
                        <Form.Item name="number_of_student">
                          <InputNumber
                            size="large"
                            style={{ width: "100%" }}
                            min={0}
                            formatter={(value: string | number | undefined) =>
                              value !== undefined ? `${value}` : ""
                            }
                            parser={(value: string | undefined) =>
                              value !== undefined ? parseInt(value, 10) : 0
                            }
                          />
                        </Form.Item>
                      </FloatLabel>
                    </Col>
                  </Row>
                </Form>
              </Drawer>
            </div>
            <Card className="rnw-card zone gx-mb-0">
              <TableComponent
                columns={columns}
                dataSource={data?.rows || []}
                loading={loading}
                meta={data?.meta}
              />
            </Card>
          </Col>
        </Row>
      </Skeleton>
    </div>
  );
}

export default SchoolWiseStudentCount;
