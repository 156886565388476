import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IContinuouslyAbsentRecord,
  IDashboardReportPayload,
} from "src/services/studentList/studentList.model";
import studentListService from "src/services/studentList/studentList.service";
import { IDateFilter } from "src/utils/models/common";

export const searchStudentsListData = createAsyncThunk(
  "studentList/searchStudentsListData",
  async (data: { batch_id: string | number | URLSearchParams }) => {
    return studentListService.searchStudentListDataById(data);
  }
);
export const studentAbsentReport = createAsyncThunk(
  "studentList/studentAbsentReport",
  async (data: string | URLSearchParams) => {
    return studentListService.studentAbsentReport(data);
  }
);
export const studentLowAttendanceReport = createAsyncThunk(
  "studentList/studentLowAttendanceReport",
  async (data: string | URLSearchParams | IDateFilter) => {
    return studentListService.studentLowAttendanceReport(data);
  }
);
export const dashboardStudentReport = createAsyncThunk(
  "studentList/dashboardStudentReport",
  async (data: IDashboardReportPayload | URLSearchParams | undefined) => {
    return studentListService.dashboardStudentListData(data);
  }
);
export const studentMarksPending = createAsyncThunk(
  "studentList/studentMarksPending",
  async (data: string[] | undefined | URLSearchParams | IDateFilter) => {
    return studentListService.studentMarksPending(data);
  }
);
export const overDueBatchData = createAsyncThunk(
  "studentList/overDueBatchData",
  async (data: string[] | undefined | URLSearchParams | IDateFilter) => {
    return studentListService.overDueBatchData(data);
  }
);

export const updateBatchStudentsMarksModify = createAsyncThunk(
  "studentList/updateBatchStudentsMarksModify",
  async (data: any) => {
    return studentListService.updateBatchStudentsMarksModify(data);
  }
);

export const cancelOrTerminateStudent = createAsyncThunk(
  "studentList/cancelOrTerminateStudent",
  async (data: string | URLSearchParams | IDateFilter) => {
    return studentListService.cancelOrTerminateStudent(data);
  }
);

export const searchStudentDetailsData = createAsyncThunk(
  "batch/searchStudentDetailsData",
  async (data: string | number | URLSearchParams) => {
    return studentListService.searchStudentDetailsData(data);
  }
);

export const studentPerformance = createAsyncThunk(
  "batch/studentPerformance",
  async (admission_id: number) => {
    return studentListService.studentPerformance(admission_id);
  }
);

export const studentAttendance = createAsyncThunk(
  "batch/studentAttendance",
  async (admission_id: number) => {
    return studentListService.studentAttendance(admission_id);
  }
);

export const continuouslyAbsent = createAsyncThunk(
  "batch/continuouslyAbsent",
  async (data: IContinuouslyAbsentRecord | URLSearchParams | {}) => {
    return studentListService.continuouslyAbsent(data);
  }
);

export const studentLowperformanceReport = createAsyncThunk(
  "studentList/studentLowPerformanceReport",
  async (data: string | URLSearchParams | IDateFilter) => {
    return studentListService.studentLowperformanceReport(data);
  }
);

export const facultyWiseBatchAndStudentCount = createAsyncThunk(
  "studentList/facultyWiseBatchAndStudentCount",
  async (data: URLSearchParams | {}) => {
    return studentListService.facultyWiseBatchAndStudentCount(data);
  }
);

export const todayAbsentIsRemarksAdded = createAsyncThunk(
  "studentList/todayAbsentIsRemarksAdded",
  async (data: any) => {
    return studentListService.todayAbsentIsRemarksAdded(data);
  }
);
