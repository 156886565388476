import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICategoryRecord } from "../../pages/Category/Category.model";
import { ICategoryDetails, ICategoryStatus } from "../../services/category/category.model";
import categoryService from "../../services/category/category.service";

export const removeCategoryById = createAsyncThunk(
  "category/removeCategoryById",
  async (id: number) => {
    return categoryService.removeCategoryById(id);
  }
);

export const updateCategoryStatus = createAsyncThunk(
  "category/updateCategoryStatus",
  async (data: ICategoryStatus) => {
    return categoryService.updateCategoryStatus(data);
  }
);

export const editCategoryById = createAsyncThunk(
  "category/editCategoryById",
  async (data: ICategoryRecord) => {
    return categoryService.editCategoryById(data);
  }
);

export const createNewCategory = createAsyncThunk(
  "category/createNewCategory",
  async (data: ICategoryDetails) => {
    return categoryService.createNewCategory(data);
  }
);

export const searchCategoryData = createAsyncThunk(
  "category/searchCategoryData",
  async (data: any) => {
    return categoryService.searchCategoryData(data);
  }
);
