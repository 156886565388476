import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { IAssignQuestionBankState } from "./assignQuestionBank.model";
import {
  assignQuestionBank,
  removeAssignQuestionBank,
  updateAssignQuestionBank,
} from "./assignQuestionBank.action";

export const initialState: IAssignQuestionBankState = {
  assignQuestionBank: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  removeById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  updateById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
};

export const assignQuestionBankSlice = createSlice({
  name: "assign-question-bank",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.assignQuestionBank.message = "";
      state.removeById.message = "";
      state.updateById.message = "";
    },
  },
  extraReducers: {
    // Assign Question Bank
    [assignQuestionBank.pending.type]: (state) => {
      state.assignQuestionBank.loading = true;
    },
    [assignQuestionBank.fulfilled.type]: (state, action) => {
      state.assignQuestionBank.message = action.payload.message;
      state.assignQuestionBank.hasErrors = action.payload.error;
      state.assignQuestionBank.loading = false;
      state.assignQuestionBank.hasErrors = false;
    },
    [assignQuestionBank.rejected.type]: (state, action) => {
      state.assignQuestionBank.loading = false;
      state.assignQuestionBank.hasErrors = true;
      state.assignQuestionBank.message = action.error.message;
    },

    // Remove Assign Question Bank
    [removeAssignQuestionBank.pending.type]: (state) => {
      state.removeById.loading = true;
    },
    [removeAssignQuestionBank.fulfilled.type]: (state, action) => {
      state.removeById.message = action.payload.message;
      state.removeById.hasErrors = action.payload.error;
      state.removeById.loading = false;
      state.removeById.hasErrors = false;
    },
    [removeAssignQuestionBank.rejected.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = true;
      state.removeById.message = action.error.message;
    },

    // Update Assign Question Bank
    [updateAssignQuestionBank.pending.type]: (state) => {
      state.updateById.loading = true;
    },
    [updateAssignQuestionBank.fulfilled.type]: (state, action) => {
      state.updateById.message = action.payload.message;
      state.updateById.hasErrors = action.payload.error;
      state.updateById.loading = false;
      state.updateById.hasErrors = false;
    },
    [updateAssignQuestionBank.rejected.type]: (state, action) => {
      state.updateById.loading = false;
      state.updateById.hasErrors = true;
      state.updateById.message = action.error.message;
    },
  },
});

// A selector
export const assignQuestionBankSelector = (state: RootState) =>
  state.assignQuestionBank;

export const { clearRemoveMessage } = assignQuestionBankSlice.actions;

// The reducer
export default assignQuestionBankSlice.reducer;
