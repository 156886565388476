import { IAreaRecord } from "src/pages/Area/Area.model";
import { config } from "src/utils";
import request from "../../lib/axios/request";
import { IApiResponse } from "../../utils/models/common";
import { IArea, IAreaDetails, IAreaStatus } from "./Area.model";


class AreaService {
  ENDPOINT = config.baseApiMasters + "/areas";

  public removeAreaById = async (id: number) => {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public editAreaById = async (
    data: IAreaDetails
  ): Promise<IApiResponse<any>> => {
    const url = `${this.ENDPOINT}/${data.id}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public createNewArea = async (
    data: IAreaDetails
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public searchAreaData = async (
    data: IAreaDetails
  ): Promise<IApiResponse<IArea[]>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
}

export default new AreaService();
