import { IHardwareEditApi } from "src/components/ShowPlaces/ShowPlaces.model";
import request from "src/lib/axios/request";
import { IBranchWisePcAndLaptopFilters, IPlacesState } from "src/state/places/places.model";
import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import { ISearchPlacesData, getBranchWisePcAndLaptopResponse } from "./places.model";

class PlacesServices {
    ENDPOINT = config.baseApiLabMasters + "/places"; 

    public bulkUpdateEditPlaces = async (
        data: IHardwareEditApi
    ): Promise<IApiResponse<any>> => {
        const url = `${this.ENDPOINT}/bulk-update`;
        return request({ url, method: "PATCH", data }).then((res) => {
            return res.data;
        });
    };

    public searchPlacesData = async (
        data: ISearchPlacesData
      ): Promise<IApiResponse<IPlacesState[]>> => {
        const url = `${this.ENDPOINT}`;
        return request({ url, method: "GET", params:data}).then((res) => {
          return res.data;
        });
      };

    public getBranchesWisePcAndLaptop = async (data:IBranchWisePcAndLaptopFilters):Promise<IApiResponse<getBranchWisePcAndLaptopResponse>>=>{
        const url = `${this.ENDPOINT}/get-branch-wise-pc-and-laptop-count`;
        return request({ url, method: "POST" , data}).then((res) => {
          return res.data;
        });
    }
}

export default new PlacesServices();
