import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICreateHardware, IDeleteHardwareData, IEditHardware } from "src/services/Hardware/Hardware.model";
import HardwareService from "src/services/Hardware/Hardware.service";


export const searchHardwareData = createAsyncThunk(
  "hardware",
  async (data: URLSearchParams | {} ) => {
    return HardwareService.searchHardwareData(data);
  }
);

export const createNewHardware = createAsyncThunk(
  "hardware/create",
  async (data: ICreateHardware) => {
    return HardwareService.createNewHardware(data);
  }
);

export const editHardwareById = createAsyncThunk(
  "hardware/editHardwareById",
  async (data: IEditHardware) => {
    return HardwareService.editHardwareById(data);
  }
);

export const deleteHardware = createAsyncThunk(
  "hardware/deleteHardware",
  async (data: IDeleteHardwareData) => {
    return HardwareService.deleteHardware(data);
  }
);
