import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { searchCityData, createNewCity, editCityById, removeCityById } from "./city.action";
import { ICityState } from "./city.model";

export const initialState: ICityState = {
  cityData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  createCity: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  editById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  removeById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  updateById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
};

export const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.removeById.message = "";
      state.updateById.message = "";
      state.editById.message = "";
      state.createCity.message = "";
    },
  },
  extraReducers: {
    //get city
    [searchCityData.pending.type]: (state) => {
      state.cityData.loading = true;
    },
    [searchCityData.fulfilled.type]: (state, action) => {
      state.cityData.data = action.payload.data;
      state.cityData.message = action.payload.message;
      state.cityData.loading = false;
      state.cityData.hasErrors = false;
    },
    [searchCityData.rejected.type]: (state, action) => {
      state.cityData.loading = false;
      state.cityData.hasErrors = true;
      state.cityData.message = action.error.message;
    },

    //create city
    [createNewCity.pending.type]: (state) => {
      state.createCity.loading = true;
    },
    [createNewCity.fulfilled.type]: (state, action) => {
      state.createCity.message = action.payload.message;
      state.createCity.hasErrors = action.payload.error;
      state.createCity.loading = false;
      state.createCity.hasErrors = false;
    },
    [createNewCity.rejected.type]: (state, action) => {
      state.createCity.loading = false;
      state.createCity.hasErrors = true;
      state.createCity.message = action.error.message;
    },

    //edit city
    [editCityById.pending.type]: (state) => {
      state.editById.loading = true;
    },
    [editCityById.fulfilled.type]: (state, action) => {
      state.editById.message = action.payload.message;
      state.editById.loading = false;
      state.editById.hasErrors = false;
    },
    [editCityById.rejected.type]: (state, action) => {
      state.editById.loading = false;
      state.editById.hasErrors = true;
      state.editById.message = action.error.message;
    },

    //Delete city
    [removeCityById.pending.type]: (state) => {
      state.removeById.loading = true;
    },
    [removeCityById.fulfilled.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = false;
      state.removeById.message = action.payload.message;
    },
    [removeCityById.rejected.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = true;
      state.removeById.message = action.error.message;
    },

  },
});

// A selector
export const citySelector = (state: RootState) => state.city;

export const { clearRemoveMessage } = citySlice.actions;

// The reducer
export default citySlice.reducer;
