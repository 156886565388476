import { config } from "src/utils";
import request from "../../lib/axios/request";
import { ICategoryRecord } from "../../pages/Category/Category.model";
import { IApiResponse } from "../../utils/models/common";
import {
  ICategory,
  ICategoryDetails,
  ICategoryStatus,
  IUpdateCategory,
} from "./category.model";

class CategoryService {
  ENDPOINT = config.baseApiExpense + "/categories";

  public removeCategoryById = async (id: number) => {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public updateCategoryStatus = async (
    data: ICategoryStatus
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}/status`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

  public editCategoryById = async (
    data: ICategoryRecord
  ): Promise<IApiResponse<IUpdateCategory>> => {
    const url = `${this.ENDPOINT}/${data.id}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public createNewCategory = async (
    data: ICategoryDetails
  ): Promise<IApiResponse<number>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public searchCategoryData = async (
    data: ICategoryDetails
  ): Promise<IApiResponse<ICategory[]>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
}

export default new CategoryService();
