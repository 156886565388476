import { createAsyncThunk } from "@reduxjs/toolkit";
import { IHolidayDetails } from "src/services/holiday/Holiday.model";
import HolidayService from "src/services/holiday/Holiday.service";

export const removeHolidayById = createAsyncThunk(
  "area/removeHolidayById",
  async (id: number) => {
    return HolidayService.removeHolidayById(id);
  }
);

export const editHolidayById = createAsyncThunk(
  "area/editHolidayById",
  async (data: IHolidayDetails) => {
    return HolidayService.editHolidayById(data);
  }
);

export const createNewHoliday = createAsyncThunk(
  "area/createNewHoliday",
  async (data: IHolidayDetails) => {
    return HolidayService.createNewHoliday(data);
  }
);

export const searchHolidayData = createAsyncThunk(
  "area/searchHolidayData",
  async (data: IHolidayDetails | URLSearchParams | any) => {
    return HolidayService.searchHolidayData(data);
  }
);

export const searchAllHolidayData = createAsyncThunk(
  "area/searchAllHolidayData",
  async (data: IHolidayDetails | URLSearchParams) => {
    return HolidayService.searchAllHolidayData(data);
  }
);
