import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICreateExamResult, IPublishStudentResult } from "src/services/ExamResult/ExamResult.model";
import ExamResultService from "src/services/ExamResult/ExamResult.service";

export const createExamResult = createAsyncThunk(
  "batch-exam-result/createExamResult",
  async (data: ICreateExamResult) => {
    return ExamResultService.createExamResult(data);
  }
);

export const getListingResult = createAsyncThunk(
  "batch-exam-result/getListingResult",
  async (data: URLSearchParams | {}) => {
    return ExamResultService.getListingResult(data);
  }
);

export const publishResult = createAsyncThunk(
  "batch/publishResult",
  async (data: IPublishStudentResult) => {
    return ExamResultService.publishResult(data);
  }
);