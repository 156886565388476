import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IAvailablePcsTimeWiseFilter,
  ICreateBranchInfrastructure,
  IEditBranchInfrastructure,
} from "src/services/BranchInfrastructure/branchInfrastructure.model";
import branchInfrastructureService from "src/services/BranchInfrastructure/branchInfrastructure.service";

export const searchBranchInfrastructureData = createAsyncThunk(
  "branch-infrastructure",
  async (data: URLSearchParams | {}) => {
    return branchInfrastructureService.searchBranchInfrastructureData(data);
  }
);

export const createNewBranchInfrastructure = createAsyncThunk(
  "branch-infrastructure/create",
  async (data: ICreateBranchInfrastructure) => {
    return branchInfrastructureService.createNewBranchInfrastructure(data);
  }
);

export const editBranchInfrastructureById = createAsyncThunk(
  "branch-infrastructure/editBranchInfrastructureById",
  async (data: IEditBranchInfrastructure) => {
    return branchInfrastructureService.editBranchInfrastructureById(data);
  }
);

export const branchWiseClassSchedule = createAsyncThunk(
  "branch-infrastructure/branchWiseClassSchedule",
  async (data: URLSearchParams | {}) => {
    return branchInfrastructureService.branchWiseClassSchedule(data);
  }
);

export const facultyAvailableSlots = createAsyncThunk(
  "branch-infrastructure/facultyAvailableSlots",
  async (data: URLSearchParams | {}) => {
    return branchInfrastructureService.facultyAvailableSlots(data);
  }
);

export const availablePcsTimeWise = createAsyncThunk(
  "branch-infrastructure/availablePcsTimeWise",
  async (data: URLSearchParams | IAvailablePcsTimeWiseFilter) => {
    return branchInfrastructureService.availablePcsTimeWise(data);
  }
);
