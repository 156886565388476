import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { IFeeTypeState } from "./feeType.model";
import { createNewFeeType } from "./feeType.action";

export const initialState: IFeeTypeState = {
  createFeeType: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
};

export const feeTypeSlice = createSlice({
  name: "feetype",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.createFeeType.message = "";
    },
  },
  extraReducers: {
    [createNewFeeType.pending.type]: (state) => {
      state.createFeeType.loading = true;
    },
    [createNewFeeType.fulfilled.type]: (state, action) => {
      state.createFeeType.data = action.payload.data;
      state.createFeeType.message = action.payload.message;
      state.createFeeType.loading = false;
      state.createFeeType.hasErrors = false;
    },
    [createNewFeeType.rejected.type]: (state, action) => {
      state.createFeeType.loading = false;
      state.createFeeType.hasErrors = true;
      state.createFeeType.message = action.error.message;
    },
  },
});

// A selector
export const feetypeSelector = (state: RootState) => state.feetype;

export const { clearRemoveMessage } = feeTypeSlice.actions;

// The reducer
export default feeTypeSlice.reducer;
