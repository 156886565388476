import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import {
  batchStartByStatus,
  searchUserBatchData,
  batchAssignedStudentCourseStatusAutoChange,
  facultyStudentAttendence,
  batchSessionAttendence,
  getBatchWiseStudentDetails,
  searchMyTeamBatchData,
  getBatchWiseStudentPresentAbsentDetails,
  getShiningSheetTopicByBatch,
  addNewTopicInSigningSheet,
  studentDetailsCsvEmailSendData,
  getBatchCompletedStudentAndNotCompletedStudentDetails,
  batchWiseStudentCompleted,
  facultyStudentAttendancesUpdate,
  facultyStudentAttendancesDelete,
  reGenerateBatchSiningSheet,
  searchBatchHoldDateData,
  removeByIdBatchHoldDateData,
  editByIdBatchHoldDateData,
  createBatchHoldDateData,
  mergeBatch,
  getCourseWiseStudentListData,
  bulkStudentAssign,
} from "./userBatch.action";
import { IUserBatchState } from "./userBatch.model";
import { IApiResponse } from "src/utils/models/common";

export const initialState: IUserBatchState = {
  createFacultyStudentAttendence: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  facultyStudentAttendence: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  searchData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  batchAssignedStudentCourseStatusAutoChange: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  getBatchWiseStudentDetails: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  searchMyTeamBatchesList: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  getBatchWiseStudentPresentAbsentDetails: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  getShiningSheetTopicByBatch: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  addNewTopicInSigningSheet: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  studentDetailsCsvEmailSendData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
    },
  },
  getBatchCompletedStudentAndNotCompletedStudentDetails: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      not_completed_batch_student: [],
      completed_batch_student: [],
      not_completed_batch_student_count: 0,
      completed_batch_student_count: 0,
    },
  },
  batchWiseStudentCompleted: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      id: [],
    },
  },
  facultyStudentAttendancesUpdate: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  facultyStudentAttendancesDelete: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  batchSessionAttendence: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  batchStartByStatus: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  reGenerateBatchSiningSheet: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  searchBatchHoldDateData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  createHoldBatchDate: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  removeHoldBatchDate: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  editHoldBatchDate: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  mergeBatch: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      currentBatchId: 0,
      mergeBatchId: 0,
      admissionIds: [],
      mergeBatch: [
        {
          currentBatchSubTopicId: 0,
          mergeBatchSubTopicId: 0,
        },
      ],
    },
  },
  getCourseWiseStudentList: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  bulkAssign: {
    loading: false,
    hasErrors: false,
    message: "",
  },
};
export const userBatchSlice = createSlice({
  name: "userbatch",
  initialState,
  reducers: {
    clearRemoveMessageForUserBatch: (state) => {
      state.createFacultyStudentAttendence.message = "";
      state.searchData.message = "";
      state.facultyStudentAttendence.message = "";
      state.batchSessionAttendence.message = "";
      state.batchAssignedStudentCourseStatusAutoChange.message = "";
      state.addNewTopicInSigningSheet.message = "";
      state.studentDetailsCsvEmailSendData.message = "";
      state.getBatchCompletedStudentAndNotCompletedStudentDetails.message = "";
      state.batchWiseStudentCompleted.message = "";
      state.facultyStudentAttendancesUpdate.message = "";
      state.facultyStudentAttendancesDelete.message = "";
      state.batchStartByStatus.message = "";
      state.reGenerateBatchSiningSheet.message = "";
      state.searchBatchHoldDateData.message = "";
      state.removeHoldBatchDate.message = "";
      state.editHoldBatchDate.message = "";
      state.createHoldBatchDate.message = "";
      state.mergeBatch.message = "";
      state.bulkAssign.message = "";
      state.getShiningSheetTopicByBatch.message = "";
    },
  },
  extraReducers: {
    [searchUserBatchData.pending.type]: (state) => {
      state.searchData.loading = true;
    },
    [searchUserBatchData.fulfilled.type]: (state, action) => {
      state.searchData.data = action.payload.data;
      state.searchData.message = action.payload.message;
      state.searchData.loading = false;
      state.searchData.hasErrors = false;
    },
    [searchUserBatchData.rejected.type]: (state, action) => {
      state.searchData.loading = false;
      state.searchData.hasErrors = true;
      state.searchData.message = action.error.message;
    },

    // batch start generate signing sheet
    [batchStartByStatus.pending.type]: (state) => {
      state.batchStartByStatus.loading = true;
    },
    [batchStartByStatus.fulfilled.type]: (state, action) => {
      state.batchStartByStatus.loading = false;
      state.batchStartByStatus.hasErrors = false;
      state.batchStartByStatus.message = action.payload.message;
    },
    [batchStartByStatus.rejected.type]: (state, action) => {
      state.batchStartByStatus.loading = false;
      state.batchStartByStatus.hasErrors = true;
      state.batchStartByStatus.message = action.error.message;
    },

    // batch-assigned-student-course-auto-change
    [batchAssignedStudentCourseStatusAutoChange.pending.type]: (state) => {
      state.batchAssignedStudentCourseStatusAutoChange.loading = true;
    },
    [batchAssignedStudentCourseStatusAutoChange.fulfilled.type]: (
      state,
      action
    ) => {
      state.batchAssignedStudentCourseStatusAutoChange.message =
        action.payload.message;
      state.batchAssignedStudentCourseStatusAutoChange.loading = false;
      state.batchAssignedStudentCourseStatusAutoChange.hasErrors = false;
    },
    [batchAssignedStudentCourseStatusAutoChange.rejected.type]: (
      state,
      action
    ) => {
      state.batchAssignedStudentCourseStatusAutoChange.loading = false;
      state.batchAssignedStudentCourseStatusAutoChange.hasErrors = true;
      state.batchAssignedStudentCourseStatusAutoChange.message =
        action.error.message;
    },

    //facultyStudentAttendence create
    [facultyStudentAttendence.pending.type]: (state) => {
      state.facultyStudentAttendence.loading = true;
    },
    [facultyStudentAttendence.fulfilled.type]: (state, action) => {
      state.facultyStudentAttendence.message = action.payload.message;
      state.facultyStudentAttendence.hasErrors = action.payload.error;
      state.facultyStudentAttendence.loading = false;
      state.facultyStudentAttendence.hasErrors = false;
    },
    [facultyStudentAttendence.rejected.type]: (state, action) => {
      state.facultyStudentAttendence.loading = false;
      state.facultyStudentAttendence.hasErrors = true;
      state.facultyStudentAttendence.message = action.error.message;
    },

    //batchSessionAttendence session attendance check
    [batchSessionAttendence.pending.type]: (state) => {
      state.batchSessionAttendence.loading = true;
    },
    [batchSessionAttendence.fulfilled.type]: (state, action) => {
      state.batchSessionAttendence.message = action.payload.message;
      state.batchSessionAttendence.hasErrors = action.payload.error;
      state.batchSessionAttendence.loading = false;
      state.batchSessionAttendence.hasErrors = false;
    },
    [batchSessionAttendence.rejected.type]: (state, action) => {
      state.batchSessionAttendence.loading = false;
      state.batchSessionAttendence.hasErrors = true;
      state.batchSessionAttendence.message = action.error.message;
    },

    //getBatchWiseStudentDetails
    [getBatchWiseStudentDetails.pending.type]: (state) => {
      state.getBatchWiseStudentDetails.loading = true;
    },
    [getBatchWiseStudentDetails.fulfilled.type]: (state, action) => {
      state.getBatchWiseStudentDetails.message = action.payload.message;
      state.getBatchWiseStudentDetails.hasErrors = action.payload.error;
      state.getBatchWiseStudentDetails.loading = false;
      state.getBatchWiseStudentDetails.hasErrors = false;
    },
    [getBatchWiseStudentDetails.rejected.type]: (state, action) => {
      state.getBatchWiseStudentDetails.loading = false;
      state.getBatchWiseStudentDetails.hasErrors = true;
      state.getBatchWiseStudentDetails.message = action.error.message;
    },

    //getMyTeamBatchesList
    [searchMyTeamBatchData.pending.type]: (state) => {
      state.searchMyTeamBatchesList.loading = true;
    },
    [searchMyTeamBatchData.fulfilled.type]: (state, action) => {
      state.searchMyTeamBatchesList.data = action.payload.data;
      state.searchMyTeamBatchesList.message = action.payload.message;
      state.searchMyTeamBatchesList.loading = false;
      state.searchMyTeamBatchesList.hasErrors = false;
    },
    [searchMyTeamBatchData.rejected.type]: (state, action) => {
      state.searchMyTeamBatchesList.loading = false;
      state.searchMyTeamBatchesList.hasErrors = true;
      state.searchMyTeamBatchesList.message = action.error.message;
    },

    //getBatchWiseStudentPresentAbsentDetails
    [getBatchWiseStudentPresentAbsentDetails.pending.type]: (state) => {
      state.getBatchWiseStudentPresentAbsentDetails.loading = true;
    },
    [getBatchWiseStudentPresentAbsentDetails.fulfilled.type]: (
      state,
      action
    ) => {
      state.getBatchWiseStudentPresentAbsentDetails.data = action.payload.data;
      state.getBatchWiseStudentPresentAbsentDetails.message =
        action.payload.message;
      state.getBatchWiseStudentPresentAbsentDetails.loading = false;
      state.getBatchWiseStudentPresentAbsentDetails.hasErrors = false;
    },
    [getBatchWiseStudentPresentAbsentDetails.rejected.type]: (
      state,
      action
    ) => {
      state.getBatchWiseStudentPresentAbsentDetails.loading = false;
      state.getBatchWiseStudentPresentAbsentDetails.hasErrors = true;
      state.getBatchWiseStudentPresentAbsentDetails.message =
        action.error.message;
    },

    //getShiningSheetTopicByBatch
    [getShiningSheetTopicByBatch.pending.type]: (state) => {
      state.getShiningSheetTopicByBatch.loading = true;
    },
    [getShiningSheetTopicByBatch.fulfilled.type]: (
      state,
      action
    ) => {
      state.getShiningSheetTopicByBatch.message =
        action.payload.message;
      state.getShiningSheetTopicByBatch.loading = false;
      state.getShiningSheetTopicByBatch.hasErrors = false;
    },
    [getShiningSheetTopicByBatch.rejected.type]: (
      state,
      action
    ) => {
      state.getShiningSheetTopicByBatch.loading = false;
      state.getShiningSheetTopicByBatch.hasErrors = true;
      state.getShiningSheetTopicByBatch.message =
        action.error.message;
    },

    //addNewTopicInSigningSheet create
    [addNewTopicInSigningSheet.pending.type]: (state) => {
      state.addNewTopicInSigningSheet.loading = true;
    },
    [addNewTopicInSigningSheet.fulfilled.type]: (state, action) => {
      state.addNewTopicInSigningSheet.message = action.payload.message;
      state.addNewTopicInSigningSheet.hasErrors = action.payload.error;
      state.addNewTopicInSigningSheet.loading = false;
      state.addNewTopicInSigningSheet.hasErrors = false;
    },
    [addNewTopicInSigningSheet.rejected.type]: (state, action) => {
      state.addNewTopicInSigningSheet.loading = false;
      state.addNewTopicInSigningSheet.hasErrors = true;
      state.addNewTopicInSigningSheet.message = action.error.message;
    },

    // student details download csv file
    [studentDetailsCsvEmailSendData.pending.type]: (state) => {
      state.studentDetailsCsvEmailSendData.loading = true;
    },
    [studentDetailsCsvEmailSendData.fulfilled.type]: (state, action) => {
      state.studentDetailsCsvEmailSendData.data = action.payload.data;
      state.studentDetailsCsvEmailSendData.message = action.payload.message;
      state.studentDetailsCsvEmailSendData.loading = false;
      state.studentDetailsCsvEmailSendData.hasErrors = false;
    },
    [studentDetailsCsvEmailSendData.rejected.type]: (state, action) => {
      state.studentDetailsCsvEmailSendData.loading = false;
      state.studentDetailsCsvEmailSendData.hasErrors = true;
      state.studentDetailsCsvEmailSendData.message = action.error.message;
    },

    //getBatchCompletedStudentAndNotCompletedStudentDetails
    [getBatchCompletedStudentAndNotCompletedStudentDetails.pending.type]: (
      state
    ) => {
      state.getBatchCompletedStudentAndNotCompletedStudentDetails.loading =
        true;
    },
    [getBatchCompletedStudentAndNotCompletedStudentDetails.fulfilled.type]: (
      state,
      action
    ) => {
      state.getBatchCompletedStudentAndNotCompletedStudentDetails.data =
        action.payload.data;
      state.getBatchCompletedStudentAndNotCompletedStudentDetails.message =
        action.payload.message;
      state.getBatchCompletedStudentAndNotCompletedStudentDetails.loading =
        false;
      state.getBatchCompletedStudentAndNotCompletedStudentDetails.hasErrors =
        false;
    },
    [getBatchCompletedStudentAndNotCompletedStudentDetails.rejected.type]: (
      state,
      action
    ) => {
      state.getBatchCompletedStudentAndNotCompletedStudentDetails.loading =
        false;
      state.getBatchCompletedStudentAndNotCompletedStudentDetails.hasErrors =
        true;
      state.getBatchCompletedStudentAndNotCompletedStudentDetails.message =
        action.error.message;
    },

    [batchWiseStudentCompleted.pending.type]: (state) => {
      state.batchWiseStudentCompleted.loading = true;
    },
    [batchWiseStudentCompleted.fulfilled.type]: (state, action) => {
      state.batchWiseStudentCompleted.message = action.payload.message;
      state.batchWiseStudentCompleted.hasErrors = action.payload.error;
      state.batchWiseStudentCompleted.loading = false;
      state.batchWiseStudentCompleted.hasErrors = false;
    },
    [batchWiseStudentCompleted.rejected.type]: (state, action) => {
      state.batchWiseStudentCompleted.loading = false;
      state.batchWiseStudentCompleted.hasErrors = true;
      state.batchWiseStudentCompleted.message = action.error.message;
    },

    //facultyStudentAttendancesUpdate update
    [facultyStudentAttendancesUpdate.pending.type]: (state) => {
      state.facultyStudentAttendancesUpdate.loading = true;
    },
    [facultyStudentAttendancesUpdate.fulfilled.type]: (state, action) => {
      state.facultyStudentAttendancesUpdate.message = action.payload.message;
      state.facultyStudentAttendancesUpdate.hasErrors = action.payload.error;
      state.facultyStudentAttendancesUpdate.loading = false;
      state.facultyStudentAttendancesUpdate.hasErrors = false;
    },
    [facultyStudentAttendancesUpdate.rejected.type]: (state, action) => {
      state.facultyStudentAttendancesUpdate.loading = false;
      state.facultyStudentAttendancesUpdate.hasErrors = true;
      state.facultyStudentAttendancesUpdate.message = action.error.message;
    },

    // DELETE faculty and student attendance topic wise

    [facultyStudentAttendancesDelete.pending.type]: (state) => {
      state.facultyStudentAttendancesDelete.loading = true;
    },
    [facultyStudentAttendancesDelete.fulfilled.type]: (state, action) => {
      state.facultyStudentAttendancesDelete.loading = false;
      state.facultyStudentAttendancesDelete.hasErrors = false;
      state.facultyStudentAttendancesDelete.message = action.payload.message;
    },
    [facultyStudentAttendancesDelete.rejected.type]: (state, action) => {
      state.facultyStudentAttendancesDelete.loading = false;
      state.facultyStudentAttendancesDelete.hasErrors = true;
      state.facultyStudentAttendancesDelete.message = action.error.message;
    },

    // Re Generate Batch Singing Sheet

    [reGenerateBatchSiningSheet.pending.type]: (state) => {
      state.reGenerateBatchSiningSheet.loading = true;
    },
    [reGenerateBatchSiningSheet.fulfilled.type]: (state, action) => {
      state.reGenerateBatchSiningSheet.loading = false;
      state.reGenerateBatchSiningSheet.hasErrors = false;
      state.reGenerateBatchSiningSheet.message = action.payload.message;
    },
    [reGenerateBatchSiningSheet.rejected.type]: (state, action) => {
      state.reGenerateBatchSiningSheet.loading = false;
      state.reGenerateBatchSiningSheet.hasErrors = true;
      state.reGenerateBatchSiningSheet.message = action.error.message;
    },

    // search Hold Batch Data
    [searchBatchHoldDateData.pending.type]: (state) => {
      state.searchBatchHoldDateData.loading = true;
    },
    [searchBatchHoldDateData.fulfilled.type]: (state, action) => {
      state.searchBatchHoldDateData.data = action.payload.data;
      state.searchBatchHoldDateData.message = action.payload.message;
      state.searchBatchHoldDateData.loading = false;
      state.searchBatchHoldDateData.hasErrors = false;
    },
    [searchBatchHoldDateData.rejected.type]: (state, action) => {
      state.searchBatchHoldDateData.loading = false;
      state.searchBatchHoldDateData.hasErrors = true;
      state.searchBatchHoldDateData.message = action.error.message;
    },

    // delete Hold Batch Date
    [removeByIdBatchHoldDateData.pending.type]: (state) => {
      state.removeHoldBatchDate.loading = true;
    },
    [removeByIdBatchHoldDateData.fulfilled.type]: (state, action) => {
      state.removeHoldBatchDate.message = action.payload.message;
      state.removeHoldBatchDate.loading = false;
      state.removeHoldBatchDate.hasErrors = false;
    },
    [removeByIdBatchHoldDateData.rejected.type]: (state, action) => {
      state.removeHoldBatchDate.loading = false;
      state.removeHoldBatchDate.hasErrors = true;
      state.removeHoldBatchDate.message = action.error.message;
    },

    // edit Hold Batch Date
    [editByIdBatchHoldDateData.pending.type]: (state) => {
      state.editHoldBatchDate.loading = true;
    },
    [editByIdBatchHoldDateData.fulfilled.type]: (state, action) => {
      state.editHoldBatchDate.message = action.payload.message;
      state.editHoldBatchDate.loading = false;
      state.editHoldBatchDate.hasErrors = false;
    },
    [editByIdBatchHoldDateData.rejected.type]: (state, action) => {
      state.editHoldBatchDate.loading = false;
      state.editHoldBatchDate.hasErrors = true;
      state.editHoldBatchDate.message = action.error.message;
    },

    // create Hold Batch Date
    [createBatchHoldDateData.pending.type]: (state) => {
      state.createHoldBatchDate.loading = true;
    },
    [createBatchHoldDateData.fulfilled.type]: (state, action) => {
      state.createHoldBatchDate.message = action.payload.message;
      state.createHoldBatchDate.hasErrors = action.payload.error;
      state.createHoldBatchDate.loading = false;
      state.createHoldBatchDate.hasErrors = false;
    },
    [createBatchHoldDateData.rejected.type]: (state, action) => {
      state.createHoldBatchDate.loading = false;
      state.createHoldBatchDate.hasErrors = true;
      state.createHoldBatchDate.message = action.error.message;
    },
    //Merge Batch
    [mergeBatch.pending.type]: (state) => {
      state.mergeBatch.loading = true;
    },
    [mergeBatch.fulfilled.type]: (state, action) => {
      state.mergeBatch.message = action.payload.message;
      state.mergeBatch.hasErrors = action.payload.error;
      state.mergeBatch.loading = false;
      state.mergeBatch.hasErrors = false;
    },
    [mergeBatch.rejected.type]: (state, action) => {
      state.mergeBatch.loading = false;
      state.mergeBatch.hasErrors = true;
      state.mergeBatch.message = action.error.message;
    },

    // search CourseWiseStudentList Data
    [getCourseWiseStudentListData.pending.type]: (state) => {
      state.getCourseWiseStudentList.loading = true;
    },
    [getCourseWiseStudentListData.fulfilled.type]: (state, action) => {
      state.getCourseWiseStudentList.loading = false;
      state.getCourseWiseStudentList.hasErrors = false;
      state.getCourseWiseStudentList.message = action.payload.message;
      state.getCourseWiseStudentList.data = action.payload.data;
    },
    [getCourseWiseStudentListData.rejected.type]: (state, action) => {
      state.getCourseWiseStudentList.loading = false;
      state.getCourseWiseStudentList.hasErrors = true;
      state.getCourseWiseStudentList.message = action.error.message;
    },

    //create Bulk Assign
    [bulkStudentAssign.pending.type]: (state) => {
      state.bulkAssign.loading = true;
    },
    [bulkStudentAssign.fulfilled.type]: (state, action) => {
      state.bulkAssign.message = action.payload.message;
      state.bulkAssign.hasErrors = action.payload.error;
      state.bulkAssign.loading = false;
      state.bulkAssign.hasErrors = false;
    },
    [bulkStudentAssign.rejected.type]: (state, action) => {
      state.bulkAssign.loading = false;
      state.bulkAssign.hasErrors = true;
      state.bulkAssign.message = action.error.message;
    },
  },
});

// A selector
export const userBatchSelector = (state: RootState) => state.userbatch;

export const { clearRemoveMessageForUserBatch } = userBatchSlice.actions;

// The reducer
export default userBatchSlice.reducer;
