import { createAsyncThunk } from "@reduxjs/toolkit";
import { ISubCourseTopicRecord } from "src/pages/SubCourseTopic/SubCourseTopic.model";
import {
  ISubCourseTopicDetails,
  ISubCourseTopicStatus,
  ISubCourseSubTopicStatus,
  ISubCourseTopicCsv,
} from "src/services/subCourseTopic/subCourseTopic.model";
import subCourseTopicService from "src/services/subCourseTopic/subCourseTopic.service";

export const searchSubCourseTopicData = createAsyncThunk(
  "subCourseTopic/searchSubCourseTopicData",
  async (data: { [key: string]: any }) => {
    return subCourseTopicService.searchSubCourseTopicData(data);
  }
);
export const createNewSubCourseTopic = createAsyncThunk(
  "subCourseTopic/createNewSubCourseTopic",
  async (data: ISubCourseTopicDetails) => {
    return subCourseTopicService.createNewSubCourseTopic(data);
  }
);
export const uploadSubCourseTopicCsvFile = createAsyncThunk(
  "subCourseTopic/uploadSubCourseTopicCsvFile",
  async (data: ISubCourseTopicCsv) => {
    return subCourseTopicService.uploadSubCourseTopicCsvFile(data);
  }
);
export const editSubCourseTopicById = createAsyncThunk(
  "subCourseTopic/editSubCourseTopicById",
  async (data: ISubCourseTopicRecord) => {
    return subCourseTopicService.editSubCourseTopicById(data);
  }
);
export const removeSubCourseTopicById = createAsyncThunk(
  "subCourseTopic/removeSubCourseTopicById",
  async (id: number) => {
    return subCourseTopicService.removeSubCourseTopicById(id);
  }
);
export const removeMultipleTopic = createAsyncThunk(
  "subCourseTopic/removeMultipleTopic",
  async (id: { id: [] }) => {
    return subCourseTopicService.removeMultipleTopic(id);
  }
);

export const updateSubCourseTopicStatus = createAsyncThunk(
  "subCourseTopic/updateSubCourseTopicStatus",
  async (data: ISubCourseTopicStatus) => {
    return subCourseTopicService.updateSubCourseTopicStatus(data);
  }
);

export const updateSubCourseSubTopicStatus = createAsyncThunk(
  "subCourseTopic/updateSubCourseSubTopicStatus",
  async (data: ISubCourseSubTopicStatus) => {
    return subCourseTopicService.updateSubCourseSubTopicStatus(data);
  }
);
