import { Badge, Button, Card, Checkbox, Col, Drawer, Empty, Form, Input, message, Modal, Row, Select, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import ContainerHeader from 'src/components/ContainerHeader';
import { useAppSelector } from 'src/state/app.hooks';
import { AppDispatch } from 'src/state/app.model';
import { FilterFilled } from "@ant-design/icons";
import { trimObject } from 'src/utils/helperFunction';
import { Exam_List, ZoneType } from 'src/utils/constants/constant';
import FloatLabel from 'src/components/Form/FloatLabel';
import { userSelector } from 'src/state/users/user.reducer';
import { zoneSelector } from 'src/state/zone/zone.reducer';
import { branchSelector } from 'src/state/branch/branch.reducer';
import { useWatch } from 'antd/lib/form/Form';
import { searchBranchData } from 'src/state/branch/branch.action';
import { searchZoneData } from 'src/state/zone/zone.action';
import { searchBatchData } from 'src/state/batch/batch.action';
import { searchPackageData } from 'src/state/package/package.action';
import { packageSelector } from 'src/state/package/package.reducer';
import { examResultSelector, clearRemoveMessage } from 'src/state/ExamResult/examResult.reducer';
import { getListingResult, publishResult } from 'src/state/ExamResult/examResult.action';
import { IGetListingData } from 'src/services/ExamResult/ExamResult.model';
import PaginationComponent from 'src/components/Pagination';
import StudentMobileNumber from "src/components/StudentMobileNumber";
import { Common } from "src/utils/constants/constant";

import { Can } from 'src/ability/can';

const { Option } = Select;

const ExamList = () => {
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState<IGetListingData[]>([]);
    const dispatch = useDispatch<AppDispatch>();
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState({});
    const [initialize, setInitialize] = useState(0);
    const [publish, setPublish] = useState(0);
    const [chequeModal, setChequeModal] = useState(false);
    const [statusModal, setStatusModal] = useState(false);
    const [examListStatus, setExamListStatus] = useState<string>("");
    const [count, setCount] = useState<number>(0);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [page, setPage] = useState(
        searchParams.get("skip") && searchParams.get("take")
            ? Number(searchParams.get("skip")) / Number(searchParams.get("take")) + 1
            : 1
    );
    const zoneState = useAppSelector(zoneSelector);
    const branchState = useAppSelector(branchSelector);
    const packageState = useAppSelector(packageSelector);
    const examResultState = useAppSelector(examResultSelector);
    const zoneId = useWatch("zone_ids", form);

    const { userData } = useAppSelector(userSelector);
    const storageID: string | null = localStorage.getItem("myStorageID");
    const currentUserZone = userData.data.user_roles.find(
        (role) => role.id === Number(storageID)
    )?.zone;

    const rules = {
        gr_id: [
            {
                pattern: /^[0-9]+$/,
                message: "Only numbers are allowed",
            },
        ],
    };

    useEffect(() => {
        if (examResultState.publishResult.message) {
            if (examResultState.publishResult.hasErrors) {
                message.error(examResultState.publishResult.message);
            } else {
                message.success(examResultState.publishResult.message);
            }
            dispatch(clearRemoveMessage());
        }
    }, [examResultState.publishResult.message]);

    useEffect(() => {
        dispatch(
            searchBranchData({
                noLimit: true,
                orderBy: "name",
                order: "ASC",
                isZoneOnly: true,
            })
        );
        dispatch(searchZoneData({
            noLimit: true,
            orderBy: "name",
            order: "ASC",
            type: ZoneType.PUBLIC,
            parent_id: currentUserZone?.id,
        }));

        dispatch(
            searchBatchData({
                noLimit: true
            }))
        dispatch(
            searchPackageData({ noLimit: true, orderBy: "name", order: "ASC" })
        );
    }, []);

    useEffect(() => {
        if (examResultState.getListingResult.data) {
            setData(examResultState.getListingResult.data?.rows);
        }
        if (searchParams.get("skip") && searchParams.get("take")) {
            setPage(
                searchParams.get("skip") && searchParams.get("take")
                    ? Number(searchParams.get("skip")) /
                    Number(searchParams.get("take")) +
                    1
                    : 1
            );
        }
    }, [examResultState.getListingResult.data]);

    const dataConvertFromSearchParm = () => {
        let data = {};
        for (const entry of Array.from(searchParams.entries())) {
            const [key, value] = entry;

            if (key === "branch_ids") {
                Object.assign(data, {
                    ["branch_ids"]: value.split(","),
                });
            } else {
                Object.assign(data, {
                    [key]: value,
                });
            }
        }
        return data;
    };

    useEffect(() => {
        const data = {
            ...setFormValues,
            ...dataConvertFromSearchParm(),
        };
        for (const entry of Array.from(searchParams.entries())) {
            const [key, value] = entry;
            if (value !== "") {
                if (key === "branch_ids") {
                    Object.assign(data, {
                        ["branch_ids"]: value.split(","),
                    });
                } else if (key === "zone_ids") {
                    Object.assign(data, {
                        ["zone_ids"]: value.split(","),
                    });
                } else {
                    Object.assign(data, {
                        [key]: value,
                    });
                }
            }
        }

        setFormValues(data);
    }, [searchParams]);

    useEffect(() => {
        dispatch(getListingResult(dataConvertFromSearchParm())).then((res: any) => {
            if (res.payload) {
                setInitialize(res.payload.data.count_initialize);
                setPublish(res.payload.data.count_publish);
            }
            setLoading(false)
        });

        for (const entry of Array.from(searchParams.entries())) {
            const [key, value] = entry;
            if (key === "status") {
                setExamListStatus(value);
            }
        }
    }, [searchParams]);

    useEffect(() => {
        if (Object.keys(formValues).length > 0) {
            form.resetFields();
        }
    }, [formValues]);

    const onFinish = (values: string[]) => {
        let data = Object.fromEntries(
            new URLSearchParams(trimObject(searchParams))
        );
        values = { ...data, ...values };
        const { ...rest } = values;
        const newData = Object.assign(rest);
        Object.keys(newData).forEach(
            (key) =>
                (newData[key] === undefined || newData[key] === null || newData[key].length <= 0) &&
                delete newData[key]
        );

        const queryString = Object.entries(trimObject(newData))
            .filter(([key, newData]) => newData && newData !== "")
            .map(([key, newData]) => key + "=" + newData)
            .join("&");

        setSearchParams(queryString.trim());
        setChequeModal(false);
    };

    const zoneChange = (value: string) => {
        form.setFieldValue("branch_ids", undefined);
    };

    const branchChange = (value: number[]) => {
        form.setFieldValue("batch_id", undefined);
    };

    const filterDataByStatus = (status: string) => {
        const urlData = Object.fromEntries(new URLSearchParams(searchParams));
        if (status) {
            Object.assign(urlData, { status: status });
            const { skip, ...rest } = urlData;
            setSearchParams(rest);
        } else {
            const { status, ...rest } = urlData;
            setSearchParams(rest);
        }
        setExamListStatus(status);
        setSelectedRows([]);
    };

    const handleCancel = () => {
        setChequeModal(false);
    };

    useEffect(() => {
        let sum = 0;
        const data = Object.fromEntries(new URLSearchParams(searchParams));
        for (const [key, value] of Object.entries(data)) {
            if (
                key !== "orderBy" &&
                key !== "order" &&
                key !== "skip" &&
                key !== "take" &&
                data[key] !== ""
            ) {
                sum += 1;
            }
        }
        setCount(sum);
    }, [window.location.search]);

    useEffect(() => {
        if (!searchParams.get("gr_id")) {
            form.setFieldValue("gr_id", undefined);
        }
    }, [window.location.search]);

    const onReset = () => {
        setSearchParams({});
        setFormValues(" ");
        setTimeout(() => form.resetFields());
    };

    const packageMap = new Map(packageState.packageData.data.rows.map(pkg => [pkg.id, pkg.name]));

    const enhancedListingData: IGetListingData[] = data.map(student => ({
        ...student,
        admission_results: student?.admission_results?.map((result) => ({
            ...result,
            result: result.result?.map((resultItem) => ({
                ...resultItem,
                credits: resultItem.credits.map((credit) => ({
                    ...credit,
                    packageName: packageMap.get(credit.packageId) || ""
                }))
            }))
        }))
    }));

    const allRowIds: number[] = enhancedListingData.flatMap(row =>
        row.admission_results?.map((result) => result.id)
    ) as number[];

    const handleAllSelect = (checked: boolean) => {
        if (checked) {
            setSelectedRows([...allRowIds]);
        } else {
            setSelectedRows([]);
        }
    };

    const handleCheckboxChange = (id: number, checked: boolean) => {
        setSelectedRows(prevSelectedRows =>
            checked
                ? [...prevSelectedRows, id]
                : prevSelectedRows.filter(rowId => rowId !== id)
        );
    };

    const isRowSelected = (id: number) => selectedRows.includes(id);

    const isAllSelected = allRowIds.length > 0 && allRowIds.every((id) => selectedRows.includes(id));

    const handlePublishClick = () => {
        const selectedIds = Array.from(selectedRows);
        const payload = {
            admission_result_ids: selectedIds
        };
        dispatch(publishResult(payload)).then((res) => {
            if (res.payload) {
                dispatch(getListingResult(dataConvertFromSearchParm())).then(() => setLoading(false));
            }
        })
    }

    return (
        <div className="rnw-main-content">
            <Skeleton active loading={loading} avatar>
                <Row
                    align="middle"
                    justify="space-between"
                    gutter={24}
                    className="mb-20"
                >
                    <Col lg={12} xl={24}>
                        <h2 className="rnw-page-title gx-d-flex">
                            <ContainerHeader title="Exam List" />
                        </h2>
                    </Col>

                    <Col
                        lg={12}
                        className="erp-filter-btn gx-d-xl-none"
                        style={{ textAlign: "end" }}
                    >
                        {(searchParams.get("status") === "INITIALIZE") && (
                            <Button
                                disabled={!selectedRows.length}
                                onClick={() => {
                                    Modal.confirm({
                                        title: "Are you sure Publish this student?",
                                        okText: "Yes",
                                        cancelText: "No",
                                        onOk() {
                                            handlePublishClick();
                                        },
                                    });
                                }}
                                className="btn-submit gx-mt-0"
                                type="primary"
                            >
                                Publish
                            </Button>
                        )}
                        <Button
                            icon={<FilterFilled />}
                            onClick={() => setChequeModal(true)}
                        >
                            Filter
                            <span>
                                <Badge count={count}></Badge>
                            </span>
                        </Button>
                        <Button onClick={() => setStatusModal(true)}> Status </Button>
                    </Col>
                </Row>
                <Row
                    align="middle"
                    justify="space-between"
                    gutter={24}
                    className="mb-20 gx-d-none gx-d-xl-flex"
                >
                    {statusModal ? (
                        <Drawer
                            className="admission-filter"
                            title="Status Filter"
                            open={statusModal}
                            visible={statusModal}
                            onClose={() => {
                                setStatusModal(false);
                            }}
                        >
                            <Col xxl={20} className="erp-button-group">
                                <Button
                                    className={
                                        "bg-darkBlue " +
                                        `${examListStatus.length === 0 ? "active" : ""}`
                                    }
                                    onClick={(e) => {
                                        filterDataByStatus("");
                                        setStatusModal(false);
                                    }}
                                >
                                    All
                                    <Badge
                                        overflowCount={99999}
                                        count={
                                            Number(initialize) +
                                            Number(publish)
                                        }
                                        style={{ backgroundColor: "#5864bd", boxShadow: "none" }}
                                        showZero
                                    />
                                </Button>
                                <Button
                                    className={
                                        "bg-blue " +
                                        `${examListStatus.includes(
                                            Exam_List.INITIALIZE
                                        )
                                            ? "active"
                                            : ""
                                        }`
                                    }
                                    onClick={(e) => {
                                        filterDataByStatus(
                                            Exam_List.INITIALIZE
                                        );
                                        setStatusModal(false);
                                    }}
                                >
                                    Initialize{" "}
                                    <Badge
                                        count={initialize}
                                        style={{ backgroundColor: "#3abaf4", boxShadow: "none" }}
                                        showZero
                                    />
                                </Button>
                                <Button
                                    className={
                                        "bg-warning " +
                                        `${examListStatus.includes(
                                            Exam_List.PUBLISH
                                        )
                                            ? "active"
                                            : ""
                                        }`
                                    }
                                    onClick={(e) => {
                                        filterDataByStatus(
                                            Exam_List.PUBLISH
                                        );
                                        setStatusModal(false);
                                    }}
                                >
                                    Publish{" "}
                                    <Badge
                                        count={publish}
                                        style={{ backgroundColor: "#ffa426", boxShadow: "none" }}
                                        showZero
                                    />
                                </Button>

                            </Col>
                        </Drawer>
                    ) : (
                        <Col xxl={20} className="erp-button-group">
                            <Button
                                className={
                                    "bg-darkBlue " +
                                    `${examListStatus.length === 0 ? "active" : ""}`
                                }
                                onClick={(e) => filterDataByStatus("")}
                            >
                                All
                                <Badge
                                    overflowCount={99999}
                                    count={
                                        Number(initialize) +
                                        Number(publish)
                                    }
                                    style={{ backgroundColor: "#5864bd", boxShadow: "none" }}
                                    showZero
                                />
                            </Button>
                            <Button
                                className={
                                    "bg-blue " +
                                    `${examListStatus.includes(
                                        Exam_List.INITIALIZE
                                    )
                                        ? "active"
                                        : ""
                                    }`
                                }
                                onClick={(e) =>
                                    filterDataByStatus(
                                        Exam_List.INITIALIZE
                                    )
                                }
                            >
                                Initialize{" "}
                                <Badge
                                    count={initialize}
                                    style={{ backgroundColor: "#3abaf4", boxShadow: "none" }}
                                    showZero
                                />
                            </Button>
                            <Button
                                className={
                                    "bg-warning " +
                                    `${examListStatus.includes(
                                        Exam_List.PUBLISH
                                    )
                                        ? "active"
                                        : ""
                                    }`
                                }
                                onClick={(e) =>
                                    filterDataByStatus(
                                        Exam_List.PUBLISH
                                    )
                                }
                            >
                                Publish{" "}
                                <Badge
                                    count={publish}
                                    style={{ backgroundColor: "#ffa426", boxShadow: "none" }}
                                    showZero
                                />
                            </Button>
                        </Col>
                    )}

                    <div className="filter" style={{ height: "auto" }}>
                        <Drawer
                            className="filter-drawer"
                            title="ExamList Filter"
                            width="1000"
                            visible={chequeModal}
                            onClose={handleCancel}
                            footer={[
                                <div className="gx-d-flex gx-justify-content-center">
                                    <Button
                                        className="cancel-filter gx-mr-0"
                                        key="back"
                                        onClick={handleCancel}
                                    >
                                        <span className="gx-d-none gx-d-sm-block">Cancel</span>
                                        <i className="fa fa-close gx-d-sm-none"></i>
                                    </Button>
                                    <Button
                                        className="btn-apply-filter gx-mx-2"
                                        key="submit"
                                        type="primary"
                                        loading={loading}
                                        htmlType="submit"
                                        form="myForm"
                                    >
                                        Apply Filter
                                    </Button>
                                    <Button
                                        className="reset-filter"
                                        type="default"
                                        htmlType="reset"
                                        form="myForm"
                                        onClick={() => filterDataByStatus("")}
                                    >
                                        <span className="gx-d-none gx-d-sm-block">Reset</span>
                                        <i className="fa fa-refresh gx-d-sm-none"></i>
                                    </Button>
                                </div>,
                            ]}
                        >
                            <Form
                                id="myForm"
                                onFinish={onFinish}
                                form={form}
                                onReset={onReset}
                                initialValues={formValues}
                            >
                                <Row>
                                    <Col xs={24}>
                                        <FloatLabel
                                            label="GR ID"
                                            placeholder="Enter GR ID"
                                            name="gr_id"
                                        >
                                            <Form.Item name="gr_id" rules={rules.gr_id}>
                                                <Input size="large" maxLength={9} />
                                            </Form.Item>
                                        </FloatLabel>
                                    </Col>
                                    {currentUserZone?.type !== ZoneType.PRIVATE &&
                                        zoneState.zonesData.data.rows.length > 0 && (
                                            <Col xs={24}>
                                                <FloatLabel
                                                    label="Zone"
                                                    placeholder="Select Zone"
                                                    name="zone_ids"
                                                >
                                                    <Form.Item name="zone_ids">
                                                        <Select
                                                            getPopupContainer={(trigger) =>
                                                                trigger.parentNode
                                                            }
                                                            showArrow
                                                            allowClear
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                (option?.children?.toString() || "")
                                                                    .toLowerCase()
                                                                    .includes(input.toLowerCase().trim())
                                                            }
                                                            onChange={zoneChange}
                                                        >
                                                            {zoneState.zonesData.data.rows
                                                                .filter(
                                                                    (zone) =>
                                                                        zone?.id === currentUserZone?.id ||
                                                                        zone?.parent_id?.id ===
                                                                        currentUserZone?.id
                                                                )
                                                                .filter((z) => z.status === true)
                                                                .map((zone) => (
                                                                    <Option value={zone.id.toString()}>
                                                                        {zone.name}
                                                                    </Option>
                                                                ))}
                                                        </Select>
                                                    </Form.Item>
                                                </FloatLabel>
                                            </Col>
                                        )
                                    }
                                    {currentUserZone?.type !== ZoneType.PRIVATE && (
                                        <Col xs={24}>
                                            <FloatLabel
                                                label="Branch Code"
                                                placeholder="Select Branch Code"
                                                name="branch_ids"
                                            >
                                                <Form.Item name="branch_ids">
                                                    <Select
                                                        getPopupContainer={(trigger) =>
                                                            trigger.parentNode
                                                        }
                                                        mode="multiple"
                                                        showArrow
                                                        allowClear
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            (option?.children?.toString() || "")
                                                                .toLowerCase()
                                                                .includes(input.toLowerCase().trim())
                                                        }
                                                        onChange={branchChange}
                                                    >
                                                        {branchState.branchesData.data.rows
                                                            .filter((item) =>
                                                                zoneId
                                                                    ? Number(item.zone?.parent_id[0].id) ===
                                                                    Number(zoneId)
                                                                    : item
                                                            )
                                                            .map((branch) => {
                                                                return (
                                                                    <Option value={branch?.id.toString()}>
                                                                        {branch?.code}
                                                                    </Option>
                                                                );
                                                            })}
                                                    </Select>
                                                </Form.Item>
                                            </FloatLabel>
                                        </Col>
                                    )}
                                    <Col xs={24}>
                                        <FloatLabel
                                            label="ExamList Status"
                                            placeholder="Select ExamList Status"
                                            name="status"
                                        >
                                            <Form.Item name="status">
                                                <Select
                                                    getPopupContainer={(trigger) => trigger.parentNode}
                                                    allowClear
                                                    showArrow
                                                    size="large"
                                                    filterOption={(input, option) =>
                                                        (option?.children?.toString() || "")
                                                            .toLowerCase()
                                                            .includes(input.toLowerCase().trim())
                                                    }
                                                >
                                                    <Option value={Exam_List.INITIALIZE}>
                                                        {Exam_List.INITIALIZE}
                                                    </Option>
                                                    <Option value={Exam_List.PUBLISH}>
                                                        {Exam_List.PUBLISH}
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </FloatLabel>
                                    </Col>
                                </Row>
                            </Form>
                        </Drawer>
                    </div>

                    <Col
                        xxl={4}
                        className="erp-filter-btn gx-d-none gx-d-xl-flex gx-align-items-center gx-justify-content-end"
                        style={{ textAlign: "end" }}
                    >
                        {(searchParams.get("status") === "INITIALIZE") && (
                            <Can
                                I={Common.Actions.CAN_UPDATE}
                                a={Common.Modules.ACADEMIC.ACADEMIC_EXAM_RESULT_UPDATE}
                            >
                                <Button
                                    disabled={!selectedRows.length}
                                    onClick={() => {
                                        Modal.confirm({
                                            title: "Are you sure Publish the student?",
                                            okText: "Yes",
                                            cancelText: "No",
                                            onOk() {
                                                handlePublishClick();
                                            },
                                        });
                                    }}
                                    className="btn-submit gx-mt-0"
                                    type="primary"
                                >
                                    Publish
                                </Button>
                            </Can>
                        )}
                        <Button
                            icon={<FilterFilled />}
                            onClick={() => setChequeModal(true)}
                        >
                            Filter
                            <span>
                                <Badge count={count}></Badge>
                            </span>
                        </Button>
                    </Col>
                </Row>
                <Card className="table-card">
                    <div className="table-bg exam-body">
                        <div className="card-body faculty-dashboard">
                            <table className="faculty-signing exam-list ant-table" style={{ minWidth: "1200px" }}>
                                <thead>
                                    <tr>
                                        <Can
                                            I={Common.Actions.CAN_UPDATE}
                                            a={Common.Modules.ACADEMIC.ACADEMIC_EXAM_RESULT_UPDATE}
                                        >
                                            {(searchParams.get("status") === "INITIALIZE") && (
                                                <th style={{ width: "4%" }}>
                                                    <Form.Item className='gx-mb-0'>
                                                        <Checkbox
                                                            style={{ fontWeight: 400 }}
                                                            className="selectAllCheckbox"
                                                            onChange={(e) => handleAllSelect(e.target.checked)}
                                                            checked={isAllSelected}
                                                        />
                                                    </Form.Item>
                                                </th>
                                            )}
                                        </Can>

                                        <th style={{ width: "3%" }}>No.</th>
                                        <th style={{ width: "10%" }}>Gr Id.</th>
                                        <th style={{ width: "300px" }}>Student Name</th>
                                        <th style={{ width: "150px" }}>Status</th>
                                        <th style={{ width: "150px" }}>Result</th>
                                        <th style={{ width: "500px" }}>Course Wise Marks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {enhancedListingData && enhancedListingData.length > 0 ? (
                                        enhancedListingData.map((row, rowIndex) => (
                                            <React.Fragment key={rowIndex}>
                                                {row.admission_results?.map((result, resultIndex: number) => (
                                                    <React.Fragment key={result.id}>
                                                        {result.result?.map((detail, detailIndex: number) => {
                                                            const rowId = result.id;
                                                            return (
                                                                <tr key={rowId} className={`sub-topic ${rowIndex % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                                                                    <Can
                                                                        I={Common.Actions.CAN_UPDATE}
                                                                        a={Common.Modules.ACADEMIC.ACADEMIC_EXAM_RESULT_UPDATE}
                                                                    >
                                                                        {(searchParams.get("status") === "INITIALIZE") && (
                                                                            <td align="center">
                                                                                <Checkbox
                                                                                    style={{ fontWeight: 400 }}
                                                                                    checked={isRowSelected(rowId)}
                                                                                    onChange={(e) => handleCheckboxChange(rowId, e.target.checked)}
                                                                                />
                                                                            </td>
                                                                        )}
                                                                    </Can>
                                                                    <td rowSpan={result.result?.length} align="center">
                                                                        <span style={{ display: "inline-block" }}>
                                                                            {(page - 1) * Number(searchParams.get("take")) + rowIndex + 1}
                                                                        </span>
                                                                    </td>
                                                                    <td rowSpan={result.result?.length} style={{ textAlign: "center", wordBreak: "break-word" }}>
                                                                        {row.gr_id}
                                                                    </td>
                                                                    <td rowSpan={result.result?.length} style={{ textAlign: "left", wordBreak: "break-word" }}>
                                                                        <span>
                                                                            {`${row.first_name} ${row?.middle_name} ${row?.last_name}`}
                                                                        </span>
                                                                        <StudentMobileNumber
                                                                            studentMobileNumber={String(row?.mobile_no)}
                                                                        />
                                                                    </td>
                                                                    <td align="center">
                                                                        {result.status}
                                                                    </td>
                                                                    <td style={{ textAlign: 'center' }}>
                                                                        <ol style={{ display: 'inline-block', textAlign: 'left', paddingLeft: '20px' }}>
                                                                            {detail.credits.map((credit, creditIndex: number) => (
                                                                                <li key={creditIndex}>
                                                                                    {`${credit.packageName}`}
                                                                                </li>
                                                                            ))}
                                                                        </ol>
                                                                    </td>
                                                                    <td>
                                                                        <table style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Course Name</th>
                                                                                    <th>Theory Marks</th>
                                                                                    <th>Practical Marks</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {detail.subcourse_results && detail.subcourse_results.length > 0 ? (
                                                                                    detail.subcourse_results?.map((subResult) => (
                                                                                        <tr key={subResult.sub_course_id}>
                                                                                            <td>{subResult.sub_cource_code}</td>
                                                                                            <td align="center">{subResult.type === 'EXAM_THEORY' ? subResult.percentage : '-'}</td>
                                                                                            <td align="center">{subResult.type === 'EXAM_PRACTICAL' ? subResult.percentage : '-'}</td>
                                                                                        </tr>
                                                                                    ))
                                                                                ) : (
                                                                                    <tr>
                                                                                        <td colSpan={3} align="center">No Data</td>
                                                                                    </tr>
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <tr key={"none"}>
                                            <td colSpan={8} style={{ border: "none" }}>
                                                <div>
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="pagination">
                        {examResultState.getListingResult.data.meta && examResultState.getListingResult.data?.meta.itemCount > 0 && (
                            <PaginationComponent
                                meta={
                                    examResultState.getListingResult.data.meta
                                        ? examResultState.getListingResult.data.meta
                                        : {
                                            take: 0,
                                            itemCount: 0,
                                            pageCount: 0,
                                            hasPreviousPage: false,
                                            hasNextPage: false,
                                        }
                                }
                            />
                        )}
                    </div>

                </Card>
            </Skeleton>
        </div>
    )
}

export default ExamList