import request from "../../lib/axios/request";
import { IApiResponse } from "../../utils/models/common";
import { config } from "src/utils";

class FeeTypeService {
  ENDPOINT = config.baseApiMasters + "/common/lookup";

  public createNewFeeType = async (
    data: string
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };
}
export default new FeeTypeService();
