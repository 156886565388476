import request from "src/lib/axios/request";
import { IPackageIds } from "src/pages/Package/Add/Add.model";
import { IPackageRecord } from "src/pages/Package/Packages.model";
import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import { IPackage, IPackageStatus, IResponse } from "./package.model";

class PackageService {
  ENDPOINT = config.baseApiMasters + "/packages";

  public searchPackageData = async (
    data: IPackage | URLSearchParams | {}
  ): Promise<IApiResponse<IPackage[]>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
  public commonCourse = async (
    data: IPackageIds
  ): Promise<IApiResponse<IPackage[]>> => {
    const url = `${this.ENDPOINT}/common-course`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public createNewPackage = async (
    data: IPackageRecord
  ): Promise<IApiResponse<IResponse>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public getPackageById = async (
    id: number
  ): Promise<IApiResponse<IResponse>> => {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: "GET" }).then((res) => {
      return res.data;
    });
  };
  public editPackageById = async (
    data: IPackageRecord
  ): Promise<IApiResponse<IResponse>> => {
    const url = `${this.ENDPOINT}/${data.id}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public removePackageById = async (id: number) => {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public updatePackageStatus = async (
    data: IPackageStatus
  ): Promise<IApiResponse<IResponse>> => {
    const url = `${this.ENDPOINT}/${data.id}/status`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };
}

export default new PackageService();
