import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { createExamResult, getListingResult, publishResult } from "./examResult.action";
import { IExamResultState } from "./examResult.model";

export const initialState: IExamResultState = {
    createExamResult: {
        loading: false,
        hasErrors: false,
        message: "",
    },
    getListingResult: {
        loading: false,
        hasErrors: false,
        message: "",
        data: {
            rows: [],
            meta: {
                take: 0,
                itemCount: 0,
                pageCount: 0,
                hasPreviousPage: false,
                hasNextPage: false
            },
        },
    },
    publishResult: {
        loading: false,
        hasErrors: false,
        message: "",
    },
};

export const examResultSlice = createSlice({
    name: "batch-exam-result",
    initialState,
    reducers: {
        clearRemoveMessage: (state) => {
            state.createExamResult.message = "";
            state.publishResult.message = "";
        },
    },
    extraReducers: {
        [createExamResult.pending.type]: (state) => {
            state.createExamResult.loading = true;
        },
        [createExamResult.fulfilled.type]: (state, action) => {
            state.createExamResult.message = action.payload.message;
            state.createExamResult.loading = false;
            state.createExamResult.hasErrors = false;
        },
        [createExamResult.rejected.type]: (state, action) => {
            state.createExamResult.loading = false;
            state.createExamResult.hasErrors = true;
            state.createExamResult.message = action.error.message;
        },

        [getListingResult.pending.type]: (state) => {
            state.getListingResult.loading = true;
        },
        [getListingResult.fulfilled.type]: (state, action) => {
            state.getListingResult.data = action.payload.data;
            state.getListingResult.message = action.payload.message;
            state.getListingResult.loading = false;
            state.getListingResult.hasErrors = false;
        },
        [getListingResult.rejected.type]: (state, action) => {
            state.getListingResult.loading = false;
            state.getListingResult.hasErrors = true;
            state.getListingResult.message = action.error.message;
        },

        [publishResult.pending.type]: (state) => {
            state.publishResult.loading = true;
        },
        [publishResult.fulfilled.type]: (state, action) => {
            state.publishResult.message = action.payload.message;
            state.publishResult.loading = false;
            state.publishResult.hasErrors = false;
        },
        [publishResult.rejected.type]: (state, action) => {
            state.publishResult.loading = false;
            state.publishResult.hasErrors = true;
            state.publishResult.message = action.error.message;
        },

    },
});

// A selector
export const examResultSelector = (state: RootState) => state.examResult;

export const { clearRemoveMessage } = examResultSlice.actions;

// The reducer
export default examResultSlice.reducer;
