import { Col, Input, Row, Form, Button, Select, InputNumber, Radio, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import FloatLabel from 'src/components/Form/FloatLabel';
import TextEditor from 'src/components/TextEditor';
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Option } from 'antd/lib/mentions';
import { Priority, QuestionType, TopicType } from 'src/utils/constants/constant';
import CustomLabel from 'src/components/CustomLabel';
import { IEditQuestione, IGetQuestionTagsData } from 'src/services/subCourseQuestion/subCourseQuestion.model';
import { IQuestionAddProps } from './Add.model';
import { questionSelector } from 'src/state/SubCourseQuestion/subCourseQuestion.reducer';
import { useAppSelector } from 'src/state/app.hooks';
import { getQuestionTagsData } from 'src/state/SubCourseQuestion/subCourseQuestion.action';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/state/app.model';

const SubCourseQuestionAdd = (props: IQuestionAddProps) => {
    const { data, onSubmit, myRef } = props;
    const { Text } = Typography;
    const [editorValue, setEditorValue] = useState("");
    const [form] = Form.useForm();
    const typeOfQuestion = Form.useWatch("question_type", form);
    const subCourseQuestionState = useAppSelector(questionSelector);
    const dispatch = useDispatch<AppDispatch>();
    const { subcourse_id } = useParams();

    useEffect(()=>{
        dispatch(getQuestionTagsData({ subcourse_id: subcourse_id }))
    },[])

    const rules = {
      type: [{ required: true, message: "Please Select Type" }],
      questiontype: [
        { required: true, message: "Please Select Question Type" },
      ],
      severity: [{ required: true, message: "Please Select severity" }],
      question: [{ required: true, message: "Please Enter question" }],
      duration: [{ required: true, message: "Please Enter Duration" }],
      marks: [
        { required: true, message: "Please Enter marks" },
        {
          validator: (rule: any, value: number) => {
            if (!value || value <= 100) {
              return Promise.resolve();
            }
            return Promise.reject("Marks must not be greater than 100");
          },
        },
        {
          pattern: new RegExp(/^[0-9]+$/),
          message: "Only numbers are allowed",
        },
      ],
      option: [{ required: true, message: "Please Enter option" }],
      question_tags: [
        { required: true, message: "Please Select Question Tags" },
      ],
    };

    const value = {
      ...data,
      mcq_answer:
        data?.mcqAnswers && data.question_type === QuestionType.MCQ
          ? [...data?.mcqAnswers].sort(
              (a, b) => (a.id as number) - (b.id as number)
            )
          : [""],
      question_tags: data?.tags ? data.tags.map((tag) => tag.name) : [],
      description: data?.description ? data.description : undefined,
    };

    return (
      <>
        <Form
          className="drawer-form question-form"
          name="dynamic_form_nest_item"
          form={form}
          initialValues={data ? value : { mcq_answer: [""] }}
          onFinish={(values: IEditQuestione) => {
            onSubmit(values);
          }}
          layout="vertical"
          autoComplete="off"
          requiredMark={true}
        >
          <Row gutter={10}>
            <Col span={24}>
              <FloatLabel
                label="Topic Type"
                placeholder="Select Topic Type"
                required
                name="topic_type"
              >
                <Form.Item name="topic_type" rules={rules.type}>
                  <Select
                    size="large"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      (option?.children?.toString() || "")
                        .toLowerCase()
                        .includes(input.toLowerCase().trim())
                    }
                  >
                    <Option key="1" value={TopicType.LECTURE}>
                      LECTURE
                    </Option>
                    <Option key="2" value={TopicType.PROJECT}>
                      PROJECT
                    </Option>
                    <Option key="3" value={TopicType.VIVA}>
                      VIVA
                    </Option>
                    <Option key="4" value={TopicType.EXAM_PRACTICAL}>
                      EXAM PRACTICAL
                    </Option>
                    <Option key="5" value={TopicType.EXAM_THEORY}>
                      EXAM THEORY
                    </Option>
                    <Option key="6" value={TopicType.ASSIGNMENT}>
                      ASSIGNMENT
                    </Option>
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col span={24}>
              <FloatLabel
                label="Question Type"
                placeholder="Select Question Type"
                required
                name="question_type"
              >
                <Form.Item name="question_type" rules={rules.questiontype}>
                  <Select
                    size="large"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      (option?.children?.toString() || "")
                        .toLowerCase()
                        .includes(input.toLowerCase().trim())
                    }
                  >
                    <Option key="1" value={QuestionType.MCQ}>
                      MCQ
                    </Option>
                    <Option key="2" value={QuestionType.SUBJECTIVE}>
                      SUBJECTIVE
                    </Option>
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col span={24}>
              <FloatLabel
                label="Severity Type"
                placeholder="Select Severity Type"
                required
                name="severity"
              >
                <Form.Item name="severity" rules={rules.severity}>
                  <Select
                    size="large"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      (option?.children?.toString() || "")
                        .toLowerCase()
                        .includes(input.toLowerCase().trim())
                    }
                  >
                    <Option key="1" value={Priority.HIGH}>
                      HIGH
                    </Option>
                    <Option key="2" value={Priority.MEDIUM}>
                      MEDIUM
                    </Option>
                    <Option key="3" value={Priority.LOW}>
                      LOW
                    </Option>
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col span={24}>
              <FloatLabel
                label="Question"
                placeholder="Enter Question"
                name="question"
                required
              >
                <Form.Item name="question" rules={rules.question}>
                  <Input size="large" />
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col span={24}>
              <FloatLabel
                label="Marks"
                placeholder="Marks"
                name="marks"
                required
              >
                <Form.Item name="marks" rules={rules.marks}>
                  <InputNumber size="large" min={0} />
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col
              span={24}
              className="gx-text-left text-editor-border"
              id="subtopiceditor"
            >
              <FloatLabel
                label="Description"
                placeholder="Enter Description"
                name="description"
              >
                <Form.Item name="description">
                  <TextEditor
                    value={editorValue || " "}
                    placeholder=""
                    onChange={setEditorValue}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col xs={24}>
              <FloatLabel
                label="Tags"
                placeholder="Select Tags"
                name="question_tags"
              >
                <Form.Item name="question_tags" rules={rules.question_tags}>
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    mode="tags"
                    allowClear
                    showSearch
                    style={{ width: "100%" }}
                    filterOption={(value, option) =>
                      (option?.children?.toString() || "")
                        .toLowerCase()
                        .includes(value.toLowerCase().trim())
                    }
                  >
                    {subCourseQuestionState.questionTags.tags.map(
                      (data: IGetQuestionTagsData) => (
                        <Option key={data.id?.toString()} value={data.name}>
                          {data.name}
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
          </Row>
          <div style={{ display: "block" }}>
            <hr className="gx-mt-1" />
            {typeOfQuestion === QuestionType.MCQ && (
              <>
                <CustomLabel label="Option" />
                <Form.List name="mcq_answer">
                  {(fields, { add, remove }) => (
                    <div>
                      {fields.map(({ key, name, ...restField }) => (
                        <>
                          <div key={key}>
                            <Row gutter={24}>
                              <Col xs={12} sm={16} md={16} lg={16} xxl={15}>
                                <FloatLabel
                                  label="Option"
                                  placeholder="Enter Option Of MCQ"
                                  name={["mcq_answer", name, "optaion"]}
                                  required
                                >
                                  <Form.Item
                                    {...restField}
                                    name={[name, "optaion"]}
                                    rules={rules.option}
                                  >
                                    <Input size="large" />
                                  </Form.Item>
                                </FloatLabel>
                              </Col>
                              <Col
                                xs={9}
                                md={6}
                                lg={6}
                                sm={6}
                                xxl={7}
                                className="gx-text-left"
                              >
                                <Text strong style={{ display: "block" }}>
                                  Option Is
                                </Text>
                                <FloatLabel
                                  label=""
                                  placeholder=""
                                  name={["mcq_answer", name, "is_right"]}
                                >
                                  <Form.Item
                                    {...restField}
                                    name={[name, "is_right"]}
                                  >
                                    <Radio.Group>
                                      <Radio value={true}> Ture </Radio>
                                      <Radio value={false}> False </Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </FloatLabel>
                              </Col>
                              <Col xs={2} lg={2} md={2} sm={2} xxl={2}>
                                {key !== 0 ? (
                                  <Button
                                    onClick={() => remove(name)}
                                    icon={<MinusOutlined />}
                                  ></Button>
                                ) : (
                                  <Button
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}
                                  ></Button>
                                )}
                              </Col>
                              <Col xs={24} className="gx-d-lg-none">
                                <hr />
                              </Col>
                            </Row>
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                </Form.List>
              </>
            )}
          </div>
          <Form.Item className="modal-btn-grp" style={{ display: "none" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              ref={myRef}
            >
              {data ? "Update" : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </>
    );
}

export default SubCourseQuestionAdd