import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICreateQuestionBank, IUpdateQuestionBank } from "src/services/subCourseQuestionBank/subCourseQuestionBank.modal";
import subCourseQuestionBankService from "src/services/subCourseQuestionBank/subCourseQuestionBank.service";

export const searchSubCourseQuestionBankData = createAsyncThunk(
  "questionbank/searchSubCourseQuestionBankData",
  async (data: URLSearchParams | {}) => {
    return subCourseQuestionBankService.searchSubCourseQuestionBankData(data);
  }
);
export const createNewSubCourseQuestionBank = createAsyncThunk(
  "questionbank/createNewSubCourseQuestionBank",
  async (data: ICreateQuestionBank) => {
    return subCourseQuestionBankService.createNewSubCourseQuestionBank(data);
  }
);

export const removeSubCourseQuestionBankById = createAsyncThunk(
  "questionbank/removeSubCourseQuestionBankById",
  async (id: number) => {
    return subCourseQuestionBankService.removeSubCourseQuestionBankById(id);
  }
);

export const updateSubCourseQuestionBankData = createAsyncThunk(
  "questionbank/updateSubCourseQuestionBankData",
  async (data: IUpdateQuestionBank) => {
    return subCourseQuestionBankService.updateSubCourseQuestionBankData(data);
  }
);
