import { Button, Card, Empty } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'src/state/app.hooks';
import { AppDispatch } from 'src/state/app.model';
import { createNewSubCourseQuestion, searchSubCourseQuestionData } from 'src/state/SubCourseQuestion/subCourseQuestion.action';
import { questionSelector } from 'src/state/SubCourseQuestion/subCourseQuestion.reducer';
import ModalComponent from '../Modal';
import { IQuestioneDetails } from 'src/services/subCourseQuestion/subCourseQuestion.model';
import { Question, TopicType } from 'src/utils/constants/constant';
import { PlusOutlined } from "@ant-design/icons";
import { subCourseTopicSelector } from 'src/state/subCourseTopic/subCourseTopic.reducer';
import { trimObject } from 'src/utils/helperFunction';
import { QuestionForm } from '../QuestionForm';
import { searchSubCourseTopicData } from 'src/state/subCourseTopic/subCourseTopic.action';
import { ISelectedCount, ISelectedTag, ISubCourseQuestionRecord } from 'src/pages/SubCourseQuestion/Question.model';
import { ISubCourseTopicDetails } from 'src/services/subCourseTopic/subCourseTopic.model';
import { Can } from 'src/ability/can';
import { Common } from 'src/utils/constants/constant';

const QuestionViewList = () => {
    const dispatch = useDispatch<AppDispatch>();
    const subCourseTopicState = useAppSelector(subCourseTopicSelector);
    const subCourseQuestionState = useAppSelector(questionSelector);
    const [topicSubtoicdata, setTopicSubTopicData] = useState<ISubCourseTopicDetails[]>()
    const [questionData, setQuestionData] = useState<IQuestioneDetails[]>()
    const { subcourse_id } = useParams();
    const [openModel, setOpenModel] = useState<boolean>(false);
    const [selectedSubTopic, setSelectedSubTopic] =
        useState<ISelectedTag>();
    const [openCountModel, setOpenCountModel] = useState<boolean>(false);
    const [selectedCountSubTopic, setSelectedCountSubTopic] =
        useState<ISelectedCount>();
    const [btn, setBtn] = useState(false);

    useEffect(() => {
        setTopicSubTopicData(subCourseTopicState.subCourseTopicData.data?.rows)
    }, [subCourseTopicState.subCourseTopicData.data?.rows])

    useEffect(() => {
        setQuestionData(subCourseQuestionState.SubCourseQuestionData.data?.rows)
    }, [subCourseQuestionState.SubCourseQuestionData.data?.rows])

    const apiCallonSubmit = () => {
        dispatch(searchSubCourseTopicData({
            noLimit: true,
            orderBy: "sequence",
            order: "ASC",
            subcourse_id: subcourse_id
        }));
        dispatch(searchSubCourseQuestionData({
            noLimit: true,
            orderBy: "id",
            order: "ASC",
            subcourse_id: subcourse_id
        }));
    }

    const topicDataWithMatchingCount = topicSubtoicdata?.map((topicItem) => {
        const topicTags = topicItem.tags || [];

        const subTopic = topicItem.subTopics?.map((subtopic) => {
            const subTopicQuestion: IQuestioneDetails[] = [];
            const subToipcTags = subtopic?.tags || [];
            subToipcTags.forEach((subTopicTag) => {
                questionData?.forEach((questionTag) => {
                    questionTag.tags?.map((data) => {
                        if (data.id === subTopicTag.id && !subTopicQuestion.some((item) => item.id === questionTag.id)) {
                            subTopicQuestion.push(questionTag);
                        }
                    })
                });
            });
            return {
                ...subtopic,
                question: subTopicQuestion
            };
        })

        const topicQuestion: IQuestioneDetails[] = [];

        topicTags.forEach((topicTag) => {
            questionData?.map((questionTag) => {
                questionTag.tags?.map((data) => {
                    if (data.id === topicTag.id && !topicQuestion.some((item) => item.id === questionTag.id)) {
                        topicQuestion.push(questionTag);
                    }
                })
            });
        });

        return {
            ...topicItem,
            question: topicQuestion,
            subTopics: subTopic
        };
    });

    const onSubmit = async (values: ISubCourseQuestionRecord) => {
        setBtn(true);
        let payload = {
            ...values,
            topic_type: selectedSubTopic?.type || TopicType.LECTURE,
            subcourse_id: Number(subcourse_id),
            mcq_answer: values.mcq_answer?.map((answer) => ({
                ...answer,
                id: 0
            })),
            question_tags: values.question_tags?.map((tag) => ({
                name: tag,
                type: Question.QUESTION,
                subcourse_id: subcourse_id
            })),
        };
        setOpenModel(true);
        const res = await dispatch(createNewSubCourseQuestion(trimObject(payload)))
        if (res.payload) {
            setBtn(false);
            apiCallonSubmit();
            setOpenModel(false);
        } else {
            setOpenModel(true);
        }
    }

    return (
        <>
            <Card className="table-card signingsheet-card">
                <div>
                    <table className="faculty-signing second-view-question" style={{ minWidth: "630px" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "3%" }}>No.</th>
                                <th style={{ width: "30%", textAlign: "left" }}>Topic</th>
                                <th style={{ width: "3%" }}>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {topicDataWithMatchingCount && topicDataWithMatchingCount.length > 0 ? (
                                topicDataWithMatchingCount.map((topic, index: number) => (
                                    <>
                                        <tr key={index} className="main-topic">
                                            <td align="center">
                                                <span style={{ display: "inline-block" }}>
                                                    {index + 1}
                                                </span>
                                            </td>

                                            <td style={{ textAlign: "left", wordBreak: "break-word" }}>
                                                {topic.name}
                                                <span className="project-type">({topic.type})</span>
                                                <div className='tag-name'>
                                                    {
                                                        topic.tags?.map((data, index) => (
                                                            <span>
                                                                {data.name}
                                                                {topic.tags && index !== topic.tags?.length - 1 && ', '}
                                                            </span>
                                                        ))
                                                    }
                                                </div>
                                            </td>

                                            <td align="center">
                                                <div className="content-wrappera">
                                                    <div
                                                        className="gx-text-danger"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedCountSubTopic(topic)
                                                            setOpenCountModel(true)
                                                        }}
                                                    >
                                                        {topic?.question.length}
                                                    </div>
                                                    <div>
                                                        <Can
                                                            I={Common.Actions.CAN_ADD}
                                                            a={Common.Modules.MASTER.MASTER_SUBCOURSE_EXAM_QUESTION}
                                                        >
                                                            <Button
                                                                className='plus-btn'
                                                                type="primary"
                                                                onClick={() => {
                                                                    setSelectedSubTopic(topic)
                                                                    setOpenModel(true)
                                                                }}
                                                                icon={<PlusOutlined />}
                                                            />
                                                        </Can>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        {
                                            topic.subTopics?.map((data, i) => (
                                                <>
                                                    <tr key={index} className='sub-topic'>
                                                        <td align="center">
                                                            <span
                                                                style={{
                                                                    display: "inline-block",
                                                                }}
                                                            >
                                                                {index + 1}.{i + 1}
                                                            </span>
                                                        </td>
                                                        <td style={{ textAlign: "left", wordBreak: "break-word" }}>
                                                            <div
                                                                className="shiningsheet-students"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: data.description,
                                                                }}
                                                            ></div>
                                                            <div className='tag-name'>
                                                                {
                                                                    data.tags?.map((subtopicTag, index) => (
                                                                        <span>
                                                                            {subtopicTag.name}
                                                                            {data.tags && index !== data.tags?.length - 1 && ', '}
                                                                        </span>
                                                                    ))
                                                                }
                                                            </div>
                                                        </td>
                                                        <td align="center">
                                                            {topic.type === TopicType.LECTURE && (
                                                                <div className="content-wrappera">
                                                                    <div
                                                                        className="gx-text-danger"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setSelectedCountSubTopic(data)
                                                                            setOpenCountModel(true)
                                                                        }}
                                                                    >
                                                                        {data?.question.length}
                                                                    </div>
                                                                    <div>
                                                                        <Can
                                                                            I={Common.Actions.CAN_ADD}
                                                                            a={Common.Modules.MASTER.MASTER_SUBCOURSE_EXAM_QUESTION}
                                                                        >
                                                                            <Button
                                                                                className='plus-btn'
                                                                                type="primary"
                                                                                onClick={() => {
                                                                                    setSelectedSubTopic(data)
                                                                                    setOpenModel(true)
                                                                                }}
                                                                                icon={<PlusOutlined />}
                                                                            />
                                                                        </Can>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </>
                                            ))

                                        }
                                    </>
                                ))
                            ) : (
                                <tr key={"none"}>
                                    <td colSpan={8} style={{ border: "none" }}>
                                        <div>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </div>
                                    </td>
                                </tr>
                            )
                            }
                        </tbody>
                    </table>
                </div>
            </Card>

            {openModel && (
                <ModalComponent
                    className="show-studentList absent-studentList question-modal-form"
                    title={`Create Question`}
                    showModal={openModel}
                    onCancel={() => {
                        setOpenModel(false);
                    }}
                    component={
                        <>
                            <QuestionForm
                                onSubmit={onSubmit}
                                selectedSubTopic={selectedSubTopic} />
                        </>
                    }
                    footer={[
                        <Button key="back" onClick={() => setOpenModel(false)}>
                            Cancel
                        </Button>,
                        <Button
                            className="btn-apply-filter"
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            form="question_create_form"
                            disabled={btn}
                        >
                            Submit
                        </Button>,
                    ]}
                ></ModalComponent>
            )}

            {openCountModel && (
                <ModalComponent
                    className="show-studentList absent-studentList"
                    title="Question List"
                    showModal={openCountModel}
                    onCancel={() => {
                        setOpenCountModel(false);
                    }}
                    component={
                        <>
                            <Card className='table-card question-table'>
                                <div>
                                    <table className="faculty-signing" style={{ minWidth: "600px" }}>
                                        <thead>
                                            <tr>
                                                <th align="center" style={{ width: "3%" }}>No.</th>
                                                <th>Question</th>
                                                <th>Severity</th>
                                                <th align="center">Marks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedCountSubTopic && selectedCountSubTopic.question && selectedCountSubTopic.question.length > 0 ? (
                                                selectedCountSubTopic.question.map((question, index) => (
                                                    <tr className="sub-topic" key={index}>
                                                        <td align="center">
                                                            <span style={{ display: "inline-block" }}>
                                                                {index + 1}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {question.question}
                                                        </td>
                                                        <td>
                                                            {question.severity}
                                                        </td>
                                                        <td align="center">
                                                            {question.marks}
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr key="none">
                                                    <td colSpan={4} style={{ border: "none" }}>
                                                        <div>
                                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Card>
                        </>
                    }
                    footer={[
                        <div>
                            <Button key="back" onClick={() => setOpenCountModel(false)}>
                                Cancel
                            </Button>
                        </div>
                    ]}
                ></ModalComponent>
            )}
        </>
    )
}

export default QuestionViewList