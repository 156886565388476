import { IRoleRecord } from "src/pages/Role/Role.model";
import { config } from "src/utils";
import request from "../../lib/axios/request";
import { IApiResponse } from "../../utils/models/common";
import {
  IGetRolePermissions,
  IRole,
  IRoleDetails,
  IRoleStatus,
} from "./role.model";

class RoleService {
  ENDPOINT = config.baseApiAuth + "/roles";

  public searchRoleData = async (
    data: IRoleDetails
  ): Promise<IApiResponse<IRole[]>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public createNewRole = async (
    data: IRoleDetails
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public editRoleById = async (
    data: IRoleRecord
  ): Promise<IApiResponse<any>> => {
    const url = `${this.ENDPOINT}/${data.id}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public removeRoleById = async (id: number) => {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public updateRoleStatus = async (
    data: IRoleStatus
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}/status`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

  public getRolePermissions = async (
    id: number
  ): Promise<IApiResponse<IGetRolePermissions>> => {
    const url = `${this.ENDPOINT}/permissions/${id}`;
    return request({ url, method: "GET" }).then((res) => {
      return res.data;
    });
  };
}

export default new RoleService();
