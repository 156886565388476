import request from "src/lib/axios/request";
import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import { ITags } from "./tags.model";


class TagService {
  ENDPOINT = config.baseApiMasters + "/tags";

  public getTags = async (): Promise<IApiResponse<ITags[]>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET" }).then((res) => {
      return res.data;
    });
  };
}

export default new TagService();
