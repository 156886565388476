import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { getBranchesWisePcAndLaptop, searchPlacesData } from "./places.action";
import { IPlacesState } from "./places.model";

export const initialState: IPlacesState = {
  placesData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: { rows: [] },
  },

  branchWisePcAndLaptop: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
};

export const placesSlice = createSlice({
  name: "places",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.placesData.message = "";
    },
  },
  extraReducers: {
    [searchPlacesData.pending.type]: (state) => {
      state.placesData.loading = true;
    },
    [searchPlacesData.fulfilled.type]: (state, action) => {
      state.placesData.data = action.payload.data;
      state.placesData.message = action.payload.message;
      state.placesData.loading = false;
      state.placesData.hasErrors = false;
    },
    [searchPlacesData.rejected.type]: (state, action) => {
      state.placesData.loading = false;
      state.placesData.hasErrors = true;
      state.placesData.message = action.error.message;
    },

    // Branch Wise Pc And Laptop
    [getBranchesWisePcAndLaptop.pending.type]: (state) => {
      state.branchWisePcAndLaptop.loading = true;
    },
    [getBranchesWisePcAndLaptop.fulfilled.type]: (state, action) => {
      state.branchWisePcAndLaptop.data = action.payload.data;
      state.branchWisePcAndLaptop.message = action.payload.message;
      state.branchWisePcAndLaptop.loading = false;
      state.branchWisePcAndLaptop.hasErrors = false;
    },
    [getBranchesWisePcAndLaptop.rejected.type]: (state, action) => {
      state.branchWisePcAndLaptop.loading = false;
      state.branchWisePcAndLaptop.hasErrors = true;
      state.branchWisePcAndLaptop.message = action.error.message;
    },
  },
});

// A selector
export const placesSelector = (state: RootState) => state.places;

export const { clearRemoveMessage } = placesSlice.actions;

// The reducer
export default placesSlice.reducer;
