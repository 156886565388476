import { createAsyncThunk } from "@reduxjs/toolkit";
import { IHardwareEditApi } from "src/components/ShowPlaces/ShowPlaces.model";
import { ISearchPlacesData } from "src/services/places/places.model";
import PlacesServices from "src/services/places/places.service";
import { IBranchWisePcAndLaptopFilters } from "./places.model";

export const bulkUpdateEditPlaces = createAsyncThunk(
  "places/bulkUpdateEditPlaces",
  async (data: IHardwareEditApi) => {
    return PlacesServices.bulkUpdateEditPlaces(data);
  }
);

export const searchPlacesData = createAsyncThunk(
  "places/searchPlacesData",
  async (data: ISearchPlacesData) => {
    return PlacesServices.searchPlacesData(data);
  }
);

export const getBranchesWisePcAndLaptop = createAsyncThunk(
  "places/getBranchesWisePcAndLaptop",
  async (data:IBranchWisePcAndLaptopFilters) => {
    return PlacesServices.getBranchesWisePcAndLaptop(data);
  }
);
