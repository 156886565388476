import { Button, Col, Form, Input, InputNumber, Radio, Row, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import FloatLabel from "src/components/Form/FloatLabel";
import TextEditor from "src/components/TextEditor";
import { getGrIdData } from "src/state/admission/admission.action";
import {
  admissionSelector,
  clearRemoveMessage,
} from "src/state/admission/admission.reducer";
import { useAppSelector } from "src/state/app.hooks";
import { AppDispatch } from "src/state/app.model";
import { searchPaymentModeData } from "src/state/paymentMode/paymentMode.action";
import { PayementModeType } from "src/utils/constants/constant";
import {
  IAddPenaltyFormProps,
  IAddPenaltyFormValues,
} from "./AddPenalty.model";
import PaymentModeComponent from "src/components/PaymentMode";

const AddPenalty = (props: IAddPenaltyFormProps) => {
  const { onSubmit, myRef, setAdmissionId, setBranchId  } = props;
  const grIdState = useAppSelector(admissionSelector);

  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const [editorValue, setEditorValue] = useState("");
  const messageRef = useRef("");
  const grid = Form.useWatch("gr_id", form);
  const remarkField = Form.useWatch("remarks", form);
  const { Text } = Typography;

  const rules = {
    gr_id: [{ required: true, message: "Please Enter GR Id" }],
    gst: [{ required: true, message: "Please Select GST" }],
    amount: [
      { required: true, message: "Please Enter Amount" },
      { pattern: new RegExp(/^[0-9]+$/), message: "only Number Are Allowed" },
    ],
    payment_mode: [{ required: true, message: "Please select payment mode" }],
    remarks: [{ required: true, message: "Please Enter reason" }],
    name: [{ required: true, message: "Please Enter Name" }, { max: 100 }],
  };

  useEffect(() => {
    dispatch(
      searchPaymentModeData({ noLimit: true, orderBy: "name", order: "ASC" })
    );
  }, []);

  useEffect(() => {
    if (grid) {
      dispatch(getGrIdData(grid));
    }
  }, [grid]);

  useEffect(() => {
    if(grid) {
      grIdState.grIdData.data.map((x) => {
        setAdmissionId(x.id)
        setBranchId(x?.branch_id as number)
        return form.setFieldValue(
          "full_name",
          x.first_name + " " + x.middle_name + " " + x.last_name
        );
      });
    }
  }, [grIdState.grIdData.data]);

  useEffect(() => {
    if (grIdState.grIdData.message) {
      messageRef.current = grIdState.grIdData.message;
      if (grIdState.grIdData.hasErrors) {
        messageRef.current = grIdState.grIdData.message;
        form.setFieldValue("full_name", undefined);
      }
      dispatch(clearRemoveMessage());
    }
  }, [grIdState.grIdData.message]);

  useEffect(() => {
    if (!!remarkField && !remarkField.replace(/<\/?[^>]+>/g, "")?.length) {
      form.setFieldValue("remarks", undefined);
    }
  }, [remarkField]);

  return (
    <Form
      name="basic"
      className="drawer-form penalty-form"
      onFinish={(values: IAddPenaltyFormValues) => {
        onSubmit(values);
      }}
      form={form}
      key={"addPenalty"}
      requiredMark={true}
    >
      <Row gutter={10}>
        <Col xs={24}>
          <FloatLabel
            label="GR Id"
            placeholder="Enter GR Id"
            name="gr_id"
            required
          >
            <Form.Item name="gr_id" rules={rules.gr_id}>
              <InputNumber size="large" maxLength={9} />
            </Form.Item>
          </FloatLabel>
          <span className="msg">
            {messageRef.current === "Student details fetch successfully"
              ? ""
              : messageRef.current}
          </span>
        </Col>
        <Col xs={24}>
          <FloatLabel
            label="Full Name"
            placeholder="Full Name"
            name="full_name"
          >
            <Form.Item name="full_name">
              <Input  size="large" disabled/>
            </Form.Item>
          </FloatLabel>
        </Col>
        <Col xs={24}>
          <FloatLabel
            label="Amount"
            placeholder="Enter Amount"
            name="pay_amount"
            required
          >
            <Form.Item name="pay_amount" rules={rules.amount}>
              <InputNumber type="number" size="large" maxLength={9}/>
            </Form.Item>
          </FloatLabel>
        </Col>

        {/* Payment Mode */}
        <PaymentModeComponent
          xl={24}
          md={24}
          sm={24}
          xs={24}
          hidePaymentMode={[PayementModeType.CHEQUE ,PayementModeType.DD,PayementModeType.NEFT_IMPS ]}
        />

        <Col
          span={24}
          className="gx-text-left text-editor-border"
          id="subtopiceditor"
        >
          <FloatLabel label="Reason" placeholder="Enter Reason" name="remarks">
            <Form.Item name="remarks" rules={rules.remarks}>
              <TextEditor
                value={editorValue || " "}
                placeholder=""
                onChange={setEditorValue}
              />
            </Form.Item>
          </FloatLabel>
        </Col>

        <Col span={24}>
          <Text strong style={{ display: "block" }}>
            Is GST
          </Text>
          <Form.Item name="isgst">
            <Radio.Group defaultValue={false}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Form.Item className="modal-btn-grp" style={{ display: "none" }}>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-submit"
            ref={myRef}
          >
            {"Submit"}
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default AddPenalty;
