import { createAsyncThunk } from "@reduxjs/toolkit";
import { IPackageIds } from "src/pages/Package/Add/Add.model";
import { IPackageRecord } from "src/pages/Package/Packages.model";
import { IPackageStatus } from "src/services/package/package.model";
import packageService from "src/services/package/package.service";

export const searchPackageData = createAsyncThunk(
  "package/searchPackageData",
  async (data: any) => {
    return packageService.searchPackageData(data);
  }
);

export const createNewpackage = createAsyncThunk(
  "package/createNewPackage",
  async (data: IPackageRecord) => {
    return packageService.createNewPackage(data);
  }
);

export const commonCourse = createAsyncThunk(
  "package/commonCourse",
  async (data: IPackageIds) => {
    return packageService.commonCourse(data);
  }
);
export const getPackageById = createAsyncThunk(
  "package/getPackageById",
  async (id: number) => {
    return packageService.getPackageById(id);
  }
);
export const editPackageById = createAsyncThunk(
  "package/editPackageById",
  async (data: IPackageRecord) => {
    return packageService.editPackageById(data);
  }
);
export const removePackageById = createAsyncThunk(
  "package/removePackageById",
  async (id: number) => {
    return packageService.removePackageById(id);
  }
);

export const updatePackageStatus = createAsyncThunk(
  "package/updatePackageStatus",
  async (data: IPackageStatus) => {
    return packageService.updatePackageStatus(data);
  }
);
