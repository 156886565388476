import { createAsyncThunk } from "@reduxjs/toolkit";
import { IExpenseRecord } from "src/pages/Expense/Expense.model";
import {
  IExpenseDetails,
  IExpenseStatus,
} from "src/services/expense/expense.model";
import expenseService from "src/services/expense/expense.service";

export const searchExpenseData = createAsyncThunk(
  "expense/searchExpenseData",
  async (data: IExpenseDetails | URLSearchParams) => {
    return expenseService.searchExpenseData(data);
  }
);

export const removeExpenseById = createAsyncThunk(
  "expense/removeExpenseById",
  async (id: number) => {
    return expenseService.removeExpenseById(id);
  }
);

export const updateExpenseStatus = createAsyncThunk(
  "expense/updateExpenseStatus",
  async (data: IExpenseStatus) => {
    return expenseService.updateExpenseStatus(data);
  }
);

export const createNewExpense = createAsyncThunk(
  "expense/createNewExpense",
  async (data: IExpenseDetails) => {
    return expenseService.createNewExpense(data);
  }
);

export const editExpenseById = createAsyncThunk(
  "expense/editExpenseById",
  async (data: IExpenseRecord) => {
    return expenseService.editExpenseById(data);
  }
);

export const expenseCsvMail = createAsyncThunk(
  "expense/expenseCsvMail",
  async (data: { [k: string]: string }) => {
    return expenseService.expenseCsvMail(data);
  }
);
