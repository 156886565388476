import { ITransferAdmissionRecord } from "src/pages/TransferAdmission/TransferAdmission.model";
import { config } from "src/utils";
import request from "../../lib/axios/request";
import { IApiResponse } from "../../utils/models/common";
import { ITransferAdmission } from "./transferadmission.model";

class TransferAdmissionService {
  ENDPOINT = config.baseApiAdmission + "/transfer";

  public searchTransferAdmissionData = async (
    data: ITransferAdmission | URLSearchParams | {}
  ): Promise<IApiResponse<ITransferAdmission[]>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public TransferAdmissionStatus = async (
    data: ITransferAdmissionRecord
  ): Promise<IApiResponse<ITransferAdmissionRecord>> => {
    const url = `${this.ENDPOINT}/${data?.id}/status`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

}

export default new TransferAdmissionService();
