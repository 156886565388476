import { Col, Row, Select, Form, Card, Input, Button, Typography, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import ContainerHeader from 'src/components/ContainerHeader';
import { useAppSelector } from 'src/state/app.hooks';
import { AppDispatch } from 'src/state/app.model';
import { searchBranchData } from 'src/state/branch/branch.action';
import { branchSelector } from 'src/state/branch/branch.reducer';
import { searchPackageData } from 'src/state/package/package.action';
import { packageSelector } from 'src/state/package/package.reducer';
import { PlusOutlined, MinusOutlined, CloseOutlined } from '@ant-design/icons';
import { showTooltip, trimObject } from 'src/utils/helperFunction';
import { createExamResult } from 'src/state/ExamResult/examResult.action';
import { examResultSelector, clearRemoveMessage } from 'src/state/ExamResult/examResult.reducer';
import { IPackageRecord } from '../Package/Packages.model';
import { IFormValues, IPackageGrouped, IPoint, ISubcourseName, Payload } from './index.model';

const { Option } = Select;

const ExamResult = () => {
    const dispatch = useDispatch<AppDispatch>();
    const branchState = useAppSelector(branchSelector);
    const packageState = useAppSelector(packageSelector);
    const examResultState = useAppSelector(examResultSelector);
    const [selectedBranch, setSelectedBranch] = useState<number | undefined>(undefined);
    const [selectedPackage, setSelectedPackage] = useState<number | undefined>(undefined);
    const [selectedPackageData, setSelectedPackageData] = useState<IPackageRecord | undefined>(undefined);
    const [subcourseNames, setSubcourseNames] = useState<ISubcourseName[]>([]);
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(
            searchBranchData({
                noLimit: true,
                orderBy: 'name',
                order: 'ASC',
                isZoneOnly: true,
            })
        );
        dispatch(
            searchPackageData({ noLimit: true, orderBy: 'name', order: 'ASC' })
        );
    }, [dispatch]);

    useEffect(() => {
        if (examResultState.createExamResult.message) {
            if (examResultState.createExamResult.hasErrors) {
                message.error(examResultState.createExamResult.message);
            } else {
                message.success(examResultState.createExamResult.message);
            }
            dispatch(clearRemoveMessage());
        }
    }, [examResultState.createExamResult.message]);

    const filteredPackages = useMemo(() => {
        if (selectedBranch !== undefined) {
            return packageState.packageData.data?.rows.filter((data) =>
                data.package_branches.some((branch) => branch.branch_id === selectedBranch)
            ) || [];
        } else {
            return packageState.packageData.data?.rows || [];
        }
    }, [selectedBranch, packageState.packageData.data?.rows]);

    useEffect(() => {
        if (selectedPackage !== undefined) {
            const selectedData = filteredPackages.find((data) => data.id === selectedPackage);
            setSelectedPackageData(selectedData as IPackageRecord | undefined);
        }
    }, [selectedPackage, filteredPackages]);

    useEffect(() => {
        if (selectedPackageData && selectedPackageData.package_subcourses) {
            const details: { name: string, type: string }[] = [];
            selectedPackageData.package_subcourses.forEach((data) => {
                data.subcourses?.forEach((subcourse) => {
                    details.push({ name: subcourse.name, type: 'T' });
                    details.push({ name: subcourse.name, type: 'P' });
                });
            });
            setSubcourseNames(Array.from(details));
        } else {
            setSubcourseNames([]);
        }
    }, [selectedPackageData]);

    const handleRemoveColumn = (index: number) => {
        setSubcourseNames(prevDetails => prevDetails.filter((_, i) => i !== index));
    };

    const handleFormSubmit = (values: { rows: IFormValues[] }) => {
        const { rows } = values;

        const payload: Payload = {
            branch_id: selectedBranch,
            package_id: selectedPackage,
            credits: []
        };

        const packageData: Record<number, IPackageGrouped> = rows.reduce((acc, row) => {
            const packageId = row.package;
            if (!acc[packageId]) {
                acc[packageId] = {
                    package_id: packageId,
                    points: []
                };
            }

            const points = subcourseNames.map(subcourse => {
                const type = subcourse.type === 'T' ? 'EXAM_THEORY' : 'EXAM_PRACTICAL';

                return {
                    sub_cource_code: subcourse.name,
                    value: Number(row[`subcourses[${subcourseNames.indexOf(subcourse)}]`]) || 0,
                    type
                };
            });

            acc[packageId].points.push(...points);
            return acc;
        }, {} as Record<number, IPackageGrouped>);

        payload.credits = Object.values(packageData);

        dispatch(createExamResult(trimObject(payload))).then((res) => {
            if (res.payload) {
                form.resetFields();
                setSelectedBranch(undefined);
                setSelectedPackage(undefined);
                setSelectedPackageData(undefined);
            }
        });
    };

    return (
        <>
            <Row
                align="middle"
                gutter={24}
                justify="space-between"
                className="mb-20 gx-flex-wrap gx-align-items-center gx-justify-content-between gx-pt-1"
            >
                <Col className="gx-mb-md-1">
                    <ContainerHeader title="Exam Result" />
                </Col>

                <Col xs={24} sm={17} md={12} xl={10}>
                    <div className="gx-d-flex gx-align-items-center gx-justify-content-end">
                        {branchState.branchesData.data?.rows.length > 0 && (
                            <Col xs={12} sm={12} className="gx-pr-sm-3">
                                <Select
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                    allowClear
                                    className="dash_input"
                                    style={{ width: '100%', textAlign: 'left' }}
                                    placeholder="Select Branch"
                                    showSearch
                                    showArrow
                                    size="large"
                                    onChange={(value: number | undefined) => {
                                        setSelectedBranch(value);
                                        setSelectedPackage(undefined);
                                        setSelectedPackageData(undefined);
                                        form.resetFields();
                                    }}
                                    filterOption={(value, option) =>
                                        (option?.children?.toString() || '')
                                            .toLowerCase()
                                            .includes(value.toLowerCase().trim())
                                    }
                                >
                                    {branchState.branchesData.data?.rows
                                        .filter((branch) => branch.status === true)
                                        .map((branch) => (
                                            <Option key={branch.id} value={branch.id}>
                                                {branch.code}
                                            </Option>
                                        ))}
                                </Select>
                            </Col>
                        )}
                        <Col xs={12} sm={12} className="gx-pr-sm-3">
                            <Select
                                getPopupContainer={(trigger) => trigger.parentNode}
                                allowClear
                                className="dash_input"
                                style={{ width: '100%', textAlign: 'left' }}
                                placeholder="Select Package"
                                showSearch
                                showArrow
                                size="large"
                                onChange={(value: number | undefined) => {
                                    setSelectedPackage(value);
                                    const selectedData = filteredPackages.find((data) => data.id === value);
                                    setSelectedPackageData(selectedData as IPackageRecord | undefined);
                                    form.resetFields();
                                }}
                                filterOption={(value, option) =>
                                    (option?.children?.toString() || '')
                                        .toLowerCase()
                                        .includes(value.toLowerCase().trim())
                                }
                                value={selectedPackage}
                            >
                                {filteredPackages.map((data) => (
                                    <Option key={data.id} value={data.id}>
                                        {data.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </div>
                </Col>
            </Row>

            <div>
                <Form
                    form={form}
                    name="examResultForm"
                    initialValues={{
                        rows: [""],
                    }}
                    onFinish={handleFormSubmit}
                    className='exam-result'
                >
                    <Card className="rnw-card table-card">
                        <Form.List name="rows">
                            {(fields, { add, remove }) => (
                                <div
                                    className='exam-result-table'
                                    style={{ borderRadius: "8px", overflow: "auto" }}
                                >
                                    <table className="faculty-signing" style={{ minWidth: subcourseNames.length <= 2 ? "800px" : "1400px" }}>
                                        <thead>
                                            <tr>
                                                <th className="sticky-top" style={{ width: "20%" }}>Package</th>
                                                {subcourseNames.map((detail, index: number) => {
                                                    const maxLength = 15;
                                                    return (
                                                        <th key={index} className="sticky-top">
                                                            <div className='subcourse-remove-btn'>
                                                                <span>{showTooltip(`${detail.name} - (${detail.type})`, maxLength)}</span>
                                                                <span>
                                                                    <Button
                                                                        style={{ marginLeft: '5px' }}
                                                                        icon={<CloseOutlined />}
                                                                        onClick={() => handleRemoveColumn(index)}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </th>
                                                    )
                                                })}
                                                {selectedPackageData && (<th className="sticky-top" style={{ width: "5%" }}>Total</th>)}
                                                <th className="sticky-top" style={{ width: "5%" }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <tr key={key}>
                                                    <td style={{ textAlign: "center" }}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'package']}
                                                        >
                                                            <Select
                                                                getPopupContainer={(trigger) => trigger.parentNode}
                                                                allowClear
                                                                className="dash_input"
                                                                placeholder="Select Package"
                                                                showSearch
                                                                showArrow
                                                                size="large"
                                                                style={{ width: "100%" }}
                                                            >
                                                                {selectedPackageData && filteredPackages
                                                                    .filter((data) => selectedPackageData?.id !== data.id)
                                                                    .map((data) => (
                                                                        <Option key={data.id} value={data.id}>
                                                                            {data.name}
                                                                        </Option>
                                                                    ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </td>
                                                    {subcourseNames.map((subCourseName, subIndex) => (
                                                        <td key={subIndex} style={{ textAlign: "center" }}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, `subcourses[${subIndex}]`]}
                                                            >
                                                                <Select
                                                                    getPopupContainer={(trigger) => trigger.parentNode}
                                                                    className="dash_input"
                                                                    placeholder="Select Rating"
                                                                    size="large"
                                                                    style={{ width: "100%" }}
                                                                    showSearch
                                                                    allowClear
                                                                    showArrow
                                                                >
                                                                    <Option value="0">0</Option>
                                                                    <Option value="1">1</Option>
                                                                    <Option value="2">2</Option>
                                                                    <Option value="3">3</Option>
                                                                    <Option value="4">4</Option>
                                                                    <Option value="5">5</Option>
                                                                    <Option value="6">6</Option>
                                                                    <Option value="7">7</Option>
                                                                    <Option value="8">8</Option>
                                                                    <Option value="9">9</Option>
                                                                    <Option value="10">10</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </td>
                                                    ))}
                                                    {selectedPackageData && (<td style={{ textAlign: "center" }}>10</td>)}
                                                    <td style={{ textAlign: "center" }}>
                                                        {key !== 0 ? (
                                                            <Button
                                                                onClick={() => remove(name)}
                                                                icon={<MinusOutlined />}
                                                            ></Button>
                                                        ) : (
                                                            <Button
                                                                onClick={() => add()}
                                                                icon={<PlusOutlined />}
                                                            ></Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </Form.List>
                        <div className='text-align-right'>
                            <Form.Item>
                                <Button
                                    className="btn-submit gx-mt-2"
                                    key="submit"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </Card>
                </Form>
            </div>
        </>
    );
};

export default ExamResult;