import { IExpenseMasterRecord } from "src/pages/ExpenseMaster/ExpenseMaster.model";
import { config } from "src/utils";
import request from "../../lib/axios/request";
import { IApiResponse } from "../../utils/models/common";
import {
  ExpenseMasterRecord,
  IExpenseMaster,
  IExpenseMasterStatus,
} from "./expenseMaster.model";

class ExpenseMasterService {
  ENDPOINT = config.baseApiExpense + "/expense-master";

  public searchExpenseMasterData = async (
    data: {} | URLSearchParams
  ): Promise<IApiResponse<IExpenseMaster[]>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public createExpenseMasterData = async (
    data: IExpenseMasterRecord
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public editExpenseMasterById = async (
    data: IExpenseMasterRecord
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public removeExpenseMasterById = async (id: number) => {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public updateExpenseMasterStatus = async (
    data: IExpenseMasterStatus
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}/status`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };
}

export default new ExpenseMasterService();
