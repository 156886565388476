import { Col, Form, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ContainerHeader from "src/components/ContainerHeader";
import FacultyAvailableSlot from "src/components/FacultyAvailableSlot";
import { useAppSelector } from "src/state/app.hooks";
import { AppDispatch } from "src/state/app.model";
import { searchBranchData } from "src/state/branch/branch.action";
import { branchSelector } from "src/state/branch/branch.reducer";
import { userSelector } from "src/state/users/user.reducer";
import { searchZoneData } from "src/state/zone/zone.action";
import { zoneSelector } from "src/state/zone/zone.reducer";
import { ZoneType } from "src/utils/constants/constant";
const { Option } = Select;

const FacultyAvailable = () => {
  const dispatch = useDispatch<AppDispatch>();
  // state
  const zoneState = useAppSelector(zoneSelector);
  const branchState = useAppSelector(branchSelector);
  const { userData } = useAppSelector(userSelector);

  // local state
  const [masterSelectedZone, setMasterSelectedZone] = useState<number[]>([]);
  const [masterSelectedBranch, setMasterSelectedBranch] = useState<number[]>(
    []
  );

  // local variables
  const [form] = Form.useForm();
  const storageID: string | null = localStorage.getItem("myStorageID");
  const currentUserZone = userData.data.user_roles.find(
    (role) => role.id === Number(storageID)
  )?.zone;

  const zoneData = Form.useWatch("zone", form);

  const branchData =
    branchState.branchesData.data.rows
      .filter((branch) =>
        zoneData ? zoneData == branch.zone?.parent_id[0].id : branch
      )
      .filter((branch) =>
        masterSelectedZone.length > 0
          ? masterSelectedZone.includes(branch.zone?.parent_id[0].id)
          : branch
      )
      .filter((branch) => branch?.status === true) || [];

  useEffect(() => {
    dispatch(
      searchZoneData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        parent_id: currentUserZone?.id,
        type: ZoneType.PUBLIC,
      })
    );
    dispatch(
      searchBranchData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        isZoneOnly: true,
      })
    );
  }, [currentUserZone]);

  return (
    <div className="rnw-main-content">
      <Row align="middle" justify="space-between" gutter={24} className="mb-20">
        <Col xs={24} sm={7}>
          <ContainerHeader
            title={`Faculty Available ${
              currentUserZone?.type !== ZoneType.PRIVATE ? "" : "Of Branch"
            }`}
          />
        </Col>

        {currentUserZone?.type !== ZoneType.PRIVATE && (
          <Col
            xs={24}
            sm={17}
            md={12}
            xl={10}
            className="text-align-right gx-p-0 gx-mt-2 gx-mt-sm-0"
          >
            <div className="gx-d-flex gx-align-items-center gx-justify-content-end">
              {zoneState.zonesData.data.rows.length > 0 && (
                <Col xs={12} sm={12}>
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    allowClear
                    placeholder={"Select Zone"}
                    showSearch
                    style={{ width: "100%", textAlign: "left" }}
                    showArrow
                    mode="multiple"
                    size="large"
                    filterOption={(value, option) =>
                      (option?.children?.toString() || "")
                        .toLowerCase()
                        .includes(value.toLowerCase().trim())
                    }
                    onChange={(value: number[]) => {
                      setMasterSelectedZone(value);
                      setMasterSelectedBranch([]);
                    }}
                  >
                    {zoneState.zonesData.data.rows
                      .filter(
                        (zone) =>
                          zone?.id === currentUserZone?.id ||
                          zone?.parent_id?.id === currentUserZone?.id
                      )
                      .filter((z) => z.status === true)
                      .map((z) => (
                        <Option value={z.id}>{z.code + "-" + z.name}</Option>
                      ))}
                  </Select>
                </Col>
              )}
              {branchData.length > 0 && (
                <Col xs={12} sm={12} className="gx-pr-sm-3">
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    allowClear
                    className="dash_input"
                    style={{
                      width: "100%",
                      textAlign: "left",
                    }}
                    placeholder={"Select Branch"}
                    showSearch
                    showArrow
                    mode="multiple"
                    size="large"
                    onChange={(value: number[]) => {
                      setMasterSelectedBranch(value);
                    }}
                    filterOption={(value, option) =>
                      (option?.children?.toString() || "")
                        .toLowerCase()
                        .includes(value.toLowerCase().trim())
                    }
                    value={masterSelectedBranch}
                  >
                    {branchData
                      .filter((branch) => branch.status === true)
                      .map((branch) => (
                        <Option value={branch.id}>{branch.code}</Option>
                      ))}
                  </Select>
                </Col>
              )}
            </div>
          </Col>
        )}
      </Row>

      <FacultyAvailableSlot
        isHo={currentUserZone?.type !== ZoneType.PRIVATE}
        masterSelectedZone={masterSelectedZone}
        masterSelectedBranch={masterSelectedBranch}
      />
    </div>
  );
};

export default FacultyAvailable;
