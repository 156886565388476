import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICourseStatus } from "../../services/course/course.model";
import coursesService from "../../services/course/course.service";

export const removeCourseById = createAsyncThunk(
  "course/removeCourseById",
  async (id: number) => {
    return coursesService.removeCourseById(id);
  }
);

export const updateCourseStatus = createAsyncThunk(
  "course/updateCourseStatus",
  async (data: ICourseStatus) => {
    return coursesService.updateCourseStatus(data);
  }
);

export const editCourseById = createAsyncThunk(
  "course/editCourseById",
  async (data: any) => {
    return coursesService.editCourseById(data);
  }
);

export const createNewCourse = createAsyncThunk(
  "course/createNewCourse",
  async (data: any) => {
    return coursesService.createNewCourse(data);
  }
);

export const searchCourseData = createAsyncThunk(
  "course/searchCourseData",
  async (data: any) => {
    return coursesService.searchCourseData(data);
  }
);
