
import { useContext } from "react";
import {  Form, Select } from "antd";

import { SidebarPermissionCodeContext } from "src/contexts/sidebarPermissionCodeContext";
import { Common, CommonRemarksLabel } from "src/utils/constants/constant";
import {
  AcademicRemarksLabel,
  AdmissionRemarkLabel,
} from "src/utils/constants/constant";
import { IRemarkLabelProps } from "./RemarkLabel.model";
function RemarksLabels(props:IRemarkLabelProps) {
  const { Option } = Select;
  const { isCode } = useContext(SidebarPermissionCodeContext);
  const isShowAdmissionRemark = isCode.includes(
    Common.Modules.ADMISSION.ADMISSION_REMARKS_LABEL
  );
  const isShowAcademicRemark = isCode.includes(
    Common.Modules.ADMISSION.ADMISSION_ACADEMIC_REMARKS_LABEL
  );

  return (
    <div>
      {" "}
      <>
      <Form.Item name="labels" rules={props.isRulesNotApply?[]:[{ required: true, message: 'Please select a label' }]}>
        <Select getPopupContainer={(trigger) => trigger.parentNode} showSearch>
          {isShowAdmissionRemark &&
            Object.values(AdmissionRemarkLabel).map((label, index) => {
              return (
                <Option key={index} value={label.toLocaleUpperCase()}>
                  {label}
                </Option>
              );
            })}

          {isShowAcademicRemark &&
            Object.values(AcademicRemarksLabel).map((label, index) => {
              return (
                <Option
                  key={
                    isShowAdmissionRemark
                      ? index + Object.values(AdmissionRemarkLabel).length
                      : index
                  }
                  value={label.toLocaleUpperCase()}
                >
                  {label}
                </Option>
              );
            })}

          {(isShowAcademicRemark || isShowAdmissionRemark) &&
            Object.values(CommonRemarksLabel).map((label, index) => {
              return (
                <Option key={index} value={label.toLocaleUpperCase()}>
                  {label}
                </Option>
              );
            })}
        </Select>
        </Form.Item>
      </>
    </div>
  );
}

export default RemarksLabels;
