import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { IBranchInfrastructureState } from "./branchInfrastructure.model";
import {
  createNewBranchInfrastructure,
  editBranchInfrastructureById,
  searchBranchInfrastructureData,
  branchWiseClassSchedule,
  facultyAvailableSlots,
  availablePcsTimeWise,
} from "./branchInfrastructure.action";

export const initialState: IBranchInfrastructureState = {
  branchInfrastructuresData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      hourlyLabDetails : []
    },
  },
  createBranchInfrastructures: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  editById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  branchWiseClassSchedule: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  facultyAvailableSlots: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  availablePcsTimeWise: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      data: [],
      totalAvailablePcs: 0,
      placeAvailabilityCount : {}
    },
  },
};

export const branchInfrastructureSlice = createSlice({
  name: "branch-infrastructure",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.editById.message = "";
      state.branchInfrastructuresData.message = "";
      state.createBranchInfrastructures.message = "";
    },
    availablePcsTimeWiseClearState : (state) => {
      state.availablePcsTimeWise.data = initialState.availablePcsTimeWise.data;
      state.availablePcsTimeWise.message = "";
      state.availablePcsTimeWise.loading = false;
      state.availablePcsTimeWise.hasErrors = false;
    }
  },
  extraReducers: {
    //get branch infrastructure
    [searchBranchInfrastructureData.pending.type]: (state) => {
      state.branchInfrastructuresData.loading = true;
    },
    [searchBranchInfrastructureData.fulfilled.type]: (state, action) => {
      state.branchInfrastructuresData.data = action.payload.data;
      state.branchInfrastructuresData.message = action.payload.message;
      state.branchInfrastructuresData.loading = false;
      state.branchInfrastructuresData.hasErrors = false;
    },
    [searchBranchInfrastructureData.rejected.type]: (state, action) => {
      state.branchInfrastructuresData.loading = false;
      state.branchInfrastructuresData.hasErrors = true;
      state.branchInfrastructuresData.message = action.error.message;
    },

    //create branch infrastructure
    [createNewBranchInfrastructure.pending.type]: (state) => {
      state.createBranchInfrastructures.loading = true;
    },
    [createNewBranchInfrastructure.fulfilled.type]: (state, action) => {
      state.createBranchInfrastructures.message = action.payload.message;
      state.createBranchInfrastructures.hasErrors = action.payload.error;
      state.createBranchInfrastructures.loading = false;
      state.createBranchInfrastructures.hasErrors = false;
    },
    [createNewBranchInfrastructure.rejected.type]: (state, action) => {
      state.createBranchInfrastructures.loading = false;
      state.createBranchInfrastructures.hasErrors = true;
      state.createBranchInfrastructures.message = action.error.message;
    },

    //edit branch infrastructure
    [editBranchInfrastructureById.pending.type]: (state) => {
      state.editById.loading = true;
    },
    [editBranchInfrastructureById.fulfilled.type]: (state, action) => {
      state.editById.message = action.payload.message;
      state.editById.loading = false;
      state.editById.hasErrors = false;
    },
    [editBranchInfrastructureById.rejected.type]: (state, action) => {
      state.editById.loading = false;
      state.editById.hasErrors = true;
      state.editById.message = action.error.message;
    },

    //branch wise class schedule
    [branchWiseClassSchedule.pending.type]: (state) => {
      state.branchWiseClassSchedule.loading = true;
    },
    [branchWiseClassSchedule.fulfilled.type]: (state, action) => {
      state.branchWiseClassSchedule.data = action.payload.data;
      state.branchWiseClassSchedule.message = action.payload.message;
      state.branchWiseClassSchedule.loading = false;
      state.branchWiseClassSchedule.hasErrors = false;
    },
    [branchWiseClassSchedule.rejected.type]: (state, action) => {
      state.branchWiseClassSchedule.loading = false;
      state.branchWiseClassSchedule.hasErrors = true;
      state.branchWiseClassSchedule.message = action.error.message;
    },

    //faculty available slot
    [facultyAvailableSlots.pending.type]: (state) => {
      state.facultyAvailableSlots.loading = true;
    },
    [facultyAvailableSlots.fulfilled.type]: (state, action) => {
      state.facultyAvailableSlots.data = action.payload.data;
      state.facultyAvailableSlots.message = action.payload.message;
      state.facultyAvailableSlots.loading = false;
      state.facultyAvailableSlots.hasErrors = false;
    },
    [facultyAvailableSlots.rejected.type]: (state, action) => {
      state.facultyAvailableSlots.loading = false;
      state.facultyAvailableSlots.hasErrors = true;
      state.facultyAvailableSlots.message = action.error.message;
    },

    //available pcs time wise
    [availablePcsTimeWise.pending.type]: (state) => {
      state.availablePcsTimeWise.loading = true;
    },
    [availablePcsTimeWise.fulfilled.type]: (state, action) => {
      state.availablePcsTimeWise.data = action.payload.data;
      state.availablePcsTimeWise.message = action.payload.message;
      state.availablePcsTimeWise.loading = false;
      state.availablePcsTimeWise.hasErrors = false;
    },
    [availablePcsTimeWise.rejected.type]: (state, action) => {
      state.availablePcsTimeWise.loading = false;
      state.availablePcsTimeWise.hasErrors = true;
      state.availablePcsTimeWise.message = action.error.message;
    },
  },
});

// A selector
export const branchInfrastructureSelector = (state: RootState) =>
  state.branchInfrastructure;

export const { clearRemoveMessage, availablePcsTimeWiseClearState } = branchInfrastructureSlice.actions;

// The reducer
export default branchInfrastructureSlice.reducer;
