import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { ISourceState } from "./source.model";
import { searchSourceData } from "./source.action";

export const initialState: ISourceState = {
  sourceData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      count:0,
      },
  },
};

export const sourceSlice = createSlice({
  name: "source",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.sourceData.message = "";
    },
  },
  extraReducers: {
    [searchSourceData.pending.type]: (state) => {
      state.sourceData.loading = true;
    },
    [searchSourceData.fulfilled.type]: (state, action) => {
      state.sourceData.data = action.payload.data;
      state.sourceData.message = action.payload.message;
      state.sourceData.loading = false;
      state.sourceData.hasErrors = false;
    },
    [searchSourceData.rejected.type]: (state, action) => {
      state.sourceData.loading = false;
      state.sourceData.hasErrors = true;
      state.sourceData.message = action.error.message;
    },
  },
});

// A selector
export const sourceSelector = (state: RootState) => state.source;

export const { clearRemoveMessage } = sourceSlice.actions;

// The reducer
export default sourceSlice.reducer;
