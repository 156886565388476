import { DeleteOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { IDeleteButtonProps } from "./DeleteButtonWithConfirm.model";

const DeleteButtonWithConfirmComponent = (props: IDeleteButtonProps) => {
  const { title, okText, cancelText, onDelete, disabled, children, className } =
    props;

  const confirm = () =>
    Modal.confirm({
      title: `${title}`,

      okText: `${okText}`,
      cancelText: `${cancelText}`,
      icon: <DeleteOutlined />,
      onOk() {
        onDelete();
      },
    });

  return (
    <Button
      className={className}
      danger
      type="ghost"
      disabled={disabled}
      icon={<DeleteOutlined />}
      onClick={confirm}
      children={children}
    ></Button>
  );
};

export default DeleteButtonWithConfirmComponent;
