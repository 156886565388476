import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ICreateTempPayload,
  IEditTempPayload,
  ITempPayload,
} from "src/pages/SubCourseTemplate/SubCourseTemplate.model";
import {
  IUpdateTemplateStatus,
  IUpdateTemplateTopicsAndSubTopicsStatus,
} from "src/services/templateSigningSheet/templateSigningSheet.model";
import { TemplateShiningSheetTopic } from "src/pages/UserWiseBatch/UserWiseBatch.model";
import templateSigningSheetService from "src/services/templateSigningSheet/templateSigningSheet.service";

export const searchTemplateSigningSheetDataByScID = createAsyncThunk(
  "template/searchTemplateSigningSheetData",
  async (data: { [key: string]: string | URLSearchParams }) => {
    return templateSigningSheetService.searchTemplateSigningSheetDataByScID(
      data
    );
  }
);

export const createNewTemplateSigningSheet = createAsyncThunk(
  "template/createNewTemplateSigningSheet",
  async (data: ICreateTempPayload) => {
    return templateSigningSheetService.createNewTemplateSigningSheet(data);
  }
);

export const getTemplateShiningSheetBySubCourseIdAndId = createAsyncThunk(
  "template/getTemplateShiningSheetBySubCourseIdAndId",
  async (data: { [key: string]: string | URLSearchParams }) => {
    return templateSigningSheetService.getTemplateShiningSheetBySubCourseIdAndId(
      data
    );
  }
);

export const editTemplateShiningSheetBySubCourseIdAndId = createAsyncThunk(
  "template/editTemplateShiningSheetBySubCourseIdAndId",
  async (data: IEditTempPayload) => {
    return templateSigningSheetService.editTemplateShiningSheetBySubCourseIdAndId(
      data
    );
  }
);

export const updateTemplateShiningSheetStatus = createAsyncThunk(
  "template/updateTemplateShiningSheetStatus",
  async (data: IUpdateTemplateStatus) => {
    return templateSigningSheetService.updateTemplateShiningSheetStatus(data);
  }
);

export const updateTemplateShiningSheetTopicStatus = createAsyncThunk(
  "template/updateTemplateShiningSheetTopicStatus",
  async (data: IUpdateTemplateTopicsAndSubTopicsStatus) => {
    return templateSigningSheetService.updateTemplateShiningSheetTopicStatus(
      data
    );
  }
);
export const deleteTemplateShiningSheetId = createAsyncThunk(
  "template/deleteTemplateShiningSheetId",
  async (id: number) => {
    return templateSigningSheetService.deleteTemplateShiningSheetId(id);
  }
);

export const sendTemplateInMail = createAsyncThunk(
  "template/sendTemplateInMail",
  async (data: ITempPayload) => {
    return templateSigningSheetService.sendTemplateInMail(data);
  }
);