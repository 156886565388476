import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import {
  createNewCourse,
  editCourseById,
  removeCourseById,
  searchCourseData,
  updateCourseStatus,
} from "./course.action";
import { ICourseState } from "./course.model";

export const initialState: ICourseState = {
  removeById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  updateById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  editById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  createCourses: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  coursesData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false
      },
      },
    },
  }


export const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.removeById.message = "";
      state.updateById.message = "";
      state.editById.message = "";
      state.createCourses.message = "";
      state.coursesData.message = "";
    },
  },
  extraReducers: {

    [removeCourseById.pending.type]: (state) => {
      state.removeById.loading = true;
    },
    [removeCourseById.fulfilled.type]: (state, action) => {
      state.removeById.message = action.payload.message;
      state.removeById.loading = false;
      state.removeById.hasErrors = false;
    },
    [removeCourseById.rejected.type]: (state,action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = true;
      state.removeById.message = action.error.message;
    },

    [updateCourseStatus.pending.type]: (state) => {
      state.updateById.loading = true;
    },
    [updateCourseStatus.fulfilled.type]: (state, action) => {
      state.updateById.message = action.payload.message;
      state.updateById.loading = false;
      state.updateById.hasErrors = false;
    },
    [updateCourseStatus.rejected.type]: (state,action) => {
      state.updateById.loading = false;
      state.updateById.hasErrors = true;
      state.updateById.message = action.error.message;
    },

    [editCourseById.pending.type]: (state) => {
      state.editById.loading = true;
    },
    [editCourseById.fulfilled.type]: (state, action) => {
      state.editById.message = action.payload.message;
      state.editById.loading = false;
      state.editById.hasErrors = false;
    },
    [editCourseById.rejected.type]: (state,action) => {
      state.editById.loading = false;
      state.editById.hasErrors = true;
      state.editById.message = action.error.message;
    },

    [createNewCourse.pending.type]: (state) => {
      state.createCourses.loading = true;
    },
    [createNewCourse.fulfilled.type]: (state, action) => {
      state.createCourses.message = action.payload.message;
      state.createCourses.loading = false;
      state.createCourses.hasErrors = false;
    },
    [createNewCourse.rejected.type]: (state,action) => {
      state.createCourses.loading = false;
      state.createCourses.hasErrors = true;
      state.createCourses.message= action.error.message;
    },

    [searchCourseData.pending.type]: (state) => {
      state.coursesData.loading = true;
    },
    [searchCourseData.fulfilled.type]: (state, action) => {
      state.coursesData.data = action.payload.data;
      state.coursesData.message = action.payload.message;
      state.coursesData.loading = false;
      state.coursesData.hasErrors = false;
    },
    [searchCourseData.rejected.type]: (state,action) => {
      state.coursesData.loading = false;
      state.coursesData.hasErrors = true;
      state.coursesData.message =action.error.message;
    },
  },
});

// A selector
export const courseSelector = (state: RootState) => state.course;

export const { clearRemoveMessage } = courseSlice.actions;

// The reducer
export default courseSlice.reducer;
