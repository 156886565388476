import { config } from "src/utils";
import request from "../../lib/axios/request";
import { ISubcourseRecord } from "../../pages/SubCourse/Subcourse.model";
import { IApiResponse } from "../../utils/models/common";
import { ISubCourseDetails, ISubCourseStatus } from "./subCourse.model";

class SubCourseService {
  ENDPOINT = config.baseApiMasters + "/sub-courses";

  public removeSubCourseById = async (id: number) => {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public updateSubCourseStatus = async (
    data: ISubCourseStatus
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}/status`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

  public editSubCourseById = async (
    data: ISubcourseRecord
  ): Promise<IApiResponse<any>> => {
    const url = `${this.ENDPOINT}/${data.id}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public createNewSubCourse = async (
    data: ISubcourseRecord
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public searchSubCourseData = async (
    data: ISubCourseDetails
  ): Promise<IApiResponse<ISubCourseDetails[]>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public searchSubCourseDataById = async (
    data: ISubCourseDetails
  ): Promise<IApiResponse<ISubCourseDetails[]>> => {
    const url = `${this.ENDPOINT}/${data.id}`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
}

export default new SubCourseService();
