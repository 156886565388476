import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import {
  createNewSubCourseTopic,
  editSubCourseTopicById,
  removeSubCourseTopicById,
  searchSubCourseTopicData,
  updateSubCourseTopicStatus,
  updateSubCourseSubTopicStatus,
  uploadSubCourseTopicCsvFile,
  removeMultipleTopic,
} from "./subCourseTopic.action";
import { ISubCourseTopicState } from "./subCourseTopic.model";

export const initialState: ISubCourseTopicState = {
  subCourseTopicData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  createSubCourseTopic: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  SubCourseScvFile: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  editById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  removeById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  removeMultiple: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  updateById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
};

export const subCourseTopicSlice = createSlice({
  name: "subCourseTopic",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.removeById.message = "";
      state.updateById.message = "";
      state.editById.message = "";
      state.createSubCourseTopic.message = "";
      state.removeMultiple.message = "";
      state.SubCourseScvFile.message = "";
    },
  },
  extraReducers: {
    //get SubCourse Topic
    [searchSubCourseTopicData.pending.type]: (state) => {
      state.subCourseTopicData.loading = true;
    },
    [searchSubCourseTopicData.fulfilled.type]: (state, action) => {
      state.subCourseTopicData.data = action.payload.data;
      state.subCourseTopicData.message = action.payload.message;
      state.subCourseTopicData.loading = false;
      state.subCourseTopicData.hasErrors = false;
    },
    [searchSubCourseTopicData.rejected.type]: (state, action) => {
      state.subCourseTopicData.loading = false;
      state.subCourseTopicData.hasErrors = true;
      state.subCourseTopicData.message = action.error.message;
    },

    //create SubCourseTopic
    [createNewSubCourseTopic.pending.type]: (state) => {
      state.createSubCourseTopic.loading = true;
    },
    [createNewSubCourseTopic.fulfilled.type]: (state, action) => {
      state.createSubCourseTopic.message = action.payload.message;
      state.createSubCourseTopic.hasErrors = action.payload.error;
      state.createSubCourseTopic.loading = false;
      state.createSubCourseTopic.hasErrors = false;
    },
    [createNewSubCourseTopic.rejected.type]: (state, action) => {
      state.createSubCourseTopic.loading = false;
      state.createSubCourseTopic.hasErrors = true;
      state.createSubCourseTopic.message = action.error.message;
    },

    //createscvfileSubCourseTopic
    [uploadSubCourseTopicCsvFile.pending.type]: (state) => {
      state.SubCourseScvFile.loading = true;
    },
    [uploadSubCourseTopicCsvFile.fulfilled.type]: (state, action) => {
      state.SubCourseScvFile.message = action.payload.message;
      state.SubCourseScvFile.hasErrors = action.payload.error;
      state.SubCourseScvFile.loading = false;
      state.SubCourseScvFile.hasErrors = false;
    },
    [uploadSubCourseTopicCsvFile.rejected.type]: (state, action) => {
      state.SubCourseScvFile.loading = false;
      state.SubCourseScvFile.hasErrors = true;
      state.SubCourseScvFile.message = action.error.message;
    },

    //edit SubCourseTopic
    [editSubCourseTopicById.pending.type]: (state) => {
      state.editById.loading = true;
    },
    [editSubCourseTopicById.fulfilled.type]: (state, action) => {
      state.editById.message = action.payload.message;
      state.editById.loading = false;
      state.editById.hasErrors = false;
    },
    [editSubCourseTopicById.rejected.type]: (state, action) => {
      state.editById.loading = false;
      state.editById.hasErrors = true;
      state.editById.message = action.error.message;
    },

    //update SubCourseTopic
    [updateSubCourseTopicStatus.pending.type]: (state) => {
      state.updateById.loading = true;
    },
    [updateSubCourseTopicStatus.fulfilled.type]: (state, action) => {
      state.updateById.message = action.payload.message;
      state.updateById.loading = false;
      state.updateById.hasErrors = false;
    },
    [updateSubCourseTopicStatus.rejected.type]: (state, action) => {
      state.updateById.loading = false;
      state.updateById.hasErrors = true;
      state.updateById.message = action.error.message;
    },

    //update SubCourseSubTopic
    [updateSubCourseSubTopicStatus.pending.type]: (state) => {
      state.updateById.loading = true;
    },
    [updateSubCourseSubTopicStatus.fulfilled.type]: (state, action) => {
      state.updateById.message = action.payload.message;
      state.updateById.loading = false;
      state.updateById.hasErrors = false;
    },
    [updateSubCourseSubTopicStatus.rejected.type]: (state, action) => {
      state.updateById.loading = false;
      state.updateById.hasErrors = true;
      state.updateById.message = action.error.message;
    },

    //Delete SubCourseTopic
    [removeSubCourseTopicById.pending.type]: (state) => {
      state.removeById.loading = true;
    },
    [removeSubCourseTopicById.fulfilled.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = false;
      state.removeById.message = action.payload.message;
    },
    [removeSubCourseTopicById.rejected.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = true;
      state.removeById.message = action.error.message;
    },

    //Delete Multiple SubCourseTopic
    [removeMultipleTopic.pending.type]: (state) => {
      state.removeMultiple.loading = true;
    },
    [removeMultipleTopic.fulfilled.type]: (state, action) => {
      state.removeMultiple.loading = false;
      state.removeMultiple.hasErrors = false;
      state.removeMultiple.message = action.payload.message;
    },
    [removeMultipleTopic.rejected.type]: (state, action) => {
      state.removeMultiple.loading = false;
      state.removeMultiple.hasErrors = true;
      state.removeMultiple.message = action.error.message;
    },

    //CSV file upload in SubCourseTopic
    [uploadSubCourseTopicCsvFile.pending.type]: (state) => {
      state.SubCourseScvFile.loading = true;
    },
    [uploadSubCourseTopicCsvFile.fulfilled.type]: (state, action) => {
      state.SubCourseScvFile.loading = false;
      state.SubCourseScvFile.hasErrors = false;
      state.SubCourseScvFile.message = action.payload.message;
    },
    [uploadSubCourseTopicCsvFile.rejected.type]: (state, action) => {
      state.SubCourseScvFile.loading = false;
      state.SubCourseScvFile.hasErrors = true;
      state.SubCourseScvFile.message = action.error.message;
    },
  },
});

// A selector
export const subCourseTopicSelector = (state: RootState) =>
  state.subcoursetopic;

export const { clearRemoveMessage } = subCourseTopicSlice.actions;

// The reducer
export default subCourseTopicSlice.reducer;
