import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { IApiResponse } from "../../utils/models/common";
import { ISubDepartmentState } from "./subDepartment.model";
import {
  createNewSubDepartment,
  editSubDepartmentById,
  removeSubDepartmentById,
  searchSubDepartmentData,
  updateSubDepartmentStatus,
} from "./subDepartment.action";

export const initialState: ISubDepartmentState = {

  removeById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  updateById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  editById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  createSubDepartment: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  searchData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
};

export const subDepartmentSlice = createSlice({
  name: "subdepartment",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.removeById.message = "";
      state.editById.message = "";
      state.createSubDepartment.message = "";
      state.updateById.message = "";
    },
  },
  extraReducers: {

    [removeSubDepartmentById.pending.type]: (state) => {
      state.removeById.loading = true;
    },
    [removeSubDepartmentById.fulfilled.type]: (
      state,
      action: PayloadAction<IApiResponse<ISubDepartmentState>>
    ) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = false;
      state.removeById.message = action.payload.message;
    },
    [removeSubDepartmentById.rejected.type]: (state,action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = true;
      state.removeById.message =action.error.message;
    },

    [updateSubDepartmentStatus.pending.type]: (state) => {
      state.updateById.loading = true;
    },
    [updateSubDepartmentStatus.fulfilled.type]: (state, action) => {
      state.updateById.message = action.payload.message;
      state.updateById.loading = false;
      state.updateById.hasErrors = false;
    },
    [updateSubDepartmentStatus.rejected.type]: (state,action) => {
      state.updateById.loading = false;
      state.updateById.hasErrors = true;
      state.updateById.message=action.error.message;
    },

    [editSubDepartmentById.pending.type]: (state) => {
      state.editById.loading = true;
    },
    [editSubDepartmentById.fulfilled.type]: (state, action) => {
      state.editById.message = action.payload.message;
      state.editById.loading = false;
      state.editById.hasErrors = false;
    },
    [editSubDepartmentById.rejected.type]: (state,action) => {
      state.editById.loading = false;
      state.editById.hasErrors = true;
      state.editById.message =action.error.message;
    },

    [createNewSubDepartment.pending.type]: (state) => {
      state.createSubDepartment.loading = true;
    },
    [createNewSubDepartment.fulfilled.type]: (state, action) => {
      state.createSubDepartment.message = action.payload.message;
      state.createSubDepartment.loading = false;
      state.createSubDepartment.hasErrors = false;
    },
    [createNewSubDepartment.rejected.type]: (state,action) => {
      state.createSubDepartment.loading = false;
      state.createSubDepartment.hasErrors = true;
      state.createSubDepartment.message=action.error.message;
    },

    [searchSubDepartmentData.pending.type]: (state) => {
      state.searchData.loading = true;
    },
    [searchSubDepartmentData.fulfilled.type]: (state, action) => {
      state.searchData.data = action.payload.data;
      state.searchData.message = action.payload.message;
      state.searchData.loading = false;
      state.searchData.hasErrors = false;
    },
    [searchSubDepartmentData.rejected.type]: (state,action) => {
      state.searchData.loading = false;
      state.searchData.hasErrors = true;
      state.searchData.message = action.error.message;
    },
  },
});

// A selector
export const subdepartmentSelector = (state: RootState) => state.subdepartment;

export const { clearRemoveMessage } = subDepartmentSlice.actions;

// The reducer
export default subDepartmentSlice.reducer;
