import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import {
  searchTransferAdmissionData,
  TransferAdmissionStatus
} from "./transferadmission.action";
import { ITransferAdmissionState } from "./transferadmission.model";

export const initialState: ITransferAdmissionState = {
  transferAdmissionData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  transferByStatus: {
    loading: false,
    hasErrors: false,
    message: "",
  },
};

export const transferAdmissionSlice = createSlice({
  name: "transferadmission",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.transferByStatus.message = "";
    },
  },
  extraReducers: {
    [searchTransferAdmissionData.pending.type]: (state) => {
      state.transferAdmissionData.loading = true;
    },
    [searchTransferAdmissionData.fulfilled.type]: (state, action) => {
      state.transferAdmissionData.data = action.payload.data;
      state.transferAdmissionData.message = action.payload.message;
      state.transferAdmissionData.loading = false;
      state.transferAdmissionData.hasErrors = false;
    },
    [searchTransferAdmissionData.rejected.type]: (state, action) => {
      state.transferAdmissionData.loading = false;
      state.transferAdmissionData.hasErrors = true;
      state.transferAdmissionData.message = action.error.message;
    },

    //transfer branch
    [TransferAdmissionStatus.pending.type]: (state) => {
      state.transferByStatus.loading = true;
    },
    [TransferAdmissionStatus.fulfilled.type]: (state, action) => {
      state.transferByStatus.message = action.payload.message;
      state.transferByStatus.loading = false;
      state.transferByStatus.hasErrors = false;
    },
    [TransferAdmissionStatus.rejected.type]: (state, action) => {
      state.transferByStatus.loading = false;
      state.transferByStatus.hasErrors = true;
      state.transferByStatus.message = action.error.message;
    },
  },
});

// A selector
export const transferadmissionSelector = (state: RootState) => state.transferadmission;

export const { clearRemoveMessage } = transferAdmissionSlice.actions;

// The reducer
export default transferAdmissionSlice.reducer;
