import request from "src/lib/axios/request";
import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import { IStudentData } from "./admissionMicroService.model";

class AdmissionMicroService {
  ENDPOINT = config.baseApiAdmission + "/admission-micro-service";

  public getStudentData = async (
    data: string
  ): Promise<IApiResponse<IStudentData>> => {
    const url = `${this.ENDPOINT}/${encodeURIComponent(data)}`;
    return request({ url, method: "GET" }).then((res) => {
      return res.data;
    });
  };
}

export default new AdmissionMicroService();
