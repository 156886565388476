import { IPaginationProps } from "./Pagination.model";
import { Pagination, PaginationProps } from "antd";
import { useEffect, useState } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

const PaginationComponent = (props: IPaginationProps) => {
  const { meta } = props;
  let [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(
    searchParams.get("skip") && searchParams.get("take")
      ? Number(searchParams.get("skip")) / Number(searchParams.get("take")) + 1
      : 1
  );
  const [pageSize, setPagesize] = useState<Number>(10);
  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    setCurrentPage(
      searchParams.get("skip") && searchParams.get("take")
        ? Number(searchParams.get("skip")) / Number(searchParams.get("take")) +
            1
        : 1
    );
  }, [searchParams]);

  const goToPageRecord = (pageSize?: number, skip?: number) =>
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams([
        ["take", `${pageSize}`],
        ["skip", `${skip ? skip : 0}`],
      ])}`,
    });

  const searchWithPageRecord = (pageSize?: number, skip?: number) => {
    const sp = new URLSearchParams(searchParams.toString());
    sp.set("take", `${pageSize}`);
    sp.set("skip", `${skip ? skip : 0}`);
    setSearchParams(sp.toString());
  };

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    const table = document.querySelector(".table-bg");
    if (table) {
      table.scrollTo(0, 0); // Scroll to top of the table
    }
    setCurrentPage(((page - 1) * pageSize) / pageSize + 1);
    if (location.search) {
      searchWithPageRecord(pageSize, (page - 1) * pageSize);
    } else {
      goToPageRecord(pageSize, (page - 1) * pageSize);
    }
  };

  function onShowSizeChange(pageSize: number) {
    setPagesize(pageSize);
    if (location.search) {
      searchWithPageRecord(pageSize);
    } else {
      goToPageRecord(pageSize);
    }
  }

  return (
    <Pagination
      current={currentPage}
      onChange={onChange}
      total={meta.itemCount}
      showSizeChanger
      onShowSizeChange={onShowSizeChange}
      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
      defaultPageSize={meta.take}
      responsive={true}
    />
  );
};

export default PaginationComponent;
