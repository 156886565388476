import { config } from "src/utils";
import request from "../../lib/axios/request";
import { IApiResponse } from "../../utils/models/common";

class RolePermissionsService {
  ENDPOINT = config.baseApiAuth + "/role-permissions";

  public saveRolePermissions = async (
    data: any
  ): Promise<IApiResponse<number>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "POST", data: data }).then((res) => {
      return res.data;
    });
  };
}

export default new RolePermissionsService();
