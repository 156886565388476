import { createAsyncThunk } from "@reduxjs/toolkit";
import { ITransferAdmissionRecord } from "src/pages/TransferAdmission/TransferAdmission.model";
import {
  ITransferAdmission,
} from "src/services/transferadmission/transferadmission.model";
import transferadmissionService from "src/services/transferadmission/transferadmission.service";

export const searchTransferAdmissionData = createAsyncThunk(
  "admission/searchTransferAdmissionData",
  async (data: ITransferAdmission | URLSearchParams | {}) => {
    return transferadmissionService.searchTransferAdmissionData(data);
  }
);

export const TransferAdmissionStatus = createAsyncThunk(
  "admission/transferAdmissionData",
  async (data: ITransferAdmissionRecord) => {
    return transferadmissionService.TransferAdmissionStatus(data);
  }
);

