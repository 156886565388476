import { createAsyncThunk } from "@reduxjs/toolkit";
import { IOverdueIncomeDetailsRecord, ISchoolWiseStudentCount } from "src/services/overdueIncome/overdueIncome.model";
import overdueIncomeService from "src/services/overdueIncome/overdueIncome.service";

export const overdueIncomeData = createAsyncThunk(
  "reports/overdue-income",
  async (data: IOverdueIncomeDetailsRecord | URLSearchParams) => {
    return overdueIncomeService.searchReportData(data);
  }
);

export const searchOverdueIncomeCsvEmailSendData = createAsyncThunk(
  "reports/searchOverdueIncomeCsvEmailSendData",
  async (data: IOverdueIncomeDetailsRecord | URLSearchParams | {}) => {
    return overdueIncomeService.searchOverdueIncomeCsvEmailSendData(data);
  }
);

export const branchWiseOverdueReport = createAsyncThunk(
  "reports/branchWiseOverdueReport",
  async (data: URLSearchParams | {}) => {
    return overdueIncomeService.branchWiseOverdueReport(data);
  }
);

export const schoolWiseStudentCount = createAsyncThunk(
  "reports/schoolWiseStudentCount",
  async (data: ISchoolWiseStudentCount | URLSearchParams | {}) => {
    return overdueIncomeService.schoolWiseStudentCount(data);
  }
);

export const schoolWiseStudentCountCsv = createAsyncThunk(
  "reports/schoolWiseStudentCountCsv",
  async (data: ISchoolWiseStudentCount | URLSearchParams | {}) => {
    return overdueIncomeService.schoolWiseStudentCountCsv(data);
  }
);
