import { Table, TableProps, Card } from "antd";
import { useSearchParams } from "react-router-dom";
import PaginationComponent from "../Pagination";
import { IDataTableExpandableProps } from "./DatatableExpandable.model";

const TableExpandableComponent = (props: IDataTableExpandableProps) => {
  const {
    columns,
    dataSource,
    loading,
    meta,
    expanrowData,
    rowSelection,
    isexpandable,
    pagination,
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const onChange: TableProps<any>["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    let data = {
      order:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === "descend"
          ? "DESC"
          : "",
      orderBy: sorter.field,
    };
    const queryString = Object.entries(data)
      .filter(([key, data]) => data !== undefined && data !== "")
      .map(([key, data]) => key + "=" + data)
      .join("&");
    if (searchParams.toString()) {
      const newUrl = new URLSearchParams(searchParams.toString());
      newUrl.set("skip", `${0}`);
      newUrl.set("orderBy", `${data.orderBy}`);
      data.order && newUrl.set("order", `${data.order}`);
      setSearchParams(newUrl);
    } else {
      setSearchParams(queryString);
    }
  };

  return (
    <>
      <div className="table-bg">
        <Table
          className="gx-table-responsive table-striped"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          onChange={pagination ? undefined : onChange}
          sortDirections={["ascend", "descend"]}
          pagination={pagination ? pagination : false}
          expandedRowRender={expanrowData}
          rowKey={(record) => (record?.key || record.id)}
          expandable={isexpandable}
        />
      </div>
      <div className="pagination">
        {meta && meta.itemCount > 0 && (
          <PaginationComponent
            meta={
              meta
                ? meta
                : {
                    take: 0,
                    itemCount: 0,
                    pageCount: 0,
                    hasPreviousPage: false,
                    hasNextPage: false,
                  }
            }
          />
        )}
      </div>
    </>
  );
};

export default TableExpandableComponent;
