import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { addMarkById, examBatch, examPaperList, examPaperQuestion } from "./exambatch.action";
import { IExamBatchState } from "./exambatch.model";

export const initialState: IExamBatchState = {
  examBatchData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  examPaperList: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  examPaperQuestion: {
    loading: false,
    hasErrors: false,
    message: "",
    data: []
  },
  addMarkById: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [{ id: 0, marks: 0 }],
  },
};

export const examBatchSlice = createSlice({
  name: "batch-exam",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.examBatchData.message = "";
      state.addMarkById.message = "";
    },
  },
  extraReducers: {
    [examBatch.pending.type]: (state) => {
      state.examBatchData.loading = true;
    },
    [examBatch.fulfilled.type]: (state, action) => {
      state.examBatchData.data = action.payload.data;
      state.examBatchData.message = action.payload.message;
      state.examBatchData.loading = false;
      state.examBatchData.hasErrors = false;
    },
    [examBatch.rejected.type]: (state, action) => {
      state.examBatchData.loading = false;
      state.examBatchData.hasErrors = true;
      state.examBatchData.message = action.error.message;
    },

    [examPaperList.pending.type]: (state) => {
      state.examPaperList.loading = true;
    },
    [examPaperList.fulfilled.type]: (state, action) => {
      state.examPaperList.data = action.payload.data;
      state.examPaperList.message = action.payload.message;
      state.examPaperList.loading = false;
      state.examPaperList.hasErrors = false;
    },
    [examPaperList.rejected.type]: (state, action) => {
      state.examPaperList.loading = false;
      state.examPaperList.hasErrors = true;
      state.examPaperList.message = action.error.message;
    },

    [examPaperQuestion.pending.type]: (state) => {
      state.examPaperQuestion.loading = true;
    },
    [examPaperQuestion.fulfilled.type]: (state, action) => {
      state.examPaperQuestion.data = action.payload.data;
      state.examPaperQuestion.message = action.payload.message;
      state.examPaperQuestion.loading = false;
      state.examPaperQuestion.hasErrors = false;
    },
    [examPaperQuestion.rejected.type]: (state, action) => {
      state.examPaperQuestion.loading = false;
      state.examPaperQuestion.hasErrors = true;
      state.examPaperQuestion.message = action.error.message;
    },

    [addMarkById.pending.type]: (state) => {
      state.addMarkById.loading = true;
    },
    [addMarkById.fulfilled.type]: (state, action) => {
      state.addMarkById.message = action.payload.message;
      state.addMarkById.loading = false;
      state.addMarkById.hasErrors = false;
    },
    [addMarkById.rejected.type]: (state, action) => {
      state.addMarkById.loading = false;
      state.addMarkById.hasErrors = true;
      state.addMarkById.message = action.error.message;
    },
  },
});

// A selector
export const examBatchSelector = (state: RootState) => state.examBatch;

export const { clearRemoveMessage } = examBatchSlice.actions;

// The reducer
export default examBatchSlice.reducer;
