import { createSlice } from "@reduxjs/toolkit";
import { IHolidayState } from "./holiday.model";
import {
  createNewHoliday,
  editHolidayById,
  removeHolidayById,
  searchHolidayData,
  searchAllHolidayData,
} from "./holiday.action";
import { RootState } from "../app.model";

export const initialState: IHolidayState = {
  removeById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  editById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  createHoliday: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  holidaysData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  allHolidayData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
};

export const holidaySlice = createSlice({
  name: "holiday",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.removeById.message = "";
      state.editById.message = "";
      state.createHoliday.message = "";
    },
  },
  extraReducers: {
    // remove a new holiday
    [removeHolidayById.pending.type]: (state) => {
      state.removeById.loading = true;
    },
    [removeHolidayById.fulfilled.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = false;
      state.removeById.message = action.payload.message;
    },
    [removeHolidayById.rejected.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = true;
      state.removeById.message = action.error.message;
    },

    // edit a new holiday
    [editHolidayById.pending.type]: (state) => {
      state.editById.loading = true;
    },
    [editHolidayById.fulfilled.type]: (state, action) => {
      state.editById.message = action.payload.message;
      state.editById.loading = false;
      state.editById.hasErrors = false;
    },
    [editHolidayById.rejected.type]: (state, action) => {
      state.editById.loading = false;
      state.editById.hasErrors = true;
      state.editById.message = action.error.message;
    },

    // create a new holiday
    [createNewHoliday.pending.type]: (state) => {
      state.createHoliday.loading = true;
    },
    [createNewHoliday.fulfilled.type]: (state, action) => {
      state.createHoliday.message = action.payload.message;
      state.createHoliday.hasErrors = action.payload.error;
      state.createHoliday.loading = false;
      state.createHoliday.hasErrors = false;
    },
    [createNewHoliday.rejected.type]: (state, action) => {
      state.createHoliday.loading = false;
      state.createHoliday.hasErrors = true;
      state.createHoliday.message = action.error.message;
    },

    //  get holiday
    [searchHolidayData.pending.type]: (state) => {
      state.holidaysData.loading = true;
    },
    [searchHolidayData.fulfilled.type]: (state, action) => {
      state.holidaysData.data = action.payload.data;
      state.holidaysData.message = action.payload.message;
      state.holidaysData.loading = false;
      state.holidaysData.hasErrors = false;
    },
    [searchHolidayData.rejected.type]: (state, action) => {
      state.holidaysData.loading = false;
      state.holidaysData.hasErrors = true;
      state.holidaysData.message = action.error.message;
    },

    //  get all holiday
    [searchAllHolidayData.pending.type]: (state) => {
      state.allHolidayData.loading = true;
    },
    [searchAllHolidayData.fulfilled.type]: (state, action) => {
      state.allHolidayData.data = action.payload.data;
      state.allHolidayData.message = action.payload.message;
      state.allHolidayData.loading = false;
      state.allHolidayData.hasErrors = false;
    },
    [searchAllHolidayData.rejected.type]: (state, action) => {
      state.allHolidayData.loading = false;
      state.allHolidayData.hasErrors = true;
      state.allHolidayData.message = action.error.message;
    },
  },
});

export const holidaySelector = (state: RootState) => state.holiday;

export const { clearRemoveMessage } = holidaySlice.actions;

// The reducer
export default holidaySlice.reducer;
