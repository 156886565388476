import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAdmissionBranchWiseReport, IAdmissionReport, IPackageReport,ISubCourseRevenue } from "src/services/statistics/statistic.model";
import { ISubCourseReportPayload } from "src/components/SubCourseRevenueChart/SubCourseRevenueChart.model";
import statisticService from "src/services/statistics/statistic.service";


export const totalCompletionReport = createAsyncThunk(
    "statistics/totalCompletionReport",
    async (data: IAdmissionReport | URLSearchParams | {}) => {
        return statisticService.totalCompletionReport(data);
    }
);

export const packageRevenueReport = createAsyncThunk(
    "statistics/packageRevenueReport",
    async (data: IPackageReport | URLSearchParams | {}) => {
        return statisticService.packageRevenueReport(data);
    }
);

export const admissionReport = createAsyncThunk(
    "statistics/admissionReport",
    async (data: IAdmissionBranchWiseReport | URLSearchParams | {}) => {
        return statisticService.admissionReport(data);
    }
);

export const subCourseRevenueReport = createAsyncThunk(
    "statistics/subCourseRevenueReport",
    async (data: ISubCourseRevenue | URLSearchParams | {}) => {
        return statisticService.subCourseRevenueReport(data);
    }
);
