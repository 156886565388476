import { createAsyncThunk } from "@reduxjs/toolkit";
import { ISubcourseRecord } from "../../pages/SubCourse/Subcourse.model";
import { ISubCourseStatus } from "../../services/subCourse/subCourse.model";
import SubCourseService from "../../services/subCourse/subCourse.service";

export const removeSubCourseById = createAsyncThunk(
  "subCourse/removeSubCourseById",
  async (id: number) => {
    return SubCourseService.removeSubCourseById(id);
  }
);

export const updateSubCourseStatus = createAsyncThunk(
  "subCourse/updateSubCourseStatus",
  async (data: ISubCourseStatus) => {
    return SubCourseService.updateSubCourseStatus(data);
  }
);

export const editSubCourseById = createAsyncThunk(
  "subCourse/editSubCourseById",
  async (data: ISubcourseRecord) => {
    return SubCourseService.editSubCourseById(data);
  }
);

export const createNewSubCourse = createAsyncThunk(
  "subCourse/createNewSubCourse",
  async (data: ISubcourseRecord) => {
    return SubCourseService.createNewSubCourse(data);
  }
);

export const searchSubCourseData = createAsyncThunk(
  "subCourse/searchSubCourseData",
  async (data: any) => {
    return SubCourseService.searchSubCourseData(data);
  }
);

export const searchSubCourseDataById = createAsyncThunk(
  "subCourse/searchSubCourseDataById",
  async (data: any) => {
    return SubCourseService.searchSubCourseDataById(data);
  }
);
