import { createAsyncThunk } from "@reduxjs/toolkit";
import { IOutStandingIncomeDetailsRecord } from "src/services/outStandingIncome/outStandingIncome.model";
import outStandingIncomeService from "src/services/outStandingIncome/outStandingIncome.service";

export const outStandingIncomeData = createAsyncThunk(
  "reports/outstanding-income",
  async (data: IOutStandingIncomeDetailsRecord | URLSearchParams) => {
    return outStandingIncomeService.searchOutStandingReportData(data);
  }
);

export const searchOutStandingIncomeCsvEmailSendData = createAsyncThunk(
  "reports/searchOutStandingIncomeCsvEmailSendData",
  async (data: IOutStandingIncomeDetailsRecord | URLSearchParams | {}) => {
    return outStandingIncomeService.searchOutStandingIncomeCsvEmailSendData(
      data
    );
  }
);
