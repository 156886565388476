interface MobileNumberProps {
    studentMobileNumber: string;
  }
const MobileNumber = (props:MobileNumberProps) =>{
    const { studentMobileNumber } = props;
    const formattedMobileNumber = studentMobileNumber?.replace(/(\d{5})(\d{5})/, '$1 $2');
    return(
        <>
            <div className="gx-d-flex gx-align-items-center gx-mt-1 mobileNumber"><small>{formattedMobileNumber}</small><span className="mobile-wp-icon"><a className="gx-mr-1" target="_blank" href={`tel:+91${studentMobileNumber}`}><i className="fa fa-phone"></i></a><a target="_blank" href={`https://wa.me/+91${studentMobileNumber}`}><i className="fa fa-whatsapp"></i></a></span></div>
        </>
    )
}
export default MobileNumber;