import { Button, Card, Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TableComponent from 'src/components/DataTable';
import { AppDispatch } from 'src/state/app.model';
import { bufferURLDecode, bufferURLEncode } from 'src/utils/helperFunction';
import StudentMobileNumber from "src/components/StudentMobileNumber";
import { useAppSelector } from 'src/state/app.hooks';
import { userBatchSelector } from 'src/state/userBatch/userBatch.reducer';
import { searchUserBatchData } from 'src/state/userBatch/userBatch.action';
import { examPaperList } from 'src/state/ExamBatch/exambatch.action';
import { examBatchSelector } from 'src/state/ExamBatch/exambatch.reducer';
import { IExamPaperList } from 'src/services/ExamBatch/ExamBatch.model';

const ExamBatchStudent = () => {
  const { batch_id, exam_paper_id } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userBatchState = useAppSelector(userBatchSelector);
  const batchExamtState = useAppSelector(examBatchSelector);

  useEffect(() => {
    dispatch(searchUserBatchData({ noLimit: true }));
  }, [batch_id]);

  const currentBatchData = userBatchState.searchData.data.rows.find(
    (d) => d.id === Number(batch_id)
  );

  useEffect(() => {
    dispatch(examPaperList(Number(exam_paper_id)))
  }, [exam_paper_id])

  const newColumnsBatchWiseStudents: ColumnsType<IExamPaperList> = [
    {
      title: "No.",
      align: "center",
      width: "5%",
      render: (text, record, index) => (
        <>{index + 1}</>
      ),
    },
    {
      title: "GR ID",
      dataIndex: ["admission"],
      className: "admission",
      key: "gr_id",
      width: "5%",
      align: "center",
      render: (record) => {
        return <>{record?.gr_id}</>;
      },
    },
    {
      title: "Student Name",
      render: (record) => {
        return (
          <>
            <span>{record?.admission?.first_name + " " + record?.admission?.middle_name + " " + record?.admission?.last_name}</span>
            <StudentMobileNumber
              studentMobileNumber={String(record?.admission?.mobile_no)}
            />
          </>
        );
      },
    },
    {
      title: "Obtain Marks",
      dataIndex: "obtain_marks",
      className: "obtain_marks",
      align: "center",
      width: "5%",
      render: (record) => {
        return (
          record!==null ? <>{record}</> : <>{""}</>
        )
      }
    },
    {
      title: "Action",
      key: "action",
      width: "5%",
      align: "center",
      render: (record) => {
        return (
          <>
            {
              <>

                <div>
                  <Button
                    onClick={() => {
                      const backURL = bufferURLEncode(
                        `${location.pathname}${location.search}`
                      );
                      navigate(`/batch/${batch_id}/${exam_paper_id}/${record.id}/answersheet?r=${backURL}`, {
                        replace: true,
                      });
                    }}
                  >
                    Answer Sheet
                  </Button>
                </div>

              </>
            }
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="rnw-main-content">
        <Row
          align="middle"
          justify="space-between"
          gutter={24}
          className="mb-20"
        >
          <Col xs={20} sm={16}>
            {currentBatchData && (
              <>
                <h2 className="rnw-page-title rnw-page-card-title">
                  <span>{currentBatchData?.name}</span>
                </h2>
                <span>({currentBatchData.user.first_name + " " + currentBatchData.user.last_name} - {currentBatchData.subcourse.name})</span>
              </>
            )}
          </Col>
          <Col xs={4} sm={8} className="text-align-right">
            <Button
              type="primary"
              onClick={() => {
                const url = searchParams.get("r");
                if (url) {
                  const backURL = bufferURLDecode(url);
                  navigate(backURL);
                } else {
                  const currentPath = window.location.pathname;
                  const segments = currentPath.split('/');
                  const targetSegment = segments[1];
                  if (targetSegment == "batch") {
                    navigate("/batch");
                  } else {
                    navigate("/my-team-batches");
                  }
                }
              }}
            >
              <i className="fa fa-arrow-left back-icon"></i>{" "}
              <span className="gx-d-none gx-d-sm-inline-block">Back</span>
            </Button>
          </Col>
        </Row>
        <Card className="rnw-card table-card cheque-amount gx-mb-0">
          <TableComponent
            columns={newColumnsBatchWiseStudents}
            dataSource={batchExamtState.examPaperList?.data && batchExamtState?.examPaperList?.data || []}
            loading={false}
          />
        </Card>
      </div>

    </>
  )
}

export default ExamBatchStudent