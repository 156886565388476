import request from "src/lib/axios/request";
import { config } from "src/utils";
import { IApiResponse } from "../../utils/models/common";

class ExamBatchService {
  ENDPOINT = config.baseApiBatch + "/batch-exam";

  public examBatch = async (
    batch_id: number
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${batch_id}/exams`;
    return request({ url, method: "GET", params: batch_id }).then((res) => {
      return res.data;
    });
  };

  public examPaperList = async (
    exam_paper_id: number
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${exam_paper_id}/exam-paper-list`;
    return request({ url, method: "GET", params: exam_paper_id }).then((res) => {
      return res.data;
    });
  };

  public examPaperQuestion = async (
    exam_student_id: number
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${exam_student_id}/exam-paper`;
    return request({ url, method: "GET", params: exam_student_id }).then((res) => {
      return res.data;
    });
  };

  public addMarkById = async (
    data: any
  ): Promise<IApiResponse<any>> => {
    const url = `${this.ENDPOINT}/${data?.exam_student_id}/add-mark`;
    return request({
      url,
      method: "PUT",
      data: data.payload,
    }).then((res) => {
      return res.data;
    });
  };
}

export default new ExamBatchService();
