import request from "src/lib/axios/request";
import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import {
  IAvailablePcsTimeWise,
  IBranchInfrastructure,
  IBranchWiseClassSchedule,
  ICreateBranchInfrastructure,
  IEditBranchInfrastructure,
  IFacultyAvailableSlot,
} from "./branchInfrastructure.model";

class BranchInfrastructureService {
  ENDPOINT = config.baseApiLabMasters + "/branch-infrastructure";

  public searchBranchInfrastructureData = async (
    data: URLSearchParams | {}
  ): Promise<IApiResponse<IBranchInfrastructure>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public createNewBranchInfrastructure = async (
    data: ICreateBranchInfrastructure
  ): Promise<IApiResponse<ICreateBranchInfrastructure>> => {
    const url = `${this.ENDPOINT}/create`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public editBranchInfrastructureById = async (
    data: IEditBranchInfrastructure
  ): Promise<IApiResponse<IEditBranchInfrastructure>> => {
    const url = `${this.ENDPOINT}/${data.id}/update`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

  public branchWiseClassSchedule = async (
    data: URLSearchParams | {}
  ): Promise<IApiResponse<IBranchWiseClassSchedule>> => {
    const url = `${this.ENDPOINT}/branch-wise-class-schedule`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public facultyAvailableSlots = async (
    data: URLSearchParams | {}
  ): Promise<IApiResponse<IFacultyAvailableSlot[]>> => {
    const url = `${this.ENDPOINT}/faculty-available-slots`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public availablePcsTimeWise = async (
    data: URLSearchParams | {}
  ): Promise<IApiResponse<IAvailablePcsTimeWise>> => {
    const url = `${this.ENDPOINT}/available-pcs-time-wise`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
}

export default new BranchInfrastructureService();
