import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import {
  createNewExpenseMaster,
  editExpenseMasterById,
  removeExpenseMasterById,
  searchExpenseMasterData,
  updateExpenseMasterStatus,
} from "./expenseMaster.action";
import { IExpenseMasterState } from "./expenseMaster.model";

export const initialState: IExpenseMasterState = {
  searchExpenseMasterData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  createExpenseMaster: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  editExpenseMasterById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  removeById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  updateById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
};

export const expenseMasterSlice = createSlice({
  name: "expense-master",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.removeById.message = "";
      state.createExpenseMaster.message = "";
      state.editExpenseMasterById.message = "";
      state.updateById.message = "";
    },
  },
  extraReducers: {
    [searchExpenseMasterData.pending.type]: (state) => {
      state.searchExpenseMasterData.loading = true;
    },
    [searchExpenseMasterData.fulfilled.type]: (state, action) => {
      state.searchExpenseMasterData.data = action.payload.data;
      state.searchExpenseMasterData.message = action.payload.message;
      state.searchExpenseMasterData.loading = false;
      state.searchExpenseMasterData.hasErrors = false;
    },
    [searchExpenseMasterData.rejected.type]: (state, action) => {
      state.searchExpenseMasterData.loading = false;
      state.searchExpenseMasterData.hasErrors = true;
      state.searchExpenseMasterData.message = action.error.message;
    },

    //create expense-master
    [createNewExpenseMaster.pending.type]: (state) => {
      state.createExpenseMaster.loading = true;
    },
    [createNewExpenseMaster.fulfilled.type]: (state, action) => {
      state.createExpenseMaster.message = action.payload.message;
      state.createExpenseMaster.hasErrors = action.payload.error;
      state.createExpenseMaster.loading = false;
      state.createExpenseMaster.hasErrors = false;
    },
    [createNewExpenseMaster.rejected.type]: (state, action) => {
      state.createExpenseMaster.loading = false;
      state.createExpenseMaster.hasErrors = true;
      state.createExpenseMaster.message = action.error.message;
    },

    //edit expense-master
    [editExpenseMasterById.pending.type]: (state) => {
      state.editExpenseMasterById.loading = true;
    },
    [editExpenseMasterById.fulfilled.type]: (state, action) => {
      state.editExpenseMasterById.message = action.payload.message;
      state.editExpenseMasterById.loading = false;
      state.editExpenseMasterById.hasErrors = false;
    },
    [editExpenseMasterById.rejected.type]: (state, action) => {
      state.editExpenseMasterById.loading = false;
      state.editExpenseMasterById.hasErrors = true;
      state.editExpenseMasterById.message = action.error.message;
    },

    //Delete expense-master
    [removeExpenseMasterById.pending.type]: (state) => {
      state.removeById.loading = true;
    },
    [removeExpenseMasterById.fulfilled.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = false;
      state.removeById.message = action.payload.message;
    },
    [removeExpenseMasterById.rejected.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = true;
      state.removeById.message = action.error.message;
    },

    //update expense-master
    [updateExpenseMasterStatus.pending.type]: (state) => {
      state.updateById.loading = true;
    },
    [updateExpenseMasterStatus.fulfilled.type]: (state, action) => {
      state.updateById.message = action.payload.message;
      state.updateById.loading = false;
      state.updateById.hasErrors = false;
    },
    [updateExpenseMasterStatus.rejected.type]: (state, action) => {
      state.updateById.loading = false;
      state.updateById.hasErrors = true;
      state.updateById.message = action.error.message;
    },
  },
});

// A selector
export const expenseMasterSelector = (state: RootState) => state.expenseMaster;

export const { clearRemoveMessage } = expenseMasterSlice.actions;

// The reducer
export default expenseMasterSlice.reducer;
