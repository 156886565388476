import { createAsyncThunk } from "@reduxjs/toolkit";
import { IBatchAssignedStudentCourseStatus } from "src/pages/UserWiseBatch/BatchWiseStudentDetails.model";
import { IAdmissionStatus } from "src/services/admission/admission.model";
import {
  BatchWiseStudentCompleted,
  IBulkAssignBatch,
  IMergeBatch,
  ISessionAttendenceData,
  IUpdateBatchShiningSheetTopicNoteData,
  IUserBatchRecord,
} from "src/services/userBatch/userBatch.model";
import userBatchService from "src/services/userBatch/userBatch.service";
import {
  IFacultyBatch,
  IFacultyStudent,
  IStudentByBatch,
} from "./userBatch.model";
import {
  CreateBatchSigningSheetTopic,
  batch_faculty_student_attendances,
} from "src/pages/UserWiseBatch/UserWiseBatch.model";
import { IStudentRecord } from "src/pages/MyTeamBatches/MyTeamBatches.model";
import { IHoldBatchPayload } from "src/services/batch/batch.model";

export const searchUserBatchData = createAsyncThunk(
  "batch/searchUserBatchData",
  async (data: any) => {
    return userBatchService.searchUserBatchData(data);
  }
);

export const searchMyTeamBatchData = createAsyncThunk(
  "batch/searchMyTeamBatchData",
  async (data: any) => {
    return userBatchService.searchMyTeamBatchData(data);
  }
);

export const countBatchData = createAsyncThunk(
  "category/getAdmissionStatusData",
  async (data: IAdmissionStatus | URLSearchParams | {}) => {
    return userBatchService.countBatchData(data);
  }
);

export const batchStartByStatus = createAsyncThunk(
  "batch/batchStartByStatus",
  async (data: IUserBatchRecord) => {
    return userBatchService.batchStartByStatus(data);
  }
);

export const batchSessionAttendence = createAsyncThunk(
  "batch/batchSessionAttendence",
  async (data: ISessionAttendenceData) => {
    return userBatchService.batchSessionAttendence(data);
  }
);

export const getShiningSheetTopicByBatch = createAsyncThunk(
  "batch/getShiningSheetTopicByBatch",
  async (data: IStudentByBatch) => {
    return userBatchService.getShiningSheetTopicByBatch(data);
  }
);
export const getBatchFacultyAttendance = createAsyncThunk(
  "batch/getBatchFacultyAttendance",
  async (data: IFacultyBatch) => {
    return userBatchService.getBatchFacultyAttendance(data);
  }
);
export const getBatchStudentAttendance = createAsyncThunk(
  "batch/getBatchStudentAttendance",
  async (data: IFacultyStudent) => {
    return userBatchService.getBatchStudentAttendance(data);
  }
);

export const facultyStudentAttendence = createAsyncThunk(
  "batch/facultyStudentAttendence",
  async (data: batch_faculty_student_attendances) => {
    return userBatchService.facultyStudentAttendence(data);
  }
);

export const batchAssignedStudentCourseStatusAutoChange = createAsyncThunk(
  "batch/batchAssignedStudentCourseAutoChange",
  async (data: IBatchAssignedStudentCourseStatus) => {
    return userBatchService.batchAssignedStudentCourseStatusAutoChange(data);
  }
);

export const getBatchWiseStudentDetails = createAsyncThunk(
  "batch/getBatchWiseStudentDetails",
  async (data: any) => {
    return userBatchService.getBatchWiseStudentDetails(data);
  }
);

export const getBatchWiseStudentPresentAbsentDetails = createAsyncThunk(
  "batch/getBatchWiseStudentPresentAbsentDetails",
  async (data: IStudentRecord) => {
    return userBatchService.getBatchWiseStudentPresentAbsentDetails(data);
  }
);

export const addNewTopicInSigningSheet = createAsyncThunk(
  "batch/addNewTopicInSigningSheet",
  async (data: CreateBatchSigningSheetTopic) => {
    return userBatchService.addNewTopicInSigningSheet(data);
  }
);

export const studentDetailsCsvEmailSendData = createAsyncThunk(
  "reports/studentDetailsCsvEmailSendData",
  async (batch_id: number) => {
    return userBatchService.studentDetailsCsvEmailSendData(batch_id);
  }
);

export const getBatchCompletedStudentAndNotCompletedStudentDetails =
  createAsyncThunk(
    "batch/getBatchCompletedStudentAndNotCompletedStudentDetails",
    async (batch_id: number) => {
      return userBatchService.getBatchCompletedStudentAndNotCompletedStudentDetails(
        batch_id
      );
    }
  );

export const batchWiseStudentCompleted = createAsyncThunk(
  "batch/batchWiseStudentCompleted",
  async (data: BatchWiseStudentCompleted) => {
    return userBatchService.batchWiseStudentCompleted(data);
  }
);

export const facultyStudentAttendancesUpdate = createAsyncThunk(
  "batch/facultyStudentAttendenceUpdate",
  async (data: batch_faculty_student_attendances) => {
    return userBatchService.facultyStudentAttendancesUpdate(data);
  }
);

export const facultyStudentAttendancesDelete = createAsyncThunk(
  "batch/facultyStudentAttendenceUpdate",
  async (batch_faculty_attendance_id: number) => {
    return userBatchService.facultyStudentAttendancesDelete(
      batch_faculty_attendance_id
    );
  }
);

export const reGenerateBatchSiningSheet = createAsyncThunk(
  "batch/reGenerateBatchSiningSheet",
  async (batch_id: number) => {
    return userBatchService.reGenerateBatchSiningSheet(batch_id);
  }
);

export const updateBatchShiningSheetTopicNote = createAsyncThunk(
  "batch/updateBatchShiningSheetTopicNote",
  async (data: IUpdateBatchShiningSheetTopicNoteData) => {
    return userBatchService.updateBatchShiningSheetTopicNote(data);
  }
);

export const createBatchHoldDateData = createAsyncThunk(
  "batch/createBatchHoldDateData",
  async (data: IHoldBatchPayload) => {
    return userBatchService.createBatchHoldDateData(data);
  }
);


export const searchBatchHoldDateData = createAsyncThunk(
  "batch/searchBatchHoldDateData",
  async (batchId:number) => {
    return userBatchService.searchBatchHoldDateData(batchId);
  }
);
export const editByIdBatchHoldDateData = createAsyncThunk(
  "batch/editByIdBatchHoldDateData",
  async (data: IHoldBatchPayload) => {
    return userBatchService.editByIdBatchHoldDateData(data);
  }
);
export const removeByIdBatchHoldDateData = createAsyncThunk(
  "batch/removeByIdBatchHoldDateData",
  async (recordId : number) => {
    return userBatchService.removeByIdBatchHoldDateData(recordId);
  }
)
export const mergeBatch = createAsyncThunk(
  "batch/mergeBatch",
  async (data: IMergeBatch) => {
    return userBatchService.mergeBatch(data);
  }
);

export const getCourseWiseStudentListData = createAsyncThunk(
  "batch/getCourseWiseStudentListData",
  async (data: { batch_id: string | number | URLSearchParams }) => {
    return userBatchService.getCourseWiseStudentList(data);
  }
);

export const bulkStudentAssign = createAsyncThunk(
  "batch/createBulkRemark",
  async (data: IBulkAssignBatch | URLSearchParams | {}) => {
    return userBatchService.bulkStudentAssign(data);
  }
);

