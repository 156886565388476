import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { createNewArea, editAreaById, removeAreaById, searchAreaData } from "./Area.action";
import { IAreaState } from "./Area.model";

export const initialState: IAreaState = {
  removeById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  updateById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  editById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  createArea: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  areasData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
};

export const zoneSlice = createSlice({
  name: "area",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
        state.removeById.message = "";
        state.updateById.message="";
        state.editById.message="";
        state.createArea.message="";
    },
  },
  extraReducers: {

    [removeAreaById.pending.type]: (state) => {
        state.removeById.loading = true;
    },
    [removeAreaById.fulfilled.type]: (state, action) => {
        state.removeById.loading = false;
        state.removeById.hasErrors = false;
        state.removeById.message = action.payload.message;
    },
    [removeAreaById.rejected.type]: (state,action) => {
        state.removeById.loading = false;
        state.removeById.hasErrors = true;
        state.removeById.message =action.error.message;
    },

    [editAreaById.pending.type]: (state) => {
        state.editById.loading = true;
    },
    [editAreaById.fulfilled.type]: (state, action) => {
        state.editById.message = action.payload.message;
        state.editById.loading = false;
        state.editById.hasErrors = false;
    },
    [editAreaById.rejected.type]: (state,action) => {
        state.editById.loading = false;
        state.editById.hasErrors = true;
        state.editById.message = action.error.message;
    },

    [createNewArea.pending.type]: (state) => {
        state.createArea.loading = true;
    },
    [createNewArea.fulfilled.type]: (state, action) => {
        state.createArea.message = action.payload.message;
        state.createArea.hasErrors = action.payload.error;
        state.createArea.loading = false;
        state.createArea.hasErrors = false;

    },
    [createNewArea.rejected.type]: (state,action) => {
        state.createArea.loading = false;
        state.createArea.hasErrors = true;
        state.createArea.message = action.error.message;
    },

    //  get area
    [searchAreaData.pending.type]: (state) => {
        state.areasData.loading = true;
    },
    [searchAreaData.fulfilled.type]: (state, action) => {
        state.areasData.data = action.payload.data;
        state.areasData.message = action.payload.message;
        state.areasData.loading = false;
        state.areasData.hasErrors = false;
    },
    [searchAreaData.rejected.type]: (state,action) => {
        state.areasData.loading = false;
        state.areasData.hasErrors = true;
        state.areasData.message =action.error.message;
    },
  },
});

// A selector
export const areaSelector = (state: RootState) => state.area;

export const { clearRemoveMessage } = zoneSlice.actions;

// The reducer
export default zoneSlice.reducer;
