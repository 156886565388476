import { combineReducers } from "@reduxjs/toolkit";
import CommonReducer from "./common/common.reducer";
import courseReducer from "./course/course.reducer";
import departmentReducer from "./department/department.reducer";
import roleReducer from "./role/role.reducer";
import loginReducer from "./Login/login.reducer";
import SettingsReducer from "./setting/setting.reducer";
import subCourseReducer from "./subCourse/subCourse.reducer";
import subdepartmentReducer from "./subDepartment/subDepartment.reducer";
import userReducer from "./users/user.reducer";
import zoneReducer from "./zone/zone.reducer";
import admissionReducer from "./admission/admission.reducer";
import subCourseTopicReducer from "./subCourseTopic/subCourseTopic.reducer";
import feeTypeReducer from "./feeType/feeType.reducer";
import categoryReducer from "./category/category.reducer";
import subCategoryReducer from "./subCategory/subCategory.reducer";
import expenseReducer from "./expense/expense.reducer";
import commonModuleReducer from "./commonModule/commonModule.reducer";
import branchReducer from "./branch/branch.reducer";
import packageReducer from "./package/package.reducer";
import batchReducer from "./batch/batch.reducer";
import sourceReducer from "./source/source.reducer";
import paymentModeReducer from "./paymentMode/paymentMode.reducer";
import overdueIncomeReducer from "./overdueIncome/overdueIncome.reducer";
import expenseMasterReducer from "./expenseMaster/expenseMaster.reducer";
import studentsReducer from "./students/students.reducer";
import userBatchReducer from "./userBatch/userBatch.reducer";
import installmentReducer from "./installment/installment.reducer";
import rolePermissionsReducer from "./rolePermissions/rolePermissions.reducer";
import transferadmissionReducer from "./transferadmission/transferadmission.reducer";
import outStandingIncomeReducer from "./outStandingIncome/outStandingIncome.reducer";
import incomeReducer from "./income/income.reducer";
import templateSignningsheetReducer from "./templateSignningsheet/templateSignningsheet.reducer";
import cityReducer from "./city/city.reducer";
import AreaReducer from "./Area/Area.reducer";
import studentListReducer from "./studentList/studentList.reducer";
import admissionMicroServiceService from "src/services/admissionMicroService/admissionMicroService.service";
import admissionMicroServiceReducer from "./admissionMicroService/admissionMicroService.reducer";
import holidayReducer from "./holiday/holiday.reducer";
import reportReducer from "./report/report.reducer";
import branchInfrastructureReducer from "./BranchInfrastructure/branchInfrastructure.reducer";
import hardwareReducer from "./Hardware/hardware.reducer";
import placesReducer from "./places/places.reducer";
import tagsReducer from "./tags/tags.reducer";
import statisticReducer from "./statistics/statistic.reducer";
import subCourseQuestionReducer from "./SubCourseQuestion/subCourseQuestion.reducer";
import subCourseQuestionBankReducer from "./SubCourseQuestionBank/subCourseQuestionBank.reducer";
import assignQuestionBankReducer from "./assignQuestionBank/assignQuestionBank.reducer";
import examBatchReducer from "./ExamBatch/exambatch.reducer";
import examResultReducer from "./ExamResult/examResult.reducer";

export const combinedReducer = combineReducers({
  admission: admissionReducer,
  transferadmission: transferadmissionReducer,
  departments: departmentReducer,
  subdepartment: subdepartmentReducer,
  subcourse: subCourseReducer,
  subcoursetopic: subCourseTopicReducer,
  feetype: feeTypeReducer,
  zone: zoneReducer,
  category: categoryReducer,
  subcategory: subCategoryReducer,
  user: userReducer,
  course: courseReducer,
  role: roleReducer,
  expense: expenseReducer,
  expenseMaster: expenseMasterReducer,
  commonModule: commonModuleReducer,
  branch: branchReducer,
  common: CommonReducer,
  settings: SettingsReducer,
  login: loginReducer,
  package: packageReducer,
  batch: batchReducer,
  source: sourceReducer,
  paymentMode: paymentModeReducer,
  overdueIncome: overdueIncomeReducer,
  Income: incomeReducer,
  students: studentsReducer,
  userbatch: userBatchReducer,
  installment: installmentReducer,
  outStandingIncome: outStandingIncomeReducer,
  templateSigningSheet: templateSignningsheetReducer,
  rolePermissions: rolePermissionsReducer,
  city: cityReducer,
  area: AreaReducer,
  studentList: studentListReducer,
  admissionMicroService: admissionMicroServiceReducer,
  holiday: holidayReducer,
  report: reportReducer,
  branchInfrastructure: branchInfrastructureReducer,
  hardware: hardwareReducer,
  places: placesReducer,
  tag: tagsReducer,
  statistics: statisticReducer,
  question: subCourseQuestionReducer,
  questionbank: subCourseQuestionBankReducer,
  assignQuestionBank: assignQuestionBankReducer,
  examBatch: examBatchReducer,
  examResult: examResultReducer
});

export const rootReducer = (state: any, action: any) => {
  return combinedReducer(state, action);
};
