import { createAsyncThunk } from "@reduxjs/toolkit";
import reportService from "src/services/report/report.service";
import { IDashboardReportPayload } from "src/services/studentList/studentList.model";

export const getCompanyVisitReportData = createAsyncThunk(
  "report/getCompanyVisitReportData",
  async (data: URLSearchParams | {}) => {
    return reportService.getCompanyVisitReportData(data);
  }
);

export const getDegradeAndCancelRemarkData = createAsyncThunk(
  "report/getDegradeAndCancelRemarkData",
  async (data: URLSearchParams | {}) => {
    return reportService.getDegradeAndCancelRemarkData(data);
  }
);

export const getExpertSessionsReportData = createAsyncThunk(
  "report/getExpertSessionsReportData",
  async (data: URLSearchParams | {}) => {
    return reportService.getExpertSessionsReportData(data);
  }
);
export const getStudentsProjectAnalysisReportData = createAsyncThunk(
  "report/getStudentsProjectAnalysisReportData",
  async (data: URLSearchParams | {}) => {
    return reportService.getStudentsProjectAnalysisReportData(data);
  }
);
export const getStudentsTopicAnalysisReportData = createAsyncThunk(
  "report/getStudentsTopicAnalysisReportData",
  async (data: URLSearchParams | {}) => {
    return reportService.getStudentsTopicAnalysisReportData(data);
  }
);

export const getLabWiseStudentData = createAsyncThunk(
  "report/getLabWiseStudentData",
  async (data: URLSearchParams | {}) => {
    return reportService.getLabWiseStudentData(data);
  }
);

export const getLabWiseBranchUtilizationData = createAsyncThunk(
  "report/getLabWiseBranchUtilizationData",
  async (data: URLSearchParams | {}) => {
    return reportService.getLabWiseBranchUtilizationData(data);
  }
);

export const getBranchWiseAllLabReport = createAsyncThunk(
  "report/getBranchWiseAllLabReport",
  async (data: URLSearchParams | {}) => {
    return reportService.getBranchWiseAllLabReport(data);
  }
);

export const branchWiseHoldStudentReport = createAsyncThunk(
  "report/branchWiseHoldStudentReport",
  async (data: URLSearchParams | {}) => {
    return reportService.branchWiseHoldStudentReport(data);
  }
);

export const removeSlotById = createAsyncThunk(
  "report/removeSlotById",
  async (id: number) => {
    return reportService.removeSlotById(id);
  }
);

// cd grade history
export const searchCDGradeStudentHistory = createAsyncThunk(
  "report/searchCDGradeStudentHistory",
  async (data: URLSearchParams | undefined | {}) => {
    return reportService.searchCDGradeStudentHistory(data);
  }
);

// cd grade history csv download
export const downloadCsvCDGradeStudentHistory = createAsyncThunk(
  "report/downloadCsvCDGradeStudentHistory",
  async (data: URLSearchParams | undefined | {}) => {
    return reportService.downloadCsvCDGradeStudentHistory(data);
  }
);

// searchCounsellorData
export const searchCounsellorData = createAsyncThunk(
  "report/searchCounsellorData",
  async () => {
    return reportService.searchCounsellorData();
  }
);

export const searchStudentExamMarks = createAsyncThunk(
  "report/searchStudentExamMarks",
  async (data: URLSearchParams | undefined | {}) => {
    return reportService.searchStudentExamMarks(data);
  }
);
