import request from "src/lib/axios/request";
import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import { ICreateHardware, IEditHardware, IHardwareDetails, IDeleteHardwareData} from "./Hardware.model";

class HardwareService {
  ENDPOINT = config.baseApiLabMasters + "/hardware";

  public searchHardwareData = async (
    data:  URLSearchParams | {} 
  ): Promise<IApiResponse<IHardwareDetails>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public createNewHardware = async (
    data: ICreateHardware
  ): Promise<IApiResponse<ICreateHardware>> => {
    const url = `${this.ENDPOINT}/create`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public editHardwareById = async (
    data: IEditHardware
  ): Promise<IApiResponse<IEditHardware>> => {
    const url = `${this.ENDPOINT}/${data.id}/update`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

  public deleteHardware = async (
    data: IDeleteHardwareData
  ): Promise<IApiResponse<any>> => {
    const url = `${this.ENDPOINT}/${data.id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };
}

export default new HardwareService();
