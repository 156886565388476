import { Button, Form, Modal, Rate, Select, Skeleton, message } from "antd";
import { useEffect, useState } from "react";
import DrawerComponent from "src/components/Drawer";
import FloatLabel from "src/components/Form/FloatLabel";
import { IRemarkDetails } from "src/services/admission/admission.model";
import Table, { ColumnsType } from "antd/lib/table";
import { IRemarksProps } from "./AddRemarks.model";
import { dateFormate, trimObject } from "src/utils/helperFunction";
import {
  createNewRemark,
  getAdmissionRemarksData,
} from "src/state/admission/admission.action";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/state/app.model";
import { useAppSelector } from "src/state/app.hooks";
import { admissionSelector } from "src/state/admission/admission.reducer";
import {
  admissionViewAndUnAssignAdmissionViewRemarks,
  Common,
} from "src/utils/constants/constant";
import { Can } from "src/ability/can";
import TextEditor from "../TextEditor";
import { useWatch } from "antd/lib/form/Form";
import { clearRemoveMessage } from "src/state/course/course.reducer";
import RemarksLabels from "../RemarksLabel";

const { Option } = Select;

const Remarks = (props: IRemarksProps) => {
  const {
    branchId,
    admissionStatus,
    admissionId,
    remarksModalOpen,
    setRemarksModalOpen,
    setAbsentStudentRemark,
  } = props;
  const [loading, setLoading] = useState(true);
  const [remarksValue, setRemarkValue] = useState<IRemarkDetails>();
  const [remarkData, setRemarkData] = useState<IRemarkDetails[] | undefined>(
    []
  );
  const [page, setPage] = useState(1);
  const [btn, setBtn] = useState<boolean>(false);
  const [editorValue, setEditorValue] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const admissionState = useAppSelector(admissionSelector);
  const [form] = Form.useForm();
  const rules = {
    labels: [{ required: true, message: "Please Select Labels" }],
    remarks: [{ required: true, message: "Please Enter Remarks" }],
    rating: [{ required: true, message: "Please Select Rating" }],
  };

  const remarkField = useWatch("remarks", form);
  useEffect(() => {
    if (!!remarkField && !remarkField.replace(/<\/?[^>]+>/g, "")?.length) {
      form.setFieldValue("remarks", undefined);
    }
  }, [remarkField]);

  useEffect(() => {
    if (remarksValue) {
      const remarkTrim = remarksValue.remarks.split("<p> ");
      remarksValue.remarks =
        remarkTrim.length === 1 ? remarkTrim[0] : "<p>" + remarkTrim[1].trim();
      if (remarksValue.remarks !== "<p></p>") {
        dispatch(createNewRemark(trimObject(remarksValue))).then((res) => {
          message.success(
            admissionState.createRemark.message === ""
              ? "Admission remark created successfully"
              : admissionState.createRemark.message
          );
          dispatch(clearRemoveMessage());

          setAbsentStudentRemark &&
            setAbsentStudentRemark(remarksValue.admission_id || 0);
          setRemarksModalOpen(false);
          form.resetFields();
          setRemarkData([]);
          if (res.payload) {
            form.resetFields();
            dispatch(
              getAdmissionRemarksData({
                admission_id: admissionId,
                noLimit: true,
              })
            );
          }
        });
      } else {
        setBtn(false);
        message.error("Remarks should not consist of only spaces");
      }
    }
  }, [remarksValue]);

  const info = (value: string) => {
    Modal.info({
      title: "Remarks",
      content: (
        <div
          className="remark-message fixHeight-model"
          dangerouslySetInnerHTML={{ __html: value }}
        />
      ),
      onOk() {},
    });
  };

  useEffect(() => {
    setPage(1);
    if (admissionId) {
      setLoading(true);
      dispatch(
        getAdmissionRemarksData({ admission_id: admissionId, noLimit: true })
      ).then(() => {
        setLoading(false);
      });
    }
  }, [remarksModalOpen]);

  useEffect(() => {
    if (admissionState.remarksData.data) {
      setBtn(false);
      setRemarkData(admissionState.remarksData.data.rows);
    }
  }, [admissionState.remarksData.data]);

  const newColumns: ColumnsType<IRemarkDetails> = [
    {
      title: "No.",
      dataIndex: "id",
      render: (item, record, index) => <>{(page - 1) * 10 + index + 1}</>,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (remarks) => (
        <>
          <div className="view-remarks">
              <p className="gx-mr-1 note-text">
                <span dangerouslySetInnerHTML={{ __html: remarks }} />
              </p>
            <Button type="primary" onClick={() => info(remarks)}>More</Button>
          </div>
        </>
      ),
    },
    {
      title: "Labels",
      dataIndex: "labels",
    },
    {
      title: "Rating",
      dataIndex: "rating",
      render: (rating) => (
        <>
          <Rate disabled value={rating} />
          <span className="star-rating">
            <i className="fa fa-star gx-mr-2" style={{ color: "#fadb14" }}></i>
            {rating}
          </span>
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "created_date",
      render: (record) => {
        return <>{dateFormate(record)}</>;
      },
    },
    {
      title: "Add By",
      dataIndex: "created_by",
    },
  ];

  return (
    <DrawerComponent
      className="remark-admission overlapDrawer"
      title={"Remarks"}
      onClose={() => {
        setRemarksModalOpen(false);
        form.resetFields();
        setRemarkData([]);
      }}
      visible={remarksModalOpen}
    >
      <>
        {props.admissionViewAndUnAssignAdmissionView ===
        admissionViewAndUnAssignAdmissionViewRemarks.VIEW_ADMISSION_REMARKS ? (
          <>
            <Form
              form={form}
              className="drawer-form remark-admission"
              onFinish={(values: IRemarkDetails) => {
                setBtn(true);
                const data: IRemarkDetails = {
                  ...values,
                  branch_id: branchId,
                  remarks: values.remarks?.replace(/\s+/g, " "),
                  status: admissionStatus,
                  admission_id: admissionId,
                  type: "PUBLIC",
                };
                setRemarkValue(data);
              }}
            >
              <>
                <Can
                  I={Common.Actions.CAN_ADD}
                  a={Common.Modules.ADMISSION.ADMISSION_REMARKS}
                >
                  <FloatLabel
                    label="Labels"
                    placeholder="Select Labels"
                    name="labels"
                    required
                  >
                    <RemarksLabels />
                  </FloatLabel>

                  <FloatLabel
                    label="Rating"
                    placeholder="Select Rating"
                    name="rating"
                    required
                  >
                    <Form.Item name="rating" rules={rules.rating}>
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                      </Select>
                    </Form.Item>
                  </FloatLabel>

                  <div className="text-editor ">
                    <FloatLabel
                      label="Remarks"
                      placeholder="Enter Remarks"
                      name="remarks"
                      required
                    >
                      <Form.Item name="remarks" rules={rules.remarks}>
                        <TextEditor
                          value={editorValue || " "}
                          placeholder=""
                          onChange={setEditorValue}
                        />
                      </Form.Item>
                    </FloatLabel>
                  </div>

                  <Form.Item className="modal-btn-grp">
                    <Button
                      className="btn-submit"
                      key="submit"
                      type="primary"
                      htmlType="submit"
                      disabled={btn}
                    >
                      Submit
                    </Button>
                    <Button
                      className="btn-cancel"
                      onClick={() => {
                        setRemarksModalOpen(false);
                        setPage(2);
                        form.resetFields();
                      }}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Can>
              </>
            </Form>
            <Skeleton loading={loading}>
              <div className="remark-table">
                <Table
                  columns={newColumns}
                  pagination={false}
                  dataSource={remarkData || []}
                  loading={loading}
                />
              </div>
            </Skeleton>
          </>
        ) : props.admissionViewAndUnAssignAdmissionView ===
          admissionViewAndUnAssignAdmissionViewRemarks.VIEW_UN_ASSIGN_ADMISSION_REMARKS ? (
          <>
            <Form
              form={form}
              className="drawer-form remark-admission"
              onFinish={(values: IRemarkDetails) => {
                setBtn(true);
                const data: IRemarkDetails = {
                  ...values,
                  branch_id: branchId,
                  remarks: values.remarks?.replace(/\s+/g, " "),
                  status: admissionStatus,
                  admission_id: admissionId,
                  type: "PUBLIC",
                };
                setRemarkValue(data);
              }}
            >
              <>
                <FloatLabel
                  label="Labels"
                  placeholder="Select Labels"
                  name="labels"
                  required
                >
                  <RemarksLabels />
                </FloatLabel>

                <FloatLabel
                  label="Rating"
                  placeholder="Select Rating"
                  name="rating"
                  required
                >
                  <Form.Item name="rating" rules={rules.rating}>
                    <Select getPopupContainer={(trigger) => trigger.parentNode}>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                    </Select>
                  </Form.Item>
                </FloatLabel>

                <div className="text-editor">
                  <FloatLabel
                    label="Remarks"
                    placeholder="Enter Remarks"
                    name="remarks"
                    required
                  >
                    <Form.Item name="remarks" rules={rules.remarks}>
                      <TextEditor
                        value={editorValue || " "}
                        placeholder=""
                        onChange={setEditorValue}
                      />
                    </Form.Item>
                  </FloatLabel>
                </div>

                <Form.Item className="modal-btn-grp">
                  <Button
                    className="btn-submit"
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    disabled={btn}
                  >
                    Submit
                  </Button>
                  <Button
                    className="btn-cancel"
                    onClick={() => {
                      setRemarksModalOpen(false);
                      setPage(2);
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </>
            </Form>
            <Skeleton loading={loading}>
              <div className="remark-table">
                <Table
                  columns={newColumns}
                  pagination={false}
                  dataSource={remarkData || []}
                  loading={loading}
                />
              </div>
            </Skeleton>
          </>
        ) : props.admissionViewAndUnAssignAdmissionView ===
          admissionViewAndUnAssignAdmissionViewRemarks.VIEW_STUDENT_DETAILS_REMARKS ? (
          <>
            <Form
              form={form}
              className="drawer-form remark-admission"
              onFinish={(values: IRemarkDetails) => {
                setBtn(true);
                const data: IRemarkDetails = {
                  ...values,
                  branch_id: branchId,
                  remarks: values.remarks?.replace(/\s+/g, " "),
                  status: admissionStatus,
                  admission_id: admissionId,
                  type: "PUBLIC",
                };
                setRemarkValue(data);
              }}
            >
              <>
                <FloatLabel
                  label="Labels"
                  placeholder="Select Labels"
                  name="labels"
                  required
                >
                  <RemarksLabels />
                </FloatLabel>
                <FloatLabel
                  label="Rating"
                  placeholder="Select Rating"
                  name="rating"
                  required
                >
                  <Form.Item name="rating" rules={rules.rating}>
                    <Select getPopupContainer={(trigger) => trigger.parentNode}>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                    </Select>
                  </Form.Item>
                </FloatLabel>

                <div className="text-editor">
                  <FloatLabel
                    label="Remarks"
                    placeholder="Enter Remarks"
                    name="remarks"
                    required
                  >
                    <Form.Item name="remarks" rules={rules.remarks}>
                      <TextEditor
                        value={editorValue || " "}
                        placeholder=""
                        onChange={setEditorValue}
                      />
                    </Form.Item>
                  </FloatLabel>
                </div>

                <Form.Item className="modal-btn-grp">
                  <Button
                    className="btn-submit"
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    disabled={btn}
                  >
                    Submit
                  </Button>
                  <Button
                    className="btn-cancel"
                    onClick={() => {
                      setRemarksModalOpen(false);
                      setPage(2);
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </>
            </Form>
            <Skeleton loading={loading}>
              <div className="remark-table">
                <Table
                  columns={newColumns}
                  pagination={false}
                  dataSource={remarkData || []}
                  loading={loading}
                />
              </div>
            </Skeleton>
          </>
        ) : (
          ""
        )}
      </>
    </DrawerComponent>
  );
};

export default Remarks;
