import { Checkbox, Form } from "antd";
import { IViewTopicsAndSubTopicsProps } from "./SubCourseTemplate.model";
import { ISubCourseTopicDetails } from "src/services/subCourseTopic/subCourseTopic.model";

const ViewTopicsAndSubTopics = (props: IViewTopicsAndSubTopicsProps) => {
  const { data, onchange } = props;

  return (
    <>
      {data
        .filter(
          (topic: ISubCourseTopicDetails) =>
            topic.subTopics && topic?.subTopics?.length > 0
        )
        .map((topic: ISubCourseTopicDetails, index: number) => (
          <Form.Item key={`${JSON.stringify(topic)}`}>
            <Checkbox
              className="gx-py-2 gx-w-100"
              style={{ fontWeight: 500, borderBottom: "1px solid #e8e8e8" }}
              onChange={onchange}
              checked={topic.selected === true ? true : false}
              value={{
                topic: topic.id,
                sub_topic: null,
                subTopic: (topic?.subTopics || []).map((item) => item.id),
                id: topic.id,
                subTopicLength: topic.subTopics?.length,
              }}
            >
              {index + 1}.&nbsp;
              {topic?.name?.replace(/&lt;/g, "<").replace(/&gt;/g, ">")}
            </Checkbox>
            {topic.subTopics &&
              topic.subTopics.map((subTopic, i: number) => (
                <div
                  className="gx-py-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderBottom: "1px solid #e8e8e8",
                  }}
                >
                  <>
                    <Checkbox
                      style={{ fontWeight: 400, paddingLeft: "20px" }}
                      checked={subTopic?.selected === true ? true : false}
                      value={{
                        topic: topic.id,
                        sub_topic: i,
                        subTopic: subTopic.id,
                        id: topic.id,
                        subTopicLength: topic.subTopics?.length,
                      }}
                      onChange={onchange}
                    >
                      {Number(index + 1)}.{i + 1} &nbsp;
                      <span
                        dangerouslySetInnerHTML={{
                          __html: subTopic?.description
                            ?.replace(/&lt;/g, "<")
                            .replace(/&gt;/g, ">"),
                        }}
                      />
                    </Checkbox>
                  </>
                </div>
              ))}
          </Form.Item>
        ))}
    </>
  );
};

export default ViewTopicsAndSubTopics;
