import request from "src/lib/axios/request";
import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import { IOutStandingIncomeDetailsRecord } from "./outStandingIncome.model";

class OutStandingIncome {
  ENDPOINT = config.baseApiAdmission + "/reports";

  public searchOutStandingReportData = async (
    data: IOutStandingIncomeDetailsRecord | URLSearchParams
  ): Promise<IApiResponse<IOutStandingIncomeDetailsRecord>> => {
    const url = `${this.ENDPOINT}/outstanding-income`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public searchOutStandingIncomeCsvEmailSendData = async (
    data: IOutStandingIncomeDetailsRecord | URLSearchParams | {}
  ): Promise<IApiResponse<IOutStandingIncomeDetailsRecord>> => {
    const url = `${this.ENDPOINT}/outstanding-income-report`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
}

export default new OutStandingIncome();
