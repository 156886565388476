import request from "src/lib/axios/request";
import { ICityRecord } from "src/pages/city/city.model";
import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import { ICity } from "./city.model";

class CityService {
  ENDPOINT = config.baseApiMasters + "/city";

  public searchCityData = async (
    data: ICityRecord
  ): Promise<IApiResponse<ICity>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public createNewCity = async (
    data: ICityRecord
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public editCityById = async (
    data: ICityRecord
  ): Promise<IApiResponse<number>> => {
    const url = `${this.ENDPOINT}/${data.id}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public removeCityById = async (id: number) => {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };
}

export default new CityService();
