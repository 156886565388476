import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import {
  branchWiseOverdueReport,
  overdueIncomeData,
  schoolWiseStudentCount,
  schoolWiseStudentCountCsv,
  searchOverdueIncomeCsvEmailSendData,
} from "./overdueIncome.action";
import { IOverdueIncomeState } from "./overdueincome.model";

export const initialState: IOverdueIncomeState = {
  overdueIncomeData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      total_amount: "",
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  searchOverDueIncomeCsvEmailSendData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
    },
  },
  branchWiseOverdueReport: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  schoolWiseStudentCount: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      totalStudents: "",
    },
  },
  schoolWiseStudentCountCsvData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
    },
  },
};

export const overdueIncomeSlice = createSlice({
  name: "overdueIncome",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.searchOverDueIncomeCsvEmailSendData.message = "";
      state.overdueIncomeData.message = "";
      state.branchWiseOverdueReport.message = "";
      state.schoolWiseStudentCountCsvData.message = "";
    },
  },
  extraReducers: {
    //get commonmodule
    [overdueIncomeData.pending.type]: (state) => {
      state.overdueIncomeData.loading = true;
    },
    [overdueIncomeData.fulfilled.type]: (state, action) => {
      state.overdueIncomeData.data = action.payload.data;
      state.overdueIncomeData.message = action.payload.message;
      state.overdueIncomeData.loading = false;
      state.overdueIncomeData.hasErrors = false;
    },
    [overdueIncomeData.rejected.type]: (state, action) => {
      state.overdueIncomeData.loading = false;
      state.overdueIncomeData.hasErrors = true;
      state.overdueIncomeData.message = action.error.message;
    },

    // search outstanding - income data and download csv file
    [searchOverdueIncomeCsvEmailSendData.pending.type]: (state) => {
      state.searchOverDueIncomeCsvEmailSendData.loading = true;
    },
    [searchOverdueIncomeCsvEmailSendData.fulfilled.type]: (state, action) => {
      state.searchOverDueIncomeCsvEmailSendData.data = action.payload.data;
      state.searchOverDueIncomeCsvEmailSendData.message =
        action.payload.message;
      state.searchOverDueIncomeCsvEmailSendData.loading = false;
      state.searchOverDueIncomeCsvEmailSendData.hasErrors = false;
    },
    [searchOverdueIncomeCsvEmailSendData.rejected.type]: (state, action) => {
      state.searchOverDueIncomeCsvEmailSendData.loading = false;
      state.searchOverDueIncomeCsvEmailSendData.hasErrors = true;
      state.searchOverDueIncomeCsvEmailSendData.message = action.error.message;
    },

    // branch wise overdue report
    [branchWiseOverdueReport.pending.type]: (state) => {
      state.branchWiseOverdueReport.loading = true;
    },
    [branchWiseOverdueReport.fulfilled.type]: (state, action) => {
      state.branchWiseOverdueReport.data = action.payload.data;
      state.branchWiseOverdueReport.message = action.payload.message;
      state.branchWiseOverdueReport.loading = false;
      state.branchWiseOverdueReport.hasErrors = false;
    },
    [branchWiseOverdueReport.rejected.type]: (state, action) => {
      state.branchWiseOverdueReport.loading = false;
      state.branchWiseOverdueReport.hasErrors = true;
      state.branchWiseOverdueReport.message = action.error.message;
    },

    // schoolwise student count
    [schoolWiseStudentCount.pending.type]: (state) => {
      state.schoolWiseStudentCount.loading = true;
    },
    [schoolWiseStudentCount.fulfilled.type]: (state, action) => {
      state.schoolWiseStudentCount.data = action.payload.data;
      state.schoolWiseStudentCount.message = action.payload.message;
      state.schoolWiseStudentCount.loading = false;
      state.schoolWiseStudentCount.hasErrors = false;
    },
    [schoolWiseStudentCount.rejected.type]: (state, action) => {
      state.schoolWiseStudentCount.loading = false;
      state.schoolWiseStudentCount.hasErrors = true;
      state.schoolWiseStudentCount.message = action.error.message;
    },

     // schoolwise student count CSV file send email
    [schoolWiseStudentCountCsv.pending.type]: (state) => {
      state.schoolWiseStudentCountCsvData.loading = true;
    },
    [schoolWiseStudentCountCsv.fulfilled.type]: (state, action) => {
      state.schoolWiseStudentCountCsvData.data = action.payload.data;
      state.schoolWiseStudentCountCsvData.message = action.payload.message;
      state.schoolWiseStudentCountCsvData.loading = false;
      state.schoolWiseStudentCountCsvData.hasErrors = false;
    },
    [schoolWiseStudentCountCsv.rejected.type]: (state, action) => {
      state.schoolWiseStudentCountCsvData.loading = false;
      state.schoolWiseStudentCountCsvData.hasErrors = true;
      state.schoolWiseStudentCountCsvData.message = action.error.message;
    },
  },
});

// A selector
export const overdueIncomeSelector = (state: RootState) => state.overdueIncome;

export const { clearRemoveMessage } = overdueIncomeSlice.actions;

// The reducer
export default overdueIncomeSlice.reducer;
