import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import {
  createNewExpense,
  editExpenseById,
  expenseCsvMail,
  removeExpenseById,
  searchExpenseData,
  updateExpenseStatus,
} from "./expense.action";
import { IExpenseState } from "./expense.model";

export const initialState: IExpenseState = {
  expensesData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      total_amount: 0,
    },
  },
  ExpensesCsvFile: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
    },
  },
  removeById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  updateById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  createExpense: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  editById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
};

export const expenseSlice = createSlice({
  name: "expense",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.removeById.message = "";
      state.updateById.message = "";
      state.editById.message = "";
      state.createExpense.message = "";
      state.ExpensesCsvFile.message = "";
    },
  },
  extraReducers: {
    //get expense
    [searchExpenseData.pending.type]: (state) => {
      state.expensesData.loading = true;
    },
    [searchExpenseData.fulfilled.type]: (state, action) => {
      state.expensesData.data = action.payload.data;
      state.expensesData.message = action.payload.message;
      state.expensesData.loading = false;
      state.expensesData.hasErrors = false;
    },
    [searchExpenseData.rejected.type]: (state, action) => {
      state.expensesData.loading = false;
      state.expensesData.hasErrors = true;
      state.expensesData.message = action.error.message;
    },
    //Delete expense
    [removeExpenseById.pending.type]: (state) => {
      state.removeById.loading = true;
    },
    [removeExpenseById.fulfilled.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = false;
      state.removeById.message = action.payload.message;
    },
    [removeExpenseById.rejected.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = true;
      state.removeById.message = action.error.message;
    },
    //update expense
    [updateExpenseStatus.pending.type]: (state) => {
      state.updateById.loading = true;
    },
    [updateExpenseStatus.fulfilled.type]: (state, action) => {
      state.updateById.message = action.payload.message;
      state.updateById.loading = false;
      state.updateById.hasErrors = false;
    },
    [updateExpenseStatus.rejected.type]: (state, action) => {
      state.updateById.loading = false;
      state.updateById.hasErrors = true;
      state.updateById.message = action.error.message;
    },

    //create expense
    [createNewExpense.pending.type]: (state) => {
      state.createExpense.loading = true;
    },
    [createNewExpense.fulfilled.type]: (state, action) => {
      state.createExpense.message = action.payload.message;
      state.createExpense.hasErrors = action.payload.error;
      state.createExpense.loading = false;
      state.createExpense.hasErrors = false;
    },
    [createNewExpense.rejected.type]: (state, action) => {
      state.createExpense.loading = false;
      state.createExpense.hasErrors = true;
      state.createExpense.message = action.error.message;
    },

    //edit expense
    [editExpenseById.pending.type]: (state) => {
      state.editById.loading = true;
    },
    [editExpenseById.fulfilled.type]: (state, action) => {
      state.editById.message = action.payload.message;
      state.editById.loading = false;
      state.editById.hasErrors = false;
    },
    [editExpenseById.rejected.type]: (state, action) => {
      state.editById.loading = false;
      state.editById.hasErrors = true;
      state.editById.message = action.error.message;
    },

    //get expense-csv
    [expenseCsvMail.pending.type]: (state) => {
      state.ExpensesCsvFile.loading = true;
    },
    [expenseCsvMail.fulfilled.type]: (state, action) => {
      state.ExpensesCsvFile.data = action.payload.data;
      state.ExpensesCsvFile.message = action.payload.message;
      state.ExpensesCsvFile.loading = false;
      state.ExpensesCsvFile.hasErrors = false;
    },
    [expenseCsvMail.rejected.type]: (state, action) => {
      state.ExpensesCsvFile.loading = false;
      state.ExpensesCsvFile.hasErrors = true;
      state.ExpensesCsvFile.message = action.error.message;
    },
  },
});

// A selector
export const expenseSelector = (state: RootState) => state.expense;

export const { clearRemoveMessage } = expenseSlice.actions;

// The reducer
export default expenseSlice.reducer;
