import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IAssignQuestionBank,
  IUpdateAssignQuestionBank,
} from "./assignQuestionBank.model";
import assignQuestionBankService from "../../services/assignQuestionBank/assignQuestionBank.service";

export const assignQuestionBank = createAsyncThunk(
  "assign-question-bank/assignQuestionBank",
  async (data: IAssignQuestionBank) => {
    return assignQuestionBankService.assignQuestionBank(data);
  }
);

export const updateAssignQuestionBank = createAsyncThunk(
  "assign-question-bank/updateAssignQuestionBank",
  async (data: IUpdateAssignQuestionBank) => {
    return assignQuestionBankService.updateAssignQuestionBank(data);
  }
);

export const removeAssignQuestionBank = createAsyncThunk(
  "assign-question-bank/removeAssignQuestionBank",
  async (id: number) => {
    return assignQuestionBankService.removeAssignQuestionBank(id);
  }
);
