import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { IPaymentModeState } from "./paymentMode.model";
import { searchPaymentModeData } from "./paymentMode.action";

export const initialState: IPaymentModeState = {
  paymentModeData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      count: 0,
    },
  },
};

export const paymentModeSlice = createSlice({
  name: "source",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.paymentModeData.message = "";
    },
  },
  extraReducers: {
    [searchPaymentModeData.pending.type]: (state) => {
      state.paymentModeData.loading = true;
    },
    [searchPaymentModeData.fulfilled.type]: (state, action) => {
      state.paymentModeData.data = action.payload.data;
      state.paymentModeData.message = action.payload.message;
      state.paymentModeData.loading = false;
      state.paymentModeData.hasErrors = false;
    },
    [searchPaymentModeData.rejected.type]: (state, action) => {
      state.paymentModeData.loading = false;
      state.paymentModeData.hasErrors = true;
      state.paymentModeData.message = action.error.message;
    },
  },
});

// A selector
export const paymentModeSelector = (state: RootState) => state.paymentMode;

export const { clearRemoveMessage } = paymentModeSlice.actions;

// The reducer
export default paymentModeSlice.reducer;
