import { Button, Col, Row, Select, Skeleton, Form, message, Popover, Modal, Card } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { SendOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import ContainerHeader from "src/components/ContainerHeader";
import {
  getCRMCounsellor,
  getCRMLeadEnquiries,
  pushCRMLeadEnquirie,
  removeEnquirieById,
} from "src/state/admission/admission.action";
import {
  admissionSelector,
  clearRemoveMessage,
} from "src/state/admission/admission.reducer";
import { useAppSelector } from "src/state/app.hooks";
import { AppDispatch } from "src/state/app.model";
import { Common, CRM_FIRST_NAMES, CRM_LEAD_ENQUIRIES_VIEW, CrmLeadStatus, ZoneType } from "src/utils/constants/constant";
import { branchSelector } from "src/state/branch/branch.reducer";
import { searchBranchData } from "src/state/branch/branch.action";
import { ability } from "src/ability";
import { ICRMLeadEnquiries, ILeadEnquiries } from "src/services/admission/admission.model";
import { dateFormate } from "src/utils/helperFunction";
import { ICRMEnquirieRemove } from "./CRMLeadEnquiries.model";
import { Can } from "src/ability/can";
import DeleteButtonWithConfirmComponent from "src/components/DeleteButtonWithConfirm";
import { searchUserData } from "src/state/users/user.action";
import { userSelector } from "src/state/users/user.reducer";
import TableComponent from "src/components/DataTable";
import { useSearchParams } from "react-router-dom";

const { Option } = Select;
const CRMLeadEnquiries = () => {
  const admissionState = useAppSelector(admissionSelector);
  const branchState = useAppSelector(branchSelector);
  const [form] = Form.useForm();
  const [selectedValues, setSelectedValues] = useState<{ [key: number]: string }>({});
  const { userData } = useAppSelector(userSelector);
  const userState = useAppSelector(userSelector);
  const storageID: string | null = localStorage.getItem("myStorageID");
  const currentUserZone = userData.data.user_roles.find(
    (role) => role.id === Number(storageID)
  )?.zone;
  const checkIsZoneOnly = currentUserZone?.type === ZoneType.PUBLIC ? false : true;
  const validTypes = ["SALES_MANAGER", "FACULTY_HEAD"];
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const handleSelectChange = (value: string, id: number) => {
    setSelectedValues(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const isShowLesdEnquiries = ability.can(
    Common.Actions.CAN_FULL_VIEW,
    Common.Modules.ADMISSION.ADMISSION_CRM_LEAD_ENQUIRIES
  )

  const info = (filteredLeadEnquiries: any) => {
    const leadData = Object.keys(filteredLeadEnquiries);
    Modal.info({
      title: "Lead Details",
      content: (
        <ul className="remark-message fixHeight-model crm-lead-info-ul">
          {leadData.map((enquiries: string | number, index: number) => {
            return (
              <li className="crm-lead-info-li" key={index}>
                <span className="crm-lead-info-font">{enquiries}:</span> {filteredLeadEnquiries[enquiries]}
              </li>
            )
          })}
        </ul>
      ),
      onOk() { },
    });
  };

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getCRMLeadEnquiries(searchParams)).then(() => {
      setLoading(false);
    });
  }, [searchParams])

  useEffect(() => {
    dispatch(
      getCRMCounsellor({
        accessKey: process.env.REACT_APP_CRM_GET_COUNSELLOR_API_ACCESS_KEY,
        secretKey: process.env.REACT_APP_CRM_GET_COUNSELLOR_API_CECRET_KEY,
      })
    );

    dispatch(
      searchBranchData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        isZoneOnly: true,
      })
    );

    dispatch(
      searchUserData({ noLimit: true, orderBy: "first_name", order: "ASC", isZoneOnly: checkIsZoneOnly, role_type: validTypes })
    );
  }, []);

  useEffect(() => {
    if (admissionState.pushedCrmLeadEnquirie.message) {
      if (admissionState.pushedCrmLeadEnquirie.hasErrors) {
        message.error(admissionState.pushedCrmLeadEnquirie.message);
      } else {
        message.success(admissionState.pushedCrmLeadEnquirie.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [admissionState.pushedCrmLeadEnquirie.message]);

  const branchCode = branchState.branchesData.data?.rows.map(
    (branchCode) => branchCode.code.toLowerCase()
  );

  const crmLeadPush = (id: number, counsellorId: number) => {
    const [counsellorData] = userState.usersData.data.rows.filter((user) => (user.id === counsellorId))

    if (counsellorData) {
      dispatch(
        pushCRMLeadEnquirie({
          id,
          lead_status: CrmLeadStatus.PUSHED,
          crm_user_info: counsellorData.email,
        })
      ).then((res) => {
        if (res?.payload) {
          dispatch(getCRMLeadEnquiries({searchParams}));
        }
      });
    }
  };


  const handledelete = (id: number) => {
    dispatch(removeEnquirieById(id)).then((res) => {
      const result = res as ICRMEnquirieRemove;
      if (result?.payload) {
        const searchData = Object.fromEntries(new URLSearchParams(searchParams));
        const { take, skip, ...rest } = searchData;
        admissionState.crmLeadEnquiries.data && admissionState.crmLeadEnquiries.data.rows.length === 1
          ? setSearchParams({ take, ...rest })
          : setSearchParams(searchData);
        dispatch(getCRMLeadEnquiries(searchData));
        message.success(result?.payload?.message);
      } else if (result?.error) {
        message.error(result?.error?.message);
      }
    });
  }

  const leadDetailColumns: ColumnsType<ICRMLeadEnquiries> = [
    {
      title: "Branch Code",
      dataIndex: "branch",
      render: (_, record) => {
        return <span>{record.branch.code}</span>;
      },
    },
    {
      title: "Lead Details",
      render: (_, record) => {
        const leadDetail = JSON.parse(record.lead_details) || [];
        const leadData = Object.keys(leadDetail);
        const filteredLeadEnquiries = leadData.filter((enquiries: string) =>
          isShowLesdEnquiries ? true : CRM_LEAD_ENQUIRIES_VIEW.includes(enquiries))

        return (
          <div>
            <ul className="crm-history-list-main">
              {filteredLeadEnquiries.slice(0, 5).map((enquiry: string, index: number) => (
                <li key={index} className="gx-d-flex">
                  {enquiry}: {leadDetail[enquiry]}
                  {filteredLeadEnquiries.length > 5 && index === 4 && (
                    <div className="view-remarks gx-ml-3">
                      <Button onClick={() => info(leadDetail)}>More</Button>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      sorter: true,
      align: "center",
      render: (record) => {
        return <>{dateFormate(record)}</>;
      },
    },
    {
      title: "Counsellor",
      dataIndex: "counsellor",
      align: "center",
      width: "25%",
      className: "counsellor-select-input",
      render: (_, record, index) => {
        return (
          <>
            <Form.Item name={record.id}>
              <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                allowClear
                showSearch
                size="large"
                placeholder="Select Counsellor"
                filterOption={(input, option) =>
                  (option?.children?.toString() || "")
                    .toLowerCase()
                    .includes(input.toLowerCase().trim())
                }
                onChange={(value) => handleSelectChange(value, record.id)}
              >
                {
                  userState.usersData.data.rows.map((data) => {
                    return (
                      <Option value={data.id} key={data.id}>
                        {`${data.first_name}(${data.email})`}
                      </Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (record) => {
        return (
          <>
            <div className="crm-push-btn-mb">
              <Button
                className="gx-px-3"
                key="submit"
                icon={<SendOutlined />}
                type="primary"
                form="myForm"
                disabled={!selectedValues[record.id]}
                onClick={() => {
                  const counsellorId = form.getFieldValue(record.id);
                  crmLeadPush(record.id, counsellorId);
                }}
              >
                Push
              </Button>
              <Can
                I={Common.Actions.CAN_DELETE}
                a={Common.Modules.ADMISSION.ADMISSION_CRM_LEAD_ENQUIRIES}
              >
                <DeleteButtonWithConfirmComponent
                  title={"Are you sure want to delete enquiries?"}
                  okText={"YES"}
                  cancelText={"NO"}
                  onDelete={() => handledelete(record.id)}
                  children={"Delete"}
                />
              </Can>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <Skeleton loading={loading} active avatar>
      <div>
        <Row gutter={24}>
          <Col xs={24} className="gx-mb-3">
            <ContainerHeader title="CRM Lead Enquiries" />
          </Col>
        </Row>
      </div>
      <div className="crm-lead-enquiries-table">
        <Form id="myForm" name="basic" autoComplete="off" form={form}>
          <Card className="rnw-card course table-card courses gx-mb-0">
            <TableComponent
              columns={isShowLesdEnquiries ? leadDetailColumns : leadDetailColumns?.slice(1)}
              dataSource={admissionState.crmLeadEnquiries.data.rows || [] || []}
              meta={admissionState.crmLeadEnquiries.data.meta}
              loading={loading}
            />
          </Card>
        </Form>
      </div>
    </Skeleton>
  );
};

export default CRMLeadEnquiries;
