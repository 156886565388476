import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { ITagState } from "./tags.model";
import { getTags } from "./tags.action";

export const initialState: ITagState = {
  getTag: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
};

export const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {},
  },
  extraReducers: {
    // zoneDelete
    [getTags.pending.type]: (state) => {
      state.getTag.loading = true;
    },
    [getTags.fulfilled.type]: (state, action) => {
      state.getTag.loading = false;
      state.getTag.hasErrors = false;
      state.getTag.message = action.payload.message;
      state.getTag.data = action.payload.data
    },
    [getTags.rejected.type]: (state, action) => {
      state.getTag.loading = false;
      state.getTag.hasErrors = true;
      state.getTag.message = action.error.message;
    },
  },
});

// A selector
export const tagSelector = (state: RootState) => state.tag;

export const { clearRemoveMessage } = tagSlice.actions;

// The reducer
export default tagSlice.reducer;
