import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import {
  searchPackageData,
  createNewpackage,
  editPackageById,
  removePackageById,
  updatePackageStatus,
  commonCourse,
  getPackageById,
} from "./package.action";

import { IPackageState } from "./package.model";

export const initialState: IPackageState = {
  packageData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  package: {
    loading: false,
    hasErrors: false,
    message: "",
    data:  null,
  },
  createPackages: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  commonCourseData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
    },
  },
  editById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  removeById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  updateById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
};

export const packageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.removeById.message = "";
      state.updateById.message = "";
      state.editById.message = "";
      state.createPackages.message = "";
    },
  },
  extraReducers: {
    //get package
    [searchPackageData.pending.type]: (state) => {
      state.packageData.loading = true;
    },
    [searchPackageData.fulfilled.type]: (state, action) => {
      state.packageData.data = action.payload.data;
      state.packageData.message = action.payload.message;
      state.packageData.loading = false;
      state.packageData.hasErrors = false;
    },
    [searchPackageData.rejected.type]: (state, action) => {
      state.packageData.loading = false;
      state.packageData.hasErrors = true;
      state.packageData.message = action.error.message;
    },

    //create package
    [createNewpackage.pending.type]: (state) => {
      state.createPackages.loading = true;
    },
    [createNewpackage.fulfilled.type]: (state, action) => {
      state.createPackages.message = action.payload.message;
      state.createPackages.hasErrors = action.payload.error;
      state.createPackages.loading = false;
      state.createPackages.hasErrors = false;
    },
    [createNewpackage.rejected.type]: (state, action) => {
      state.createPackages.loading = false;
      state.createPackages.hasErrors = true;
      state.createPackages.message = action.error.message;
    },

    //common course
    [commonCourse.pending.type]: (state) => {
      state.commonCourseData.loading = true;
    },
    [commonCourse.fulfilled.type]: (state, action) => {
      state.commonCourseData.data = action.payload.data;
      state.commonCourseData.message = action.payload.message;
      state.commonCourseData.loading = false;
      state.commonCourseData.hasErrors = false;
    },
    [commonCourse.rejected.type]: (state, action) => {
      state.commonCourseData.loading = false;
      state.commonCourseData.hasErrors = true;
      state.commonCourseData.message = action.error.message;
    },

    //get package
    [getPackageById.pending.type]: (state) => {
      state.package.loading = true;
    },
    [getPackageById.fulfilled.type]: (state, action) => {
      state.package.message = action.payload.message;
      state.package.loading = false;
      state.package.hasErrors = false;
    },
    [getPackageById.rejected.type]: (state, action) => {
      state.package.loading = false;
      state.package.hasErrors = true;
      state.package.message = action.error.message;
    },

    //edit package
    [editPackageById.pending.type]: (state) => {
      state.editById.loading = true;
    },
    [editPackageById.fulfilled.type]: (state, action) => {
      state.editById.message = action.payload.message;
      state.editById.loading = false;
      state.editById.hasErrors = false;
    },
    [editPackageById.rejected.type]: (state, action) => {
      state.editById.loading = false;
      state.editById.hasErrors = true;
      state.editById.message = action.error.message;
    },

    //update package
    [updatePackageStatus.pending.type]: (state) => {
      state.updateById.loading = true;
    },
    [updatePackageStatus.fulfilled.type]: (state, action) => {
      state.updateById.message = action.payload.message;
      state.updateById.loading = false;
      state.updateById.hasErrors = false;
    },
    [updatePackageStatus.rejected.type]: (state, action) => {
      state.updateById.loading = false;
      state.updateById.hasErrors = true;
      state.updateById.message = action.error.message;
    },

    //Delete package
    [removePackageById.pending.type]: (state) => {
      state.removeById.loading = true;
    },
    [removePackageById.fulfilled.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = false;
      state.removeById.message = action.payload.message;
    },
    [removePackageById.rejected.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = true;
      state.removeById.message = action.error.message;
    },
  },
});

// A selector
export const packageSelector = (state: RootState) => state.package;

export const { clearRemoveMessage } = packageSlice.actions;

// The reducer
export default packageSlice.reducer;
