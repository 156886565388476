import { createAsyncThunk } from "@reduxjs/toolkit";
import { IExpenseMasterRecord } from "src/pages/ExpenseMaster/ExpenseMaster.model";
import {
  ExpenseMasterRecord,
  IExpenseMasterStatus,
} from "src/services/expenseMaster/expenseMaster.model";
import expenseMasterService from "src/services/expenseMaster/expenseMaster.service";

export const searchExpenseMasterData = createAsyncThunk(
  "expense-master/searchExpenseMasterData",
  async (data: {} | URLSearchParams) => {
    return expenseMasterService.searchExpenseMasterData(data);
  }
);

export const createNewExpenseMaster = createAsyncThunk(
  "expense-master/createNewExpenseMaster",
  async (data: IExpenseMasterRecord) => {
    return expenseMasterService.createExpenseMasterData(data);
  }
);

export const editExpenseMasterById = createAsyncThunk(
  "expense-master/editExpenseMasterById",
  async (data: IExpenseMasterRecord) => {
    return expenseMasterService.editExpenseMasterById(data);
  }
);

export const removeExpenseMasterById = createAsyncThunk(
  "expense-master/removeExpenseMasterById",
  async (id: number) => {
    return expenseMasterService.removeExpenseMasterById(id);
  }
);

export const updateExpenseMasterStatus = createAsyncThunk(
  "expense-master/updateExpenseMasterStatus",
  async (data: IExpenseMasterStatus) => {
    return expenseMasterService.updateExpenseMasterStatus(data);
  }
);
