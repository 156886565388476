import { config } from "src/utils";
import request from "../../lib/axios/request";
import { ISubdepartmentRecord } from "../../pages/SubDepartment/Subdepartment.model";
import { IApiResponse } from "../../utils/models/common";
import { ISubDepartmentDetails, ISubDepartmentStatus } from "./subDepartment.model";

class SubDepartmentService {
  ENDPOINT = config.baseApiMasters+ "/subdepartments";

  public removeSubDepartmentById = async (id: number) => {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data
    });
  };

  public updateSubDepartmentStatus = async (
    data: ISubDepartmentStatus
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}/status`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

  public editSubDepartmentById = async (
    data: ISubdepartmentRecord
  ): Promise<IApiResponse<any>> => {
    const url = `${this.ENDPOINT}/${data.id}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public createNewSubDepartment = async (
    data: ISubdepartmentRecord
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public searchSubDepartmentData = async (
    data: ISubDepartmentDetails
  ): Promise<IApiResponse<ISubDepartmentDetails[]>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params:data}).then((res) => {
      return res.data;
    });
  };
}

export default new SubDepartmentService();