import { config } from "src/utils";
import request from "../../lib/axios/request";
import { IHoliday, IHolidayDetails } from "./Holiday.model";
import { IApiResponse } from "src/utils/models/common";

class HolidayService {
  ENDPOINT = config.baseApiMasters + "/holiday";

  public removeHolidayById = async (id: number) => {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public editHolidayById = async (
    data: IHolidayDetails
  ): Promise<IApiResponse<IHolidayDetails>> => {
    const url = `${this.ENDPOINT}/${data.id}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public createNewHoliday = async (
    data: IHolidayDetails
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public searchHolidayData = async (
    data: IHolidayDetails | URLSearchParams
  ): Promise<IApiResponse<IHoliday[]>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public searchAllHolidayData = async (
    data: IHolidayDetails | URLSearchParams
  ): Promise<IApiResponse<IHoliday[]>> => {
    const url = `${this.ENDPOINT}/get-all-holidays`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
}
export default new HolidayService();
