import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import {
  createNewCategory,
  editCategoryById,
  removeCategoryById,
  searchCategoryData,
  updateCategoryStatus,
} from "./category.action";
import { ICategoryState } from "./category.model";

export const initialState: ICategoryState = {
  removeById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  updateById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  editById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  createCategory: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  categoryData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.removeById.message = "";
      state.updateById.message = "";
      state.editById.message = "";
      state.createCategory.message = "";
    },
  },
  extraReducers: {

    [removeCategoryById.pending.type]: (state) => {
      state.removeById.loading = true;
    },
    [removeCategoryById.fulfilled.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = false;
      state.removeById.message = action.payload.message;
    },
    [removeCategoryById.rejected.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = true;
      state.removeById.message = action.error.message;
    },

    [updateCategoryStatus.pending.type]: (state) => {
      state.updateById.loading = true;
    },
    [updateCategoryStatus.fulfilled.type]: (state, action) => {
      state.updateById.message = action.payload.message;
      state.updateById.loading = false;
      state.updateById.hasErrors = false;
    },
    [updateCategoryStatus.rejected.type]: (state, action) => {
      state.updateById.loading = false;
      state.updateById.hasErrors = true;
      state.updateById.message = action.error.message;
    },

    [editCategoryById.pending.type]: (state) => {
      state.editById.loading = true;
    },
    [editCategoryById.fulfilled.type]: (state, action) => {
      state.editById.message = action.payload.message;
      state.editById.loading = false;
      state.editById.hasErrors = false;
    },
    [editCategoryById.rejected.type]: (state, action) => {
      state.editById.loading = false;
      state.editById.hasErrors = true;
      state.editById.message = action.error.message;
    },

    [createNewCategory.pending.type]: (state) => {
      state.createCategory.loading = true;
    },
    [createNewCategory.fulfilled.type]: (state, action) => {
      state.createCategory.message = action.payload.message;
      state.createCategory.hasErrors = action.payload.error;
      state.createCategory.loading = false;
      state.createCategory.hasErrors = false;

    },
    [createNewCategory.rejected.type]: (state, action) => {
      state.createCategory.loading = false;
      state.createCategory.hasErrors = true;
      state.createCategory.message = action.error.message;
    },

    [searchCategoryData.pending.type]: (state) => {
      state.categoryData.loading = true;
    },
    [searchCategoryData.fulfilled.type]: (state, action) => {
      state.categoryData.data = action.payload.data;
      state.categoryData.message = action.payload.message;
      state.categoryData.loading = false;
      state.categoryData.hasErrors = false;
    },
    [searchCategoryData.rejected.type]: (state, action) => {
      state.categoryData.loading = false;
      state.categoryData.hasErrors = true;
      state.categoryData.message = action.error.message;
    },
  },
});

// A selector
export const categorySelector = (state: RootState) => state.category;

export const { clearRemoveMessage } = categorySlice.actions;

// The reducer
export default categorySlice.reducer;
