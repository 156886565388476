import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import {
  searchBatchData,
  createNewBatch,
  editBatchById,
  removeBatchById,
  updateBatchStatus,
} from "./batch.action";

import { IBatchState } from "./batch.model";

export const initialState: IBatchState = {
  batchData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  createBatches: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  editById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  removeById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  updateById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
};

export const batchSlice = createSlice({
  name: "batch",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.removeById.message = "";
      state.updateById.message = "";
      state.editById.message = "";
      state.createBatches.message = "";
    },
  },
  extraReducers: {
    //get batch
    [searchBatchData.pending.type]: (state) => {
      state.batchData.loading = true;
    },
    [searchBatchData.fulfilled.type]: (state, action) => {
      state.batchData.data = action.payload.data;
      state.batchData.message = action.payload.message;
      state.batchData.loading = false;
      state.batchData.hasErrors = false;
    },
    [searchBatchData.rejected.type]: (state, action) => {
      state.batchData.loading = false;
      state.batchData.hasErrors = true;
      state.batchData.message = action.error.message;
    },

    //create batch
    [createNewBatch.pending.type]: (state) => {
      state.createBatches.loading = true;
    },
    [createNewBatch.fulfilled.type]: (state, action) => {
      state.createBatches.message = action.payload.message;
      state.createBatches.hasErrors = action.payload.error;
      state.createBatches.loading = false;
      state.createBatches.hasErrors = false;
    },
    [createNewBatch.rejected.type]: (state, action) => {
      state.createBatches.loading = false;
      state.createBatches.hasErrors = true;
      state.createBatches.message = action.error.message;
    },

    //edit batch
    [editBatchById.pending.type]: (state) => {
      state.editById.loading = true;
    },
    [editBatchById.fulfilled.type]: (state, action) => {
      state.editById.message = action.payload.message;
      state.editById.loading = false;
      state.editById.hasErrors = false;
    },
    [editBatchById.rejected.type]: (state, action) => {
      state.editById.loading = false;
      state.editById.hasErrors = true;
      state.editById.message = action.error.message;
    },

    //update batch
    [updateBatchStatus.pending.type]: (state) => {
      state.updateById.loading = true;
    },
    [updateBatchStatus.fulfilled.type]: (state, action) => {
      state.updateById.message = action.payload.message;
      state.updateById.loading = false;
      state.updateById.hasErrors = false;
    },
    [updateBatchStatus.rejected.type]: (state, action) => {
      state.updateById.loading = false;
      state.updateById.hasErrors = true;
      state.updateById.message = action.error.message;
    },

    //Delete batch
    [removeBatchById.pending.type]: (state) => {
      state.removeById.loading = true;
    },
    [removeBatchById.fulfilled.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = false;
      state.removeById.message = action.payload.message;
    },
    [removeBatchById.rejected.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = true;
      state.removeById.message = action.error.message;
    },
  },
});

// A selector
export const batchSelector = (state: RootState) => state.batch;

export const { clearRemoveMessage } = batchSlice.actions;

// The reducer
export default batchSlice.reducer;
