import { createAsyncThunk } from "@reduxjs/toolkit";
import { ISubdepartmentRecord } from "../../pages/SubDepartment/Subdepartment.model";
import { ISubDepartmentStatus } from "../../services/subDepartment/subDepartment.model";
import SubDepartmentService from "../../services/subDepartment/subDepartment.service";

export const removeSubDepartmentById = createAsyncThunk(
  "subDepartment/removeSubDepartmentById",
  async (id: number) => {
    return SubDepartmentService.removeSubDepartmentById(id);
  }
);

export const updateSubDepartmentStatus = createAsyncThunk(
  "subDepartment/updateSubDepartmentStatus",
  async (data: ISubDepartmentStatus) => {
    return SubDepartmentService.updateSubDepartmentStatus(data);
  }
);

export const editSubDepartmentById = createAsyncThunk(
  "subDepartment/editSubDepartmentById",
  async (data: ISubdepartmentRecord) => {
    return SubDepartmentService.editSubDepartmentById(data);
  }
);

export const createNewSubDepartment = createAsyncThunk(
  "subDepartment/createNewSubDepartment",
  async (data: ISubdepartmentRecord) => {
    return SubDepartmentService.createNewSubDepartment(data);
  }
);

export const searchSubDepartmentData = createAsyncThunk(
  "subDepartment/searchSubDepartmentData",
  async (data: any) => {
    return SubDepartmentService.searchSubDepartmentData(data);
  }
);
