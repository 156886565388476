import { config } from "src/utils";
import request from "../../lib/axios/request";
import { IApiResponse } from "../../utils/models/common";
import { ICreateExamResult, IPublishStudentResult } from "./ExamResult.model";

class ExamResultService {
  ENDPOINT = config.baseApiBatch + "/batch-exam-result";

  public createExamResult = async (
    data: ICreateExamResult | URLSearchParams | {}
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/exams-result`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public getListingResult = async (
    data: URLSearchParams | {}
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/listing-results`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
 
  public publishResult = async (
    data: IPublishStudentResult
  ): Promise<IApiResponse<IPublishStudentResult>> => {
    const url = `${this.ENDPOINT}/update-status`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };
}

export default new ExamResultService();
