import { ISubCourseTopicRecord } from "src/pages/SubCourseTopic/SubCourseTopic.model";
import { config } from "src/utils";
import request from "../../lib/axios/request";
import { IApiResponse } from "../../utils/models/common";
import {
  ISubCourseTopic,
  ISubCourseTopicDetails,
  ISubCourseTopicStatus,
  ISubCourseSubTopicStatus,
  ISubCourseTopicCsv,
} from "./subCourseTopic.model";

class SubCourseTopicService {
  ENDPOINT = config.baseApiMasters + "/sub-course";

  public searchSubCourseTopicData = async (
    data: any
  ): Promise<IApiResponse<ISubCourseTopic>> => {
    const url = `${this.ENDPOINT}/${data.subcourse_id}/topics`;
    return request({
      url,
      method: "GET",
      params: data.searchParams ? data.searchParams : data,
    }).then((res) => {
      return res.data;
    });
  };

  public createNewSubCourseTopic = async (
    data: ISubCourseTopicDetails
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.subcourse_id}/topics`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public uploadSubCourseTopicCsvFile = async (
    newData: ISubCourseTopicCsv
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${newData.id}/bulk`;
    return request({ url, method: "POST", data: newData.body }).then((res) => {
      return res.data;
    });
  };

  public editSubCourseTopicById = async (
    data: ISubCourseTopicRecord
  ): Promise<IApiResponse<any>> => {
    const url = `${this.ENDPOINT}/${data.subcourse_id}/${data.id}/topics`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public removeSubCourseTopicById = async (id: number) => {
    const url = `${this.ENDPOINT}/sub-course-topics/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public updateSubCourseTopicStatus = async (
    data: ISubCourseTopicStatus
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.subcourse_id}/${data.id}/status`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

  public updateSubCourseSubTopicStatus = async (
    data: ISubCourseSubTopicStatus
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/sub-topic/${data.id}/status`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

  public removeMultipleTopic = async (data: { id: [] }) => {
    const url = `${this.ENDPOINT}/sub-course-topics/multiple-delete`;
    return request({ url, method: "DELETE", data }).then((res) => {
      return res.data;
    });
  };
}

export default new SubCourseTopicService();
