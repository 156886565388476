import request from "../../lib/axios/request";
import { IApiResponse } from "../../utils/models/common";
import { config } from "src/utils";
import { ISource } from "./source.model";

class SourceService {
  ENDPOINT = config.baseApiMasters+ "/common/source";
    public searchSourceData = async (
        data: any
      ): Promise<IApiResponse<ISource>> => {
        const url = `${this.ENDPOINT}`;
        return request({ url, method: "GET", params: data }).then((res) => {
          return res.data;
        });
      };
}
export default new SourceService();
