import request from "src/lib/axios/request";
import { IBatchAssignedStudentCourseStatus } from "src/pages/UserWiseBatch/BatchWiseStudentDetails.model";
import {
  IFacultyBatch,
  IFacultyStudent,
  IStudentByBatch,
} from "src/state/userBatch/userBatch.model";
import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import { IAdmissionStatus } from "../admission/admission.model";
import {
  BatchWiseStudentCompleted,
  IBulkAssignBatch,
  ICourseWiseStudentsRecord,
  IFacultyStudentAttendenceData,
  IMergeBatch,
  ISessionAttendenceData,
  IUpdateBatchShiningSheetTopicNoteData,
  IUserBatchData,
  IUserBatchRecord,
} from "./userBatch.model";
import {
  CreateBatchSigningSheetTopic,
  batch_faculty_student_attendances,
} from "src/pages/UserWiseBatch/UserWiseBatch.model";
import { IMyTeamBatchesData } from "../myteambatches/myTeamBatches.modal";
import { IStudentRecord } from "src/pages/MyTeamBatches/MyTeamBatches.model";
import { IHoldBatchPayload } from "../batch/batch.model";

class UserBatchService {
  ENDPOINT = config.baseApiBatch + "/batches";

  public searchUserBatchData = async (
    data: IUserBatchData | URLSearchParams | { noLimit: boolean }
  ): Promise<IApiResponse<IUserBatchData>> => {
    const entries = Object.entries(data);
    const filteredEntries = entries.filter(([key, value]) => value !== "");
    const newData = Object.fromEntries(filteredEntries);
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: newData }).then((res) => {
      return res.data;
    });
  };

  public searchMyTeamBatchData = async (
    data: IMyTeamBatchesData | URLSearchParams | { noLimit: boolean }
  ): Promise<IApiResponse<IMyTeamBatchesData>> => {
    const url = `${this.ENDPOINT}/team-batch-list`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public countBatchData = async (
    data: IAdmissionStatus | URLSearchParams | {}
  ): Promise<IApiResponse<IAdmissionStatus>> => {
    const url = `${this.ENDPOINT}/status-count`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public batchStartByStatus = async (
    data: IUserBatchRecord
  ): Promise<IApiResponse<any>> => {
    const url = `${this.ENDPOINT}/${data.id}/batch-start`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public batchSessionAttendence = async (
    data: ISessionAttendenceData
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/session-attendance`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public getShiningSheetTopicByBatch = async (
    data: IStudentByBatch
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data?.batch_id}/shining-sheet-topic`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public getBatchFacultyAttendance = async (
    data: IFacultyBatch
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data?.batch_shining_sheet_id}/batch-faculty-attendance`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
  public getBatchStudentAttendance = async (
    data: IFacultyStudent
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data?.admission_id}/get-batch-student-attendance`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public facultyStudentAttendence = async (
    data: batch_faculty_student_attendances
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/faculty-student-attendance`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public batchAssignedStudentCourseStatusAutoChange = async (
    data: IBatchAssignedStudentCourseStatus
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}/batch-assigned-student-course-auto-change`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public getBatchWiseStudentDetails = async (
    data: any
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.batch_id}/batch-wise-student-list`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public getBatchWiseStudentPresentAbsentDetails = async (
    data: IStudentRecord
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.batch_faculty_attendance_id}/present-absent/student-details`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public addNewTopicInSigningSheet = async (
    data: CreateBatchSigningSheetTopic
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/add-singing-sheet-topic`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public studentDetailsCsvEmailSendData = async (
    batch_id: number
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${batch_id}/student-details-report`;
    return request({ url, method: "GET", params: batch_id }).then((res) => {
      return res.data;
    });
  };

  public getBatchCompletedStudentAndNotCompletedStudentDetails = async (
    batch_id: number
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${batch_id}/batch-completed-student-details`;
    return request({ url, method: "GET", params: batch_id }).then((res) => {
      return res.data;
    });
  };

  public batchWiseStudentCompleted = async (
    data: BatchWiseStudentCompleted
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data?.batch_id}/batch-completed`;
    return request({
      url,
      method: "PUT",
      data: data,
    }).then((res) => {
      return res.data;
    });
  };

  public facultyStudentAttendancesUpdate = async (
    data: batch_faculty_student_attendances
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/faculty-student-attendance-update`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public facultyStudentAttendancesDelete = async (
    batch_faculty_attendance_id: number
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${batch_faculty_attendance_id}/faculty-student-attendance-deleted`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public reGenerateBatchSiningSheet = async (
    batch_id: number
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${batch_id}/re-generate-signing-sheet`;
    return request({ url, method: "POST" }).then((res) => {
      return res.data;
    });
  };

  public updateBatchShiningSheetTopicNote = async (
    data: IUpdateBatchShiningSheetTopicNoteData
  ): Promise<IApiResponse<any>> => {
    const url = `${this.ENDPOINT}/${data?.shiningSheetId}/update-batch-shining-sheet-notes`;
    const { shiningSheetId, ...rest } = data;
    return request({ url, method: "PATCH", data: rest }).then((res) => {
      return res.data;
    });
  };

  public mergeBatch = async (data: IMergeBatch): Promise<IApiResponse<any>> => {
    const url = `${this.ENDPOINT}/merge-batch`;
    return request({ url, method: "POST", data: data }).then((res) => {
      return res.data;
    });
  };

  public createBatchHoldDateData = async (
    data: IHoldBatchPayload
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/create-batch-hold-details`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public searchBatchHoldDateData = async (
    batchId: number
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${batchId}/get-batch-hold-details`;
    return request({ url, method: "GET" }).then((res) => {
      return res.data;
    });
  };

  public editByIdBatchHoldDateData = async (
    data: IHoldBatchPayload
  ): Promise<IApiResponse<string>> => {
    const { id, ...rest } = data;
    const url = `${this.ENDPOINT}/${id}/update-batch-hold-details`;
    return request({ url, method: "PATCH", data: rest }).then((res) => {
      return res.data;
    });
  };

  public removeByIdBatchHoldDateData = async (recordId: number) => {
    const url = `${this.ENDPOINT}/${recordId}/remove-batch-hold-details`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public getCourseWiseStudentList = async (data: {
    batch_id: string | number | URLSearchParams;
  }): Promise<IApiResponse<ICourseWiseStudentsRecord>> => {
    const url = `${this.ENDPOINT}/${data.batch_id}/get-student-details`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public bulkStudentAssign = async (
    data: IBulkAssignBatch | URLSearchParams | {}
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/bulk-student-assign`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };
}

export default new UserBatchService();
