import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { IApiResponse } from "../../utils/models/common";
import { ISubCourseState } from "./subCourse.model";
import {
  createNewSubCourse,
  editSubCourseById,
  removeSubCourseById,
  searchSubCourseData,
  searchSubCourseDataById,
  updateSubCourseStatus,
} from "./subCourse.action";

export const initialState: ISubCourseState = {
  removeById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  updateById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  editById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  createSubCourse: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  searchData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  getById: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      id: 0,
      course_id: 0,
      name: "",
      code: "",
      career_course: false,
      upskill_course: false,
      total: 0,
      duration: 0,
      installment: 0,
      shining_sheet: "",
      status: false,
      created_by: "",
      created_date: "",
      updated_by: "",
      updated_date: "",
      course: [],
      subcourse_fees: [],
      course_category: "SINGLE",
    },
  },
};

export const subCourseSlice = createSlice({
  name: "subcourse",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.removeById.message = "";
      state.editById.message = "";
      state.createSubCourse.message = "";
      state.updateById.message = "";
      state.getById.message = "";
    },
  },
  extraReducers: {
    [removeSubCourseById.pending.type]: (state) => {
      state.removeById.loading = true;
    },
    [removeSubCourseById.fulfilled.type]: (
      state,
      action: PayloadAction<IApiResponse<ISubCourseState>>
    ) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = false;
      state.removeById.message = action.payload.message;
    },
    [removeSubCourseById.rejected.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = true;
      state.removeById.message = action.error.message;
    },

    [updateSubCourseStatus.pending.type]: (state) => {
      state.updateById.loading = true;
    },
    [updateSubCourseStatus.fulfilled.type]: (state, action) => {
      state.updateById.message = action.payload.message;
      state.updateById.loading = false;
      state.updateById.hasErrors = false;
    },
    [updateSubCourseStatus.rejected.type]: (state, action) => {
      state.updateById.loading = false;
      state.updateById.hasErrors = true;
      state.updateById.message = action.error.message;
    },

    [editSubCourseById.pending.type]: (state) => {
      state.editById.loading = true;
    },
    [editSubCourseById.fulfilled.type]: (state, action) => {
      state.editById.message = action.payload.message;
      state.editById.loading = false;
      state.editById.hasErrors = false;
    },
    [editSubCourseById.rejected.type]: (state, action) => {
      state.editById.loading = false;
      state.editById.hasErrors = true;
      state.editById.message = action.error.message;
    },

    [createNewSubCourse.pending.type]: (state) => {
      state.createSubCourse.loading = true;
    },
    [createNewSubCourse.fulfilled.type]: (state, action) => {
      state.createSubCourse.message = action.payload.message;
      state.createSubCourse.loading = false;
      state.createSubCourse.hasErrors = false;
    },
    [createNewSubCourse.rejected.type]: (state, action) => {
      state.createSubCourse.loading = false;
      state.createSubCourse.hasErrors = true;
      state.createSubCourse.message = action.error.message;
    },

    [searchSubCourseData.pending.type]: (state) => {
      state.searchData.loading = true;
    },
    [searchSubCourseData.fulfilled.type]: (state, action) => {
      state.searchData.data = action.payload.data;
      state.searchData.message = action.payload.message;
      state.searchData.loading = false;
      state.searchData.hasErrors = false;
    },
    [searchSubCourseData.rejected.type]: (state, action) => {
      state.searchData.loading = false;
      state.searchData.hasErrors = true;
      state.searchData.message = action.error.message;
    },

    // get by id
    [searchSubCourseDataById.pending.type]: (state) => {
      state.getById.loading = true;
    },
    [searchSubCourseDataById.fulfilled.type]: (state, action) => {
      state.getById.data = action.payload.data;
      state.getById.message = action.payload.message;
      state.getById.loading = false;
      state.getById.hasErrors = false;
    },
    [searchSubCourseDataById.rejected.type]: (state, action) => {
      state.getById.loading = false;
      state.getById.hasErrors = true;
      state.getById.message = action.error.message;
    },
  },
});

// A selector
export const subcourseSelector = (state: RootState) => state.subcourse;

export const { clearRemoveMessage } = subCourseSlice.actions;

// The reducer
export default subCourseSlice.reducer;
