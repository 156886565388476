import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import {
  outStandingIncomeData,
  searchOutStandingIncomeCsvEmailSendData,
} from "./outStandingIncome.action";
import { IOutStandingIncomeState } from "./outStandingIncome.model";

export const initialState: IOutStandingIncomeState = {
  outStandingIncomeData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      total_amount: "",
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  searchOutStandingIncomeCsvEmailSendData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
    },
  },
};

export const outStandingIncomeSlice = createSlice({
  name: "outStandingIncome",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.searchOutStandingIncomeCsvEmailSendData.message = "";
    },
  },
  extraReducers: {
    //get commonmodule
    [outStandingIncomeData.pending.type]: (state) => {
      state.outStandingIncomeData.loading = true;
    },
    [outStandingIncomeData.fulfilled.type]: (state, action) => {
      state.outStandingIncomeData.data = action.payload.data;
      state.outStandingIncomeData.message = action.payload.message;
      state.outStandingIncomeData.loading = false;
      state.outStandingIncomeData.hasErrors = false;
    },
    [outStandingIncomeData.rejected.type]: (state, action) => {
      state.outStandingIncomeData.loading = false;
      state.outStandingIncomeData.hasErrors = true;
      state.outStandingIncomeData.message = action.error.message;
    },

    // search outstanding - income data and download csv file
    [searchOutStandingIncomeCsvEmailSendData.pending.type]: (state) => {
      state.searchOutStandingIncomeCsvEmailSendData.loading = true;
    },
    [searchOutStandingIncomeCsvEmailSendData.fulfilled.type]: (
      state,
      action
    ) => {
      state.searchOutStandingIncomeCsvEmailSendData.data = action.payload.data;
      state.searchOutStandingIncomeCsvEmailSendData.message =
        action.payload.message;
      state.searchOutStandingIncomeCsvEmailSendData.loading = false;
      state.searchOutStandingIncomeCsvEmailSendData.hasErrors = false;
    },
    [searchOutStandingIncomeCsvEmailSendData.rejected.type]: (
      state,
      action
    ) => {
      state.searchOutStandingIncomeCsvEmailSendData.loading = false;
      state.searchOutStandingIncomeCsvEmailSendData.hasErrors = true;
      state.searchOutStandingIncomeCsvEmailSendData.message =
        action.error.message;
    },
  },
});

// A selector
export const outStandingIncomeSelector = (state: RootState) =>
  state.outStandingIncome;
export const { clearRemoveMessage } = outStandingIncomeSlice.actions;
// The reducer
export default outStandingIncomeSlice.reducer;
