import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAreaStatus } from "src/pages/Area/Area.model";
import { IAreaDetails } from "src/services/Area/Area.model";
import AreaService from "src/services/Area/Area.service";

export const removeAreaById = createAsyncThunk(
  "area/removeAreaById",
  async (id: number) => {
    return AreaService.removeAreaById(id);
  }
);

export const editAreaById = createAsyncThunk(
  "area/editAreaById",
  async (data: IAreaDetails) => {
    return AreaService.editAreaById(data);
  }
);

export const createNewArea = createAsyncThunk(
  "area/createNewArea",
  async (data: IAreaDetails) => {
    return AreaService.createNewArea(data);
  }
);

export const searchAreaData = createAsyncThunk(
  "area/searchAreaData",
  async (data: any) => {
    return AreaService.searchAreaData(data);
  }
);
