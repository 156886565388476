import { IReceipt } from "src/components/Receipt/Receipt.model";
import request from "src/lib/axios/request";
import config from "src/utils/config";
import { IApiResponse } from "src/utils/models/common";
import {
  IChequeComment,
  IChequeStatus,
  IInstallmentData,
  IInstallmentSplit,
  IPayInstallment,
  IPayInstalmentResponse,
} from "./installments.model";

class InstallmentService {
  ENDPOINT = config.baseApiAdmission + "/installments";

  public installmentPayById = async (
    data: IPayInstallment
  ): Promise<IApiResponse<IPayInstalmentResponse>> => {
    const { installment_id, ...restData } = data;
    const url = `${this.ENDPOINT}/${installment_id}/pay`;
    return request({ url, method: "PUT", data: restData }).then((res) => {
      return res.data;
    });
  };

  public searchInstallmentsData = async (
    data: IInstallmentData | URLSearchParams | {}
  ): Promise<IApiResponse<IInstallmentData[]>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public getInvoiceById = async (
    id: string | undefined
  ): Promise<IApiResponse<IReceipt>> => {
    const url = `${this.ENDPOINT}/${id}/getInvoiceById`;
    return request({ url, method: "GET" }).then((res) => {
      return res.data;
    });
  };

  public countChequeData = async (
    data: IChequeStatus | URLSearchParams | {}
  ): Promise<IApiResponse<IChequeStatus>> => {
    const newData = new URLSearchParams(data.toString());
    newData.delete("cheque_status");
    const url = `${this.ENDPOINT}/cheque-status`;
    return request({ url, method: "GET", params: newData }).then((res) => {
      return res.data;
    });
  };

  public addChequeComment = async (
    data: IChequeComment
  ): Promise<IApiResponse<IChequeComment>> => {
    const url = `${this.ENDPOINT}/cheque-comments`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public installmentSplit = async (
    data: IInstallmentSplit
  ): Promise<IApiResponse<IInstallmentSplit>> => {
    const url = `${this.ENDPOINT}/${data.id}/${data.admission_id}/installment-split`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };
}

export default new InstallmentService();
