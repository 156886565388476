import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IChequeComment,
  IChequeStatus,
  IInstallmentData,
  IInstallmentSplit,
  IPayInstallment,
} from "src/services/installments/installments.model";
import installmentsService from "src/services/installments/installments.service";

export const installmentPayById = createAsyncThunk(
  "installments/installmentPayById",
  async (data: IPayInstallment) => {
    return installmentsService.installmentPayById(data);
  }
);

export const searchInstallmentsData = createAsyncThunk(
  "installments/searchInstallmentsData",
  async (data: IInstallmentData | URLSearchParams | {}) => {
    return installmentsService.searchInstallmentsData(data);
  }
);


export const getInvoiceById = createAsyncThunk(
  "installments/getInvoiceById",
  async (id: string |undefined) => {
    return installmentsService.getInvoiceById(id);
  }
);

export const countChequeData = createAsyncThunk(
  "category/getAdmissionStatusData",
  async (data: IChequeStatus | URLSearchParams | {}) => {
    return installmentsService.countChequeData(data);
  }
);

export const addChequeComment = createAsyncThunk(
  "installments/addChequeComment",
  async (data: IChequeComment) => {
    return installmentsService.addChequeComment(data);
  }
);

export const installmentSplit = createAsyncThunk(
  "admission/installmentSplit",
  async (data: IInstallmentSplit) => {
    return installmentsService.installmentSplit(data);
  }
);
