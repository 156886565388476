import { IExpenseRecord } from "src/pages/Expense/Expense.model";
import { config } from "src/utils";
import request from "../../lib/axios/request";
import { IApiResponse } from "../../utils/models/common";
import { IExpenseDetails, IExpenseStatus } from "./expense.model";

class ExpenseService {
  ENDPOINT = config.baseApiExpense + "/expense";

  public searchExpenseData = async (
    data: IExpenseDetails | URLSearchParams
  ): Promise<IApiResponse<IExpenseDetails[]>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public removeExpenseById = async (id: number) => {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public updateExpenseStatus = async (
    data: IExpenseStatus
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}/status`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

  public createNewExpense = async (
    data: IExpenseDetails
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public editExpenseById = async (
    data: IExpenseRecord
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public expenseCsvMail = async (data: {
    [k: string]: string;
  }): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/expense-csv-mail`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
}

export default new ExpenseService();
