import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { incomeCsvEmail, incomeTellyReportCsvEmail, searchIncomeData } from "./income.action";
import { IIncomeState } from "./income.model";

export const initialState: IIncomeState = {
  IncomeData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      total_amount: "",
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },

  IncomeCsvFile: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
    },
  },

  IncomeTellyReportCsvFile: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
    },
  },
};

export const IncomeSlice = createSlice({
  name: "income",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.IncomeCsvFile.message = "";
      state.IncomeTellyReportCsvFile.message = "";
    },
  },
  extraReducers: {
    //get commonmodule
    [searchIncomeData.pending.type]: (state) => {
      state.IncomeData.loading = true;
    },
    [searchIncomeData.fulfilled.type]: (state, action) => {
      state.IncomeData.data = action.payload.data;
      state.IncomeData.message = action.payload.message;
      state.IncomeData.loading = false;
      state.IncomeData.hasErrors = false;
    },
    [searchIncomeData.rejected.type]: (state, action) => {
      state.IncomeData.loading = false;
      state.IncomeData.hasErrors = true;
      state.IncomeData.message = action.error.message;
    },

    // get IncomeCsvFile
    [incomeCsvEmail.pending.type]: (state) => {
      state.IncomeCsvFile.loading = true;
    },
    [incomeCsvEmail.fulfilled.type]: (state, action) => {
      state.IncomeCsvFile.data = action.payload.data;
      state.IncomeCsvFile.message = action.payload.message;
      state.IncomeCsvFile.loading = false;
      state.IncomeCsvFile.hasErrors = false;
    },
    [incomeCsvEmail.rejected.type]: (state, action) => {
      state.IncomeCsvFile.loading = false;
      state.IncomeCsvFile.hasErrors = true;
      state.IncomeCsvFile.message = action.error.message;
    },

    //income tellyReportCsv
    [incomeTellyReportCsvEmail.pending.type]: (state) => {
      state.IncomeTellyReportCsvFile.loading = true;
    },
    [incomeTellyReportCsvEmail.fulfilled.type]: (state, action) => {
      state.IncomeTellyReportCsvFile.data = action.payload.data;
      state.IncomeTellyReportCsvFile.message = action.payload.message;
      state.IncomeTellyReportCsvFile.loading = false;
      state.IncomeTellyReportCsvFile.hasErrors = false;
    },
    [incomeTellyReportCsvEmail.rejected.type]: (state, action) => {
      state.IncomeTellyReportCsvFile.loading = false;
      state.IncomeTellyReportCsvFile.hasErrors = true;
      state.IncomeTellyReportCsvFile.message = action.error.message;
    },
  },
});

// A selector
export const IncomeSelector = (state: RootState) => state.Income;
export const { clearRemoveMessage } = IncomeSlice.actions;

// The reducer
export default IncomeSlice.reducer;
