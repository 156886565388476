import { createAsyncThunk } from "@reduxjs/toolkit";
import { IIncomeDetailsRecord } from "src/services/income/income.model";
import IncomeService from "src/services/income/income.service";

export const searchIncomeData = createAsyncThunk(
  "income/searchIncomeData",
  async (data: IIncomeDetailsRecord | URLSearchParams) => {
    return IncomeService.searchIncomeData(data);
  }
);

export const incomeCsvEmail = createAsyncThunk(
  "income/incomeCsvEmail",
  async (data: { [k: string]: string }) => {
    return IncomeService.incomeCsvEmail(data);
  }
);

export const incomeTellyReportCsvEmail = createAsyncThunk(
  "income/incomeTellyReportCsvEmail",
  async (data: { [k: string]: string }) => {
    return IncomeService.incomeTellyReportCsvEmail(data);
  }
);
