import { Button, Col, Row, Form, Card, Badge, Empty } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IExamBatch, ITransformedData, ITransformedResult } from 'src/services/ExamBatch/ExamBatch.model';
import { useAppSelector } from 'src/state/app.hooks';
import { AppDispatch } from 'src/state/app.model';
import { examBatch } from 'src/state/ExamBatch/exambatch.action';
import { examBatchSelector } from 'src/state/ExamBatch/exambatch.reducer';
import { searchUserBatchData } from 'src/state/userBatch/userBatch.action';
import { userBatchSelector } from 'src/state/userBatch/userBatch.reducer';
import { bufferURLDecode, bufferURLEncode } from 'src/utils/helperFunction';

const Exams = () => {
    const { batch_id } = useParams();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const userBatchState = useAppSelector(userBatchSelector);
    const batchExamtState = useAppSelector(examBatchSelector);
    useEffect(() => {
        dispatch(searchUserBatchData({ noLimit: true }));
    }, [batch_id]);

    const currentBatchData = userBatchState.searchData.data.rows.find(
        (d) => d.id === Number(batch_id)
    );

    useEffect(() => {
        if (batch_id) {
            dispatch(examBatch(Number(batch_id)));
        }
    }, [batch_id]);

    const clickstudent = (exam_paper_id: number) => {
        const backURL = bufferURLEncode(
            `${location.pathname}${location.search}`
        );
        navigate(`/batch/${batch_id}/${exam_paper_id}/exambatchstudent?r=${backURL}`, {
            replace: true,
        });
    }

    const transformData = (data: IExamBatch[]): ITransformedData[] => {
        if (!Array.isArray(data)) {
            return [];
        }

        const result: any= {};

        data.forEach((item: any) => {
            const sheetId = item?.batchSingingSheet?.id;
            
            if (!result[sheetId]) {
                result[sheetId] = {
                    topic_name: item.batchSingingSheet?.name,
                    marks: item.batchSingingSheet?.marks,
                    details: []
                };
            }

            result[sheetId].details.push({
                id: item.id,
                actual_date: moment(item.batchFacultyAttendence?.actual_date).format('DD-MM-YYYY'),
                number_of_students: item.no_of_student
            });
        });

        return Object.values(result);
    };

    const transformedData = transformData(batchExamtState.examBatchData.data);

    return (
        <>
            <div className="rnw-main-content">
                <Row
                    align="middle"
                    justify="space-between"
                    gutter={24}
                    className="mb-20"
                >
                    <Col xs={20} sm={16}>
                        {currentBatchData && (
                            <>
                                <h2 className="rnw-page-title rnw-page-card-title">
                                    <span>{currentBatchData?.name}</span>
                                </h2>
                                <span>({currentBatchData.user.first_name + " " + currentBatchData.user.last_name} - {currentBatchData.subcourse.name})</span>
                            </>
                        )}
                    </Col>

                    <Col xs={4} sm={8} className="text-align-right">
                        <Button
                            type="primary"
                            onClick={() => {
                                const url = searchParams.get("r");
                                if (url) {
                                    const backURL = bufferURLDecode(url);
                                    navigate(backURL);
                                } else {
                                    const currentPath = window.location.pathname;
                                    const segments = currentPath.split('/');
                                    const targetSegment = segments[1];
                                    if (targetSegment == "batch") {
                                        navigate("/batch");
                                    } else {
                                        navigate("/my-team-batches");
                                    }
                                }
                            }}
                        >
                            <i className="fa fa-arrow-left back-icon"></i>{" "}
                            <span className="gx-d-none gx-d-sm-inline-block">Back</span>
                        </Button>
                    </Col>
                </Row>
                <Card className="table-card signingsheet-card exam-answer-sheet">
                    <div>
                        <table className="faculty-signing" style={{ minWidth: "800px" }}>
                            <thead>
                                <tr>
                                    <th style={{ width: "3%" }}>No.</th>
                                    <th style={{ width: "300px" }}>Topic</th>
                                    <th style={{ width: "150px" }}>Actual Date</th>
                                    <th style={{ width: "150px" }}>Number of student</th>
                                    <th style={{ width: "150px" }}>Marks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transformedData.length > 0 ? (
                                    transformedData.map((row : ITransformedData, rowIndex) => (
                                        <React.Fragment key={rowIndex}>
                                            {row && row.details?.map((detail, detailIndex: number) => (
                                                <tr key={detail.id} className={`sub-topic ${rowIndex % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                                                    {detailIndex === 0 && (
                                                        <td rowSpan={row.details?.length} align="center">
                                                            <span style={{ display: "inline-block" }}>
                                                                {rowIndex + 1}
                                                            </span>
                                                        </td>
                                                    )}
                                                    {detailIndex === 0 && (
                                                        <td rowSpan={row.details?.length} style={{ textAlign: "left", wordBreak: "break-word" }}>
                                                            {row.topic_name}
                                                        </td>
                                                    )}
                                                    <td align="center">
                                                        {detail.actual_date}
                                                    </td>
                                                    <td align="center"
                                                        style={{
                                                            cursor: "pointer",
                                                            color: "rgb(234, 84, 85)",
                                                        }}
                                                        onClick={() => clickstudent(detail?.id)}
                                                    >
                                                        {detail.number_of_students}
                                                    </td>
                                                    {detailIndex === 0 && (
                                                        <td rowSpan={row.details?.length} align="center">
                                                            {row.marks}
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <tr key={"none"}>
                                    <td colSpan={8} style={{ border: "none" }}>
                                      <div>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                                {}
                            </tbody>
                        </table>
                    </div>

                </Card>
            </div>
        </>
    )
}

export default Exams