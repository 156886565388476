import { Avatar, Button, Col, Image, Popover, Row, } from "antd";
import StudentMobileNumber from "src/components/StudentMobileNumber";
import {  useState } from "react";
import ModalComponent from "../Modal";
import { config } from "src/utils";
import Remarks from "../AddRemarks";
import { admissionViewAndUnAssignAdmissionViewRemarks } from "src/utils/constants/constant";
import { studentProfileModelProps } from "./StudentProfileModel.model";
import { UserOutlined } from "@ant-design/icons";

function StudentProfileModel(props: studentProfileModelProps) {
  const { openModel, setOpenModel, modelData } = props;
  const [remarksModalOpen, setRemarksModalOpen] = useState(false);  
  return (
    <div>
      {" "}
      <ModalComponent
        className="show-studentList absent-studentList"
        title={`Student Details`}
        showModal={openModel}
        onCancel={() => {
          setOpenModel(false);
        }}
        component={
          <div className="card-body faculty-dashboard absent-card-body">
            <>
              <Row className="gx-align-items-center gx-align-items-md-start">
                <Col xs={10} md={6} className="gx-text-center">
                  {modelData?.profilePhoto ? (
                    <Avatar
                      size={120}
                      className="absent-data-image"
                      src={
                        <Image
                          src={
                            config.bucket_name + "/" + modelData?.profilePhoto
                          }
                        />
                      }
                    />
                  ) : (
                    <Avatar size={100} icon={<UserOutlined />} />
                  )}
                </Col>
                <Col className="gx-d-md-none" xs={14} md={12}>
                  <div className="profile-detail">
                    <p>Name:</p>
                    <b>{modelData?.studentName}</b>
                    {modelData?.mobile_no && (
                      <StudentMobileNumber
                        studentMobileNumber={String(modelData?.mobile_no)}
                      />
                    )}
                  </div>
                </Col>
                <Col md={18} className="gx-mt-3 gx-mt-md-0">
                  <Row>
                    <Col md={12} className="gx-d-none gx-d-md-block">
                      <div className="profile-detail">
                        <p>Name:</p>
                        <b>{modelData?.studentName}</b>
                        {modelData?.mobile_no && (
                          <StudentMobileNumber
                            studentMobileNumber={String(modelData?.mobile_no)}
                          />
                        )}
                      </div>
                    </Col>
                    <Col xs={10} md={12}>
                      <div className="profile-detail">
                        <p>Father Name:</p>
                        <b>
                          {modelData?.father_name}
                        </b>
               
                           {modelData?.father_mobile_no && (
                          <StudentMobileNumber
                            studentMobileNumber={String(
                              modelData?.father_mobile_no
                            )}
                          />
                        )}
                      </div>
                    </Col>
                    <Col xs={14} md={12} className="gx-my-md-4">
                      <div className="profile-detail">
                        <p>Email ID:</p>
                        <b>{modelData?.email}</b>
                      </div>
                    </Col>

                    <Col xs={10} md={12} className="gx-my-4">
                      <div className="profile-detail">
                        <p>GR ID:</p>
                        <b>{modelData?.gr_id}</b>
                      </div>
                    </Col>
                    <Col xs={14} md={12} className="gx-my-4 gx-my-md-0">
                      <div className="profile-detail">
                        <p>Batch</p>
                        {modelData?.batchesName ?
                        (<>
                          <div className="roles-details  ">
                            {modelData?.batchesName?.map((name: string, index: number) => {
                              if (index < 1) {
                                return (
                                  <>
                                    <b className="gx-mb-1">{name}</b>
                                  </>
                                );
                              }
                            })}
                            {modelData?.batchesName?.length > 1 && (
                              <>
                                <div className="gx-ml-2">
                                  <Popover
                                    getPopupContainer={(parent) =>
                                      parent.parentElement as HTMLElement
                                    }
                                    content={
                                      <p className="gx-mb-1 role-modal-details">
                                        
                                        {modelData?.batchesName?.map((batchName: string) => {
                                          return (
                                            <span className={`gx-mb-1 gx-d-block gx-px-3 batchname`}>{batchName}</span>
                                          );
                                        })}
                                      </p>
                                    }
                                    placement="bottomLeft"
                                    title="Batches"
                                    trigger="click"
                                  >
                                    <Button type="primary">More</Button>
                                  </Popover>
                                </div>
                              </>
                            )}
                          </div>
                        </>) : (
                          <b>{modelData?.batch_name}</b>
                        )}
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="profile-detail">
                        <p>Remark</p>
                        <b>
                          <span
                            style={{
                              cursor: "pointer",
                              color: "#ea5455",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              setRemarksModalOpen(true);
                            }}
                          >
                            Add
                          </span>
                        </b>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          </div>
        }
        footer={[
          <div>
            <Button key="back" onClick={() => setOpenModel(false)}>
              Cancel
            </Button>
          </div>,
        ]}
      ></ModalComponent>
   
        <Remarks
          branchId={modelData?.branch_id}
          admissionStatus={modelData?.status}
          admissionId={modelData?.admission_id}
          remarksModalOpen={remarksModalOpen}
          setRemarksModalOpen={setRemarksModalOpen}
          admissionViewAndUnAssignAdmissionView={
            admissionViewAndUnAssignAdmissionViewRemarks.VIEW_STUDENT_DETAILS_REMARKS
          }
        />
   
    </div>
  );
}

export default StudentProfileModel;
