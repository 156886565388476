import { Badge, Button, Card, Col, DatePicker, Drawer, Form, message, Modal, Row, Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import ContainerHeader from 'src/components/ContainerHeader'
import {
    FileTextOutlined,
    DownloadOutlined,
    FilterFilled,
} from "@ant-design/icons";
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/state/app.model';
import { incomeTellyReportCsvEmail, searchIncomeData } from 'src/state/income/income.action';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'src/state/app.hooks';
import { IncomeSelector, clearRemoveMessage } from 'src/state/income/income.reducer';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { currencyFormat, showTooltip, trimObject } from 'src/utils/helperFunction';
import TableExpandableComponent from 'src/components/DataTableExpandable';
import { packageSelector } from 'src/state/package/package.reducer';
import { CourseCategoryStatusType } from 'src/utils/constants/constant';
import { IIncome, IIncomeDetailsRecord } from 'src/services/income/income.model';
import { searchSubCourseData } from 'src/state/subCourse/subCourse.action';
import { searchPackageData } from 'src/state/package/package.action';
import { subcourseSelector } from 'src/state/subCourse/subCourse.reducer';
import { AdmissionSubcourse } from 'src/services/overdueIncome/overdueIncome.model';
import { Can } from "src/ability/can";
import { Common } from "../../utils/constants/constant";

const { RangePicker } = DatePicker;

const TellyReport = () => {
    const dispatch = useDispatch<AppDispatch>();
    const packageState = useAppSelector(packageSelector);
    const subCourseState = useAppSelector(subcourseSelector);
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<IIncome>();
    const IncomeState = useAppSelector(IncomeSelector);
    const [count, setCount] = useState<number>(0);
    const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState({});
    const dateFormat = "DD-MM-YYYY";

    const [page, setPage] = useState(
        searchParams.get("skip") && searchParams.get("take")
            ? Number(searchParams.get("skip")) / Number(searchParams.get("take")) + 1
            : 1
    );

    useEffect(() => {
        dispatch(searchPackageData({ noLimit: true, orderBy: "name", order: "ASC" }));
        dispatch(searchSubCourseData({ noLimit: true, orderBy: "name", order: "ASC" }));
    }, []);

    useEffect(() => {
        if (IncomeState.IncomeData.data && IncomeState.IncomeData.data.rows) {
            const modifiedIncomeData = IncomeState.IncomeData.data?.rows.map(
                (item) => {
                    const { admission_installments, ...rest } = item;
                    const installment_no = admission_installments?.installment_no || null;
                    return { installment_no, ...rest, ...item };
                }
            );
            if (modifiedIncomeData) {
                setData({
                    ...IncomeState.IncomeData.data,
                    rows: [...modifiedIncomeData] as IIncomeDetailsRecord[],
                });
            }
        }
        if (searchParams.get("skip") && searchParams.get("take")) {
            setPage(
                searchParams.get("skip") && searchParams.get("take")
                    ? Number(searchParams.get("skip")) /
                    Number(searchParams.get("take")) +
                    1
                    : 1
            );
        }
    }, [IncomeState.IncomeData.data]);

    useEffect(() => {
        dispatch(searchIncomeData(searchParams)).then((res) => {
            if (!res.payload) {
                setData(undefined);
            }
            setLoading(false);
        });
    }, [searchParams]);

    useEffect(() => {
        if (IncomeState.IncomeTellyReportCsvFile.message) {
            if (IncomeState.IncomeTellyReportCsvFile.hasErrors) {
                message.error(IncomeState.IncomeTellyReportCsvFile.message);
            } else {
                message.success(IncomeState.IncomeTellyReportCsvFile.message);
            }
            dispatch(clearRemoveMessage());
        }
    }, [IncomeState.IncomeTellyReportCsvFile.message]);

    const columns: ColumnsType<IIncomeDetailsRecord> = [
        {
            title: "No.",
            align: "center",
            render: (text, record, index) => (
                <>{(page - 1) * Number(searchParams.get("take")) + index + 1}</>
            ),
        },
        {
            title: "Voucher Date",
            dataIndex: "invoiceDate",
            align: "center",
            key: "invoiceDate",
            render: (record) => {
                return <span>{moment(record).format("DD-MM-YYYY")}</span>;
            },
        },
        {
            title: "Voucher Type Name",
            render: (record) => {
                const searchTerm = "Private Limited";
                const getBranchCodeIfMatch = (branchname: string) => {
                    return branchname.includes(searchTerm) ? branchname : '';
                };
                const allInstallments = [...record.admission_installments, ...record.admission_penalty];
                return allInstallments.map((data) => {
                    const branchname = data.branch?.name || '';
                    const branchCode = getBranchCodeIfMatch(branchname) ? data.branch?.code : '';
                    return (
                        <span>
                            {`Sales ${branchCode}`}
                        </span>
                    );
                });
            },
        },
        {
            title: "Voucher Number",
            dataIndex: "invoiceNo",
            align: "center",
            key: "invoiceDate",
            render: (record) => {
                return record;
            },
        },
        {
            title: "Ledger Name",
            align: "center",
            dataIndex: "",
            render: (record) => {
                return <span>{`${record.admission.first_name} - ${record.admission.gr_id}`}</span>
            },
        },
        {
            title: "Ledger Amount",
            dataIndex: "payAmount",
            align: "center",
            render: (record) => {
                return <span>{currencyFormat(record)}</span>;
            },
        },
    ];

    const expandedRowRender = (record: IIncomeDetailsRecord) => {
        const columns: ColumnsType<IIncomeDetailsRecord> = [
            {
                title: "Package/Course Name",
                dataIndex: "",
                align: "center",
                render: (record) => {
                    const packageName = record.admission.packageName
                    const subcourseNames = record.admission.admission_subcourses.map((subcourse: AdmissionSubcourse) => subcourse.subcourse_name)
                    const maxLength = 30;
                    return (
                        <div>
                            {record.admission.course_category === CourseCategoryStatusType.PACKAGE ? (
                                <span>
                                    P : {showTooltip(packageName, maxLength)}
                                </span>
                            ) : (
                                <span>
                                    S : {showTooltip(subcourseNames.join(', '), maxLength)}
                                </span>
                            )}
                        </div>
                    );
                },
            },
            {
                title: "CGST",
                dataIndex: "cgst",
                align: "center",
                render: (record) => {
                    return <span>{currencyFormat(Math.round(Number(record)))}</span>;
                },
            },
            {
                title: "SGST",
                dataIndex: "sgst",
                align: "center",
                render: (record) => {
                    return <span>{currencyFormat(Math.round(Number(record)))}</span>;
                },
            },
            {
                title: "Taxable Amount",
                dataIndex: "",
                align: "center",
                render: (record) => {
                    const payAmount = parseFloat(record.payAmount || 0);
                    const cgst = Math.round(parseFloat(record.cgst) || 0);
                    const sgst = Math.round(parseFloat(record.sgst) || 0);
                    const gst = cgst + sgst;
                    const taxableAmount = payAmount - gst;
                    return (
                        <span>{currencyFormat(taxableAmount)}</span>
                    );
                },
            },
        ];

        return (
            <Table
                className="gx-table-responsive"
                columns={columns}
                dataSource={[record]}
                pagination={false}
            />
        );
    };

    const TellyReportCsvMail = () => {
        const data = Object.fromEntries(new URLSearchParams(searchParams));
        dispatch(incomeTellyReportCsvEmail(data));
    };

    const finalData = data?.rows.map((income) => {
        const packageNames = income?.admission?.course_category === CourseCategoryStatusType.PACKAGE &&
            packageState.packageData?.data?.rows?.reduce((acc: { [key: number]: string }, pkg) => {
                acc[pkg.id] = pkg.name;
                return acc;
            }, {});

        const packageId = income.admission?.package_id;
        const packageName = packageNames && packageNames[packageId as number] || null;

        const subcourseNames = income?.admission?.course_category === CourseCategoryStatusType.SINGLE &&
            subCourseState.searchData.data.rows.reduce((acc: { [key: number]: string }, subcourse) => {
                acc[subcourse.id] = subcourse.name;
                return acc;
            }, {});

        const updatedAdmissionSubcourses = income.admission?.admission_subcourses.map((subcourse) => ({
            ...subcourse,
            subcourse_name: subcourseNames && subcourseNames[subcourse?.subcourse_id as number] || null
        })) || [];

        return {
            ...income,
            packageName: packageName,
            admission: {
                ...income.admission,
                admission_subcourses: updatedAdmissionSubcourses,
                packageName: packageName,
            }
        };
    });

    useEffect(() => {
        const data = { ...setFormValues };
        for (const entry of Array.from(searchParams.entries())) {
            const [key, value] = entry;

            if (key === "date_from") {
                Object.assign(data, {
                    ["installment_date"]:
                        searchParams.get("date_from") && searchParams.get("date_to")
                            ? [
                                moment(searchParams.get("date_from")),
                                moment(searchParams.get("date_to")),
                            ]
                            : "",
                });
            } else {
                Object.assign(data, {
                    [key]: value,
                });
            }
        }

        setFormValues(data);
    }, []);

    useEffect(() => {
        if (Object.keys(formValues).length > 0) {
            form.resetFields();
        }
    }, [formValues]);

    useEffect(() => {
        let sum = 0;
        const data = Object.fromEntries(new URLSearchParams(searchParams));
        for (const [key, value] of Object.entries(data)) {
          if (
            key !== "orderBy" &&
            key !== "order" &&
            key !== "skip" &&
            key !== "take"
          ) {
            sum += 1;
          }
        }
        setCount(sum);
      }, [window.location.search]);

    const handleCancel = () => {
        setFilterModalOpen(false);
    };

    const onFinish = (values: IIncomeDetailsRecord) => {
        if (values.installment_date && values.installment_date != null) {
          values.installment_date = values.installment_date.map((date) => {
            return moment(date).format("YYYY-MM-DD");
          });
        }
        let newDateData: string[] = [];
        let data = Object.fromEntries(new URLSearchParams());
        if (values.installment_date) {
          newDateData = values.installment_date;
          let date = {
            date_from: newDateData[0],
            date_to: newDateData[1],
          };
          data = date;
        }
        values = { ...data, ...values };
    
        const { installment_date, ...rest } = values;
        let queryString = Object.entries(trimObject(rest))
          .filter(
            ([key, values]) =>
              values !== undefined &&
              values !== "" &&
              values !== null &&
              (Array.isArray(values) ? values.length > 0 : values)
          )
          .map(([key, values]) => key + "=" + encodeURIComponent(values as string))
          .join("&");
        setSearchParams(queryString.trim());
        setFilterModalOpen(false);
      };

    const onReset = () => {
        setFormValues(" ");
        form.resetFields();
        setSearchParams({});
    };

    return (
        <div>
            <Skeleton active loading={loading} avatar>
                <Row
                    className="mb-20"
                    align="middle"
                    justify="space-between"
                    gutter={24}
                >
                    <Col
                        md={16}
                        sm={16}
                        lg={14}
                        className="-amount-title"
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <ContainerHeader title="Tally Report" />
                    </Col>
                    <Col
                        md={8}
                        sm={8}
                        lg={10}
                        className=" gx-mt-md-0 gx-text-right"
                    >
                        <Can
                            I={Common.Actions.CAN_VIEW}
                            a={Common.Modules.REPORT.TELLY_REPORT_CSV}
                        >
                            <Button
                                icon={<FileTextOutlined />}
                                onClick={() => {
                                    Modal.confirm({
                                        title: "Are you sure want to download telly report csv file?",
                                        okText: "Yes",
                                        cancelText: "No",
                                        icon: <DownloadOutlined />,
                                        onOk() {
                                            TellyReportCsvMail();
                                        },
                                    });
                                }}
                            >
                                CSV
                            </Button>
                            <Button
                                icon={<FilterFilled />}
                                onClick={() => setFilterModalOpen(true)}
                            >
                                Filter
                                <span>
                                    <Badge count={count}></Badge>
                                </span>
                            </Button>
                        </Can>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <>
                            <div className="filter" style={{ height: "auto" }}>
                                <Drawer
                                    title="Tally Report Filter"
                                    visible={filterModalOpen}
                                    onClose={() => {
                                        setFilterModalOpen(false);
                                    }}
                                    footer={[
                                        <div className="gx-d-flex gx-justify-content-center">
                                            <Button
                                                className="cancel-filter gx-mr-0"
                                                key="back"
                                                onClick={handleCancel}
                                            >
                                                <span className="gx-d-none gx-d-sm-block">
                                                    Cancel
                                                </span>
                                                <i className="fa fa-close gx-d-sm-none"></i>
                                            </Button>
                                            <Button
                                                className="btn-apply-filter gx-mx-2"
                                                key="submit"
                                                type="primary"
                                                loading={loading}
                                                htmlType="submit"
                                                form="myForm"
                                            >
                                                Apply Filter
                                            </Button>
                                            <Button
                                                className="reset-filter"
                                                type="default"
                                                htmlType="reset"
                                                form="myForm"
                                            >
                                                <span className="gx-d-none gx-d-sm-block">Reset</span>
                                                <i className="fa fa-refresh gx-d-sm-none"></i>
                                            </Button>
                                        </div>,
                                    ]}
                                >
                                    <Form
                                        id="myForm"
                                        onFinish={onFinish}
                                        form={form}
                                        onReset={onReset}
                                        initialValues={formValues}
                                    >
                                        <Form.Item
                                            name="installment_date"
                                            className="multidate-select"
                                        >
                                            <RangePicker
                                                getPopupContainer={(trigger) => trigger}
                                                style={{ width: "100%" }}
                                                name="installment_date"
                                                format={dateFormat}
                                                ranges={{
                                                    Today: [moment(), moment()],
                                                    Week: [
                                                        moment().startOf("week"),
                                                        moment().endOf("week"),
                                                    ],
                                                    "This Month": [
                                                        moment().startOf("month"),
                                                        moment().endOf("month"),
                                                    ],
                                                    "This Year": [
                                                        moment().startOf("year"),
                                                        moment().endOf("year"),
                                                    ],
                                                }}
                                            />
                                        </Form.Item>
                                    </Form>
                                </Drawer>
                            </div>
                        </>
                    </Col>
                </Row>
                <Card className="rnw-card zone gx-mb-0">
                    <TableExpandableComponent
                        columns={columns}
                        dataSource={finalData || []}
                        meta={data?.meta}
                        loading={loading}
                        expanrowData={expandedRowRender}
                    />
                </Card>
            </Skeleton>
        </div>
    )
}

export default TellyReport