import request from "../../lib/axios/request";
import config from "../../utils/config";
import { IApiResponse } from "../../utils/models/common";
import {
  IAssignQuestionBank,
  IUpdateAssignQuestionBank,
} from "./assignQuestionBank.model";

class AssignQuestionBankService {
  ENDPOINT = config.baseApiBatch + "/assign-question-bank";

  public assignQuestionBank = async (
    data: IAssignQuestionBank
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public updateAssignQuestionBank = async (
    data: IUpdateAssignQuestionBank
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public removeAssignQuestionBank = async (id: number) => {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };
}

export default new AssignQuestionBankService();
