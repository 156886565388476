import request from "src/lib/axios/request";
import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import { BranchWiseOverdueResponse, IOverdueIncomeDetailsRecord, ISchoolWiseStudentCount } from "./overdueIncome.model";
import { IAdmission } from "../admission/admission.model";

class OverdueIncome {
  ENDPOINT = config.baseApiAdmission + "/reports";

  public searchReportData = async (
    data: IOverdueIncomeDetailsRecord | URLSearchParams
  ): Promise<IApiResponse<IOverdueIncomeDetailsRecord>> => {
    const url = `${this.ENDPOINT}/overdue-income`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public searchOverdueIncomeCsvEmailSendData = async (
    data: IOverdueIncomeDetailsRecord | URLSearchParams | {}
  ): Promise<IApiResponse<IOverdueIncomeDetailsRecord>> => {
    const url = `${this.ENDPOINT}/overdue-income-report`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public branchWiseOverdueReport = async (
    data: URLSearchParams | {}
  ): Promise<IApiResponse<BranchWiseOverdueResponse>> => {
    const url = `${this.ENDPOINT}/branch-wise-overdue-income`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  }

  public schoolWiseStudentCount = async (
    data: ISchoolWiseStudentCount | URLSearchParams | {}
  ): Promise<IApiResponse<ISchoolWiseStudentCount>> => {
    const url = `${this.ENDPOINT}/school-wise-student-count`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public schoolWiseStudentCountCsv = async (
    data: ISchoolWiseStudentCount | URLSearchParams | {}
  ): Promise<IApiResponse<ISchoolWiseStudentCount[]>> => {
    const url = `${this.ENDPOINT}/school-wise-student-count-csv`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
}

export default new OverdueIncome();
