import { config } from "src/utils";
import request from "../../lib/axios/request";
import { IZoneRecord } from "../../pages/Zone/Zone.model";
import { IApiResponse } from "../../utils/models/common";
import { ISearchAllZoneDataPayload, IZone, IZoneDetails, IZoneStatus } from "./zone.model";

class ZoneService {
  ENDPOINT = config.baseApiMasters + "/zones";

  public removeZoneById = async (id: number) => {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public updateZoneStatus = async (
    data: IZoneStatus
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}/status`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

  public editZoneById = async (
    data: IZoneRecord
  ): Promise<IApiResponse<any>> => {
    const url = `${this.ENDPOINT}/${data.id}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public createNewZone = async (
    data: IZoneDetails
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public searchZoneData = async (data: any): Promise<IApiResponse<IZone>> => {
    let type = {
      ...data,
    };
    if (!data["type"]) {
      type = Object.assign(
        {
          type: "PUBLIC",
        },
        type
      );
    }
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: type }).then((res) => {
      return res.data;
    });
  };

  public searchAllZoneData = async (
    data: ISearchAllZoneDataPayload
  ): Promise<IApiResponse<string>> => {
    let type = {
      ...data,
    };
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET" , params: type}).then((res) => {
      return res.data;
    });
  };
}

export default new ZoneService();
