import {
  Badge,
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Row,
  Skeleton,
  Switch,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  EyeOutlined,
  MailOutlined,
  DownloadOutlined,
  FilterFilled,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ContainerHeader from "src/components/ContainerHeader";
import DrawerComponent from "src/components/Drawer";
import { useAppSelector } from "src/state/app.hooks";
import { AppDispatch } from "src/state/app.model";
import { searchSubCourseDataById } from "src/state/subCourse/subCourse.action";
import { ColumnsType } from "antd/lib/table";
import TableComponent from "src/components/DataTable";
import {
  ITemplateRecord,
  ITemplateSigningSheetRecord,
} from "src/services/templateSigningSheet/templateSigningSheet.model";
import {
  createNewTemplateSigningSheet,
  deleteTemplateShiningSheetId,
  editTemplateShiningSheetBySubCourseIdAndId,
  searchTemplateSigningSheetDataByScID,
  sendTemplateInMail,
  updateTemplateShiningSheetStatus,
} from "src/state/templateSignningsheet/templateSignningsheet.action";
import {
  clearRemoveMessage,
  templateSigningSheetSelector,
} from "src/state/templateSignningsheet/templateSignningsheet.reducer";
import {
  bufferURLDecode,
  openInNewTab,
  showTooltip,
  trimObject,
} from "src/utils/helperFunction";
import FloatLabel from "src/components/Form/FloatLabel";
import {
  ICreateTempPayload,
  TemplateShiningSheetTopic,
} from "./SubCourseTemplate.model";
import ActionWrapper from "src/components/ActionWrapper";
import { Can } from "src/ability/can";
import { Common } from "src/utils/constants/constant";
import { ability } from "src/ability";
import CommonFilter from "src/components/CommonFilter";
import DeleteButtonWithConfirmComponent from "src/components/DeleteButtonWithConfirm";
import SubCourseTemplateAdd from "./Add";

const SubCourseTemplate = () => {
  const [subCourseName, setSubCourseName] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const { subcourse_id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const [formValues, setFormValues] = useState<{ [key: string]: string }>({});
  const [form] = Form.useForm();
  const [show, setShow] = useState(false);
  const [row, setRow] = useState<ITemplateRecord>();
  const [data, setData] = useState<ITemplateSigningSheetRecord>();
  const [count, setCount] = useState<number>(0);
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(true);

  const templateSigningSheetState = useAppSelector(
    templateSigningSheetSelector
  );
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(
    searchParams.get("skip") && searchParams.get("take")
      ? Number(searchParams.get("skip")) / Number(searchParams.get("take")) + 1
      : 1
  );
  useEffect(() => {
    if (searchParams) {
      setCurrentPage(
        searchParams.get("skip") && searchParams.get("take")
          ? Number(searchParams.get("skip")) /
              Number(searchParams.get("take")) +
              1
          : 1
      );
      if (subcourse_id) {
        const NewData = {
          searchParams: searchParams,
          subcourse_id: subcourse_id,
        };
        dispatch(searchTemplateSigningSheetDataByScID(NewData)).then(() => {
          setLoading(false);
        });
      }
    }
  }, [searchParams]);

  const calculateTotalDuration = (data: TemplateShiningSheetTopic[]): number => {
    let totalDuration = 0;
  
    data.forEach((item: TemplateShiningSheetTopic) => {
      if (item?.sub_topics?.some((subTopic: { status: boolean; }) => subTopic.status === true)) {
        totalDuration += Number(item.duration);
      }
    });
  
    return totalDuration;
  };

  const updatePage = (values: ICreateTempPayload) => {

    const templateRightSideExistTopicsLength =
    values.template_shining_sheet_topics?.filter(
      (templateShiningSheetTopics) => templateShiningSheetTopics.status
    ).length;

    setDisable(false);
    if (row) {
      const data = {
        ...values,
        id: row.id,
        subcourse_id: row.subcourse_id,
      };
      const updatedData = data.template_shining_sheet_topics.map(
        ({ template_singing_sheet_id, ...rest }) => rest
      );
      const dataNew = {
        ...data,
        status: true,
        template_shining_sheet_topics: updatedData,
        total_duration: calculateTotalDuration(updatedData)
      };

      if(templateRightSideExistTopicsLength) {
        dispatch(
          editTemplateShiningSheetBySubCourseIdAndId(trimObject(dataNew))
        ).then((res) => {
          if (res.payload) {
            setDisable(true);
            setShow(false);
          } else {
            setDisable(true);
          }
          if (searchParams && subcourse_id) {
            const NewData = {
              searchParams: searchParams,
              subcourse_id: subcourse_id,
            };
            dispatch(searchTemplateSigningSheetDataByScID(NewData));
          }
        });
      }else{
        setTimeout(() => {
          setDisable(true);
        }, 3000)        
        message.error(
          "templated shining sheet topic must contain at least one topic or sub topic."
        );
      }
    } else {
      if (templateRightSideExistTopicsLength) {
        const updatedData = values.template_shining_sheet_topics.map(
          ({ id, ...rest }) => rest
        );
        const data = {
          ...values,
          template_shining_sheet_topics: updatedData,
          total_duration: calculateTotalDuration(updatedData)
        };
        dispatch(createNewTemplateSigningSheet(trimObject(data))).then(
          (res) => {
            if (res.payload) {
              setDisable(true);
              setShow(false);
            } else {
              setDisable(true);
            }
            if (searchParams && subcourse_id) {
              const NewData = {
                searchParams: searchParams,
                subcourse_id: subcourse_id,
              };
              dispatch(searchTemplateSigningSheetDataByScID(NewData));
            }
          }
        );
      } else {
        setTimeout(() => {
          setDisable(true);
        }, 3000)
        message.error(
          "Please select topic and sub topic then move to right side it."
        );
      }
    }
  };

  const handleEdit = (record: ITemplateRecord) => {
    setRow(record);
  };
  const handleDelete = (record: ITemplateRecord) => {
    dispatch(deleteTemplateShiningSheetId(Number(record.id))).then(() => {
      if (subcourse_id) {
        dispatch(
          searchTemplateSigningSheetDataByScID({ subcourse_id: subcourse_id })
        );
      }
    });
  };

  const sendPdfInMail = (templateId: number) => {
    const pdfPayload = {
      subcourse_id: Number(subcourse_id),
      id:templateId
    };
    dispatch(sendTemplateInMail(pdfPayload))
  }

  useEffect(() => {
    if (templateSigningSheetState.templatePdfData.message) {
      if (templateSigningSheetState.templatePdfData.hasErrors) {
        message.error(templateSigningSheetState.templatePdfData.message);
      } else {
        message.success(templateSigningSheetState.templatePdfData.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [templateSigningSheetState.templatePdfData.message]);

  useEffect(() => {
    const data = { ...setFormValues };
    for (const entry of Array.from(searchParams.entries())) {
      const [key, value] = entry;
      Object.assign(data, {
        [key]: value,
      });
    }
    setFormValues(data);
  }, []);

  const onCancel = () => {
    setShow(false);
    setFilterModalOpen(false);
  };

  const onReset = () => {
    setCount(0);
  };

  useEffect(() => {
    let sum = 0;
    const data = Object.fromEntries(new URLSearchParams(searchParams));

    const { r, ...newObject } = data;

    for (const [key, value] of Object.entries(newObject)) {
      if (
        window.location.search.length !== 0 &&
        key !== "orderBy" &&
        key !== "order" &&
        key !== "skip" &&
        key !== "take"
      ) {
        sum += 1;
      }
    }
    setCount(sum);
  }, [window.location.search]);

  const onFinish = (values: { [key: string]: string }) => {
    let queryString = Object.entries(trimObject(values))
      .filter(([key, values]) => values !== undefined && values !== "")
      .map(([key, values]) => key + "=" + values)
      .join("&");
    const backURL = searchParams.get("r");
    if (backURL) {
      queryString += `&r=${backURL}`;
    }
    setSearchParams(queryString);
    setFilterModalOpen(false);
  };

  const handleStatus = (record: ITemplateRecord, checked: boolean) => {
    const data = {
      id: Number(record.id),
      status: checked,
      subcourse_id: Number(subcourse_id),
    };
    dispatch(updateTemplateShiningSheetStatus(data)).then((res) => {
      if (searchParams && subcourse_id) {
        const NewData = {
          searchParams: searchParams,
          subcourse_id: subcourse_id,
        };
        dispatch(searchTemplateSigningSheetDataByScID(NewData));
      }
    });
  };

  useEffect(() => {
    if (subcourse_id) {
      dispatch(searchSubCourseDataById({ id: subcourse_id })).then(
        (res: any) => {
          let headerName =
            res?.payload?.data?.name + " (" + res?.payload?.data?.code + ")";
          setSubCourseName(headerName);
        }
      );
    }
  }, [subcourse_id]);

  useEffect(() => {
    if (templateSigningSheetState.templateSigningSheetData.data) {
      setData(templateSigningSheetState.templateSigningSheetData.data);
    }
  }, [templateSigningSheetState.templateSigningSheetData.data]);

  useEffect(() => {
    if (templateSigningSheetState.createTemplate.message) {
      if (templateSigningSheetState.createTemplate.hasErrors) {
        message.error(templateSigningSheetState.createTemplate.message);
      } else {
        message.success(templateSigningSheetState.createTemplate.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [templateSigningSheetState.createTemplate.message]);

  useEffect(() => {
    if (templateSigningSheetState.editById.message) {
      if (templateSigningSheetState.editById.hasErrors) {
        message.error(templateSigningSheetState.editById.message);
      } else {
        message.success(templateSigningSheetState.editById.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [templateSigningSheetState.editById.message]);

  useEffect(() => {
    if (templateSigningSheetState.removeById.message) {
      if (templateSigningSheetState.removeById.hasErrors) {
        message.error(templateSigningSheetState.removeById.message);
      } else {
        message.success(templateSigningSheetState.removeById.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [templateSigningSheetState.removeById.message]);

  useEffect(() => {
    if (templateSigningSheetState.updateTemplateStatus.message) {
      if (templateSigningSheetState.updateTemplateStatus.hasErrors) {
        message.error(templateSigningSheetState.updateTemplateStatus.message);
      } else {
        message.success(templateSigningSheetState.updateTemplateStatus.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [templateSigningSheetState.updateTemplateStatus.message]);

  const columns: ColumnsType<ITemplateRecord> = [
    {
      title: "No.",
      dataIndex: "id",
      sorter: false,
      align: "center",
      width: "5%",
      render: (item, record, index) => (
        <>{(currentPage - 1) * Number(searchParams.get("take")) + index + 1}</>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "30%",
      render: (record) => {
        const maxLength = 75;
        return <span>{showTooltip(record, maxLength)}</span>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: true,
      width: "30%",
      render: (record) => {
        const maxLength = 75;
        return <span>{showTooltip(record, maxLength)}</span>;
      },
    },
    {
      title: "Duration",
      dataIndex: "total_duration",
      width: "30%",
      render: (record) => {
        return <span>{Number(record)}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      key: "status",
      width: "10%",
      align: "center",
      render: (_, record) => {
        return (
          <>
            {ability.can(
              Common.Actions.CAN_UPDATE,
              Common.Modules.MASTER.TEMPLATE_SHINING_SHEET
            ) ? (
              <Switch
                key={`record-${record.id}`}
                defaultChecked={record.status}
                onClick={(e) => {
                  handleStatus(record, e);
                }}
              />
            ) : (
              <>
                {record.status === true ? (
                  <p className="gx-mb-0">Active</p>
                ) : (
                  <p className="gx-mb-0">Inactive</p>
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      align: "center",
      render: (record) => {
        return (
          <>
            <ActionWrapper>
              <Can
                I={Common.Actions.CAN_UPDATE}
                a={Common.Modules.MASTER.TEMPLATE_SHINING_SHEET}
              >
                <Button
                  className="btn_edit"
                  type="ghost"
                  icon={<EditOutlined />}
                  onClick={(e) => {
                    let showNew = !show;
                    setShow(showNew);
                    handleEdit(record);
                  }}
                >
                  Edit
                </Button>
              </Can>
              <Can
                I={Common.Actions.CAN_DELETE}
                a={Common.Modules.MASTER.TEMPLATE_SHINING_SHEET}
              >
                <DeleteButtonWithConfirmComponent
                  title={"Are you sure want to delete Template?"}
                  cancelText={"NO"}
                  okText={"YES"}
                  onDelete={() => handleDelete(record)}
                  children={"Delete"}
                />
              </Can>
              <Can
                I={Common.Actions.CAN_VIEW}
                a={Common.Modules.MASTER.TEMPLATE_SHINING_SHEET}
              >
                <Button
                  className="btn_view"
                  type="ghost"
                  icon={<EyeOutlined />}
                  onClick={(e) => {
                    openInNewTab(
                      `/subcourses/${subcourse_id}/template/${record.id}`
                    );
                  }}
                >
                  View
                </Button>
              </Can>
              <Can
                I={Common.Actions.CAN_EXPORT_DATA}
                a={Common.Modules.MASTER.TEMPLATE_SHINING_SHEET}
              >
              <Button
                  className="btn_view"
                  type="ghost"
                  icon={<MailOutlined />}
                  onClick={(e) => {
                    Modal.confirm({
                      title:
                        "Are you sure want to download Template PDF file?",
                      okText: "Yes",
                      cancelText: "No",
                      icon: <DownloadOutlined />,
                      onOk() {
                        sendPdfInMail(record.id);
                      },
                    });
                  }}
                >
                  Mail
                </Button>
              </Can>
            </ActionWrapper>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Skeleton loading={loading} active>
        <Row
          align="middle"
          justify="space-between"
          gutter={24}
          className="mb-20"
        >
          <Col lg={12}>
            <ContainerHeader title={subCourseName} />
          </Col>
          <Col lg={12} className="text-align-right gx-mt-3 gx-mt-sm-0">
            <Button
              icon={<FilterFilled />}
              onClick={() => setFilterModalOpen(true)}
            >
              Filter
              <span>
                <Badge count={count}></Badge>
              </span>
            </Button>
            <Can
              I={Common.Actions.CAN_ADD}
              a={Common.Modules.MASTER.TEMPLATE_SHINING_SHEET}
            >
              <Button
                type="primary"
                onClick={() => {
                  setRow(undefined);
                  setShow(true);
                }}
                icon={<PlusOutlined />}
              >
                Template
              </Button>
            </Can>
            <Button
              type="primary"
              onClick={() => {
                const url = searchParams.get("r");
                if (url) {
                  const backURL = bufferURLDecode(url);
                  navigate(backURL);
                } else {
                  navigate(`/subcourses`);
                }
              }}
            >
              <i className="fa fa-arrow-left back-icon"></i>
              <span className="gx-d-none gx-d-sm-inline-block ">Back</span>
            </Button>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form onFinish={onFinish} form={form} initialValues={formValues}>
              <div className="filter" style={{ height: "auto" }}>
                <Drawer
                  className="filter-drawer"
                  title="Sub-Course Template Filter"
                  width="1000"
                  visible={filterModalOpen}
                  onClose={() => {
                    setFilterModalOpen(false);
                  }}
                  footer={[
                    <div className="gx-d-flex gx-justify-content-center">
                      <Button
                        className="cancel-filter gx-mr-0"
                        key="back"
                        onClick={onCancel}
                      >
                        <span className="gx-d-none gx-d-sm-block">Cancel</span>
                        <i className="fa fa-close gx-d-sm-none"></i>
                      </Button>
                      <Button
                        className="btn-apply-filter gx-mx-2"
                        key="submit"
                        type="primary"
                        loading={loading}
                        htmlType="submit"
                        form="myForm"
                      >
                        Apply Filter
                      </Button>
                      <Button
                        className="reset-filter"
                        type="default"
                        htmlType="reset"
                        form="myForm"
                      >
                        <span className="gx-d-none gx-d-sm-block">Reset</span>
                        <i className="fa fa-refresh gx-d-sm-none"></i>
                      </Button>
                    </div>,
                  ]}
                >
                  <CommonFilter
                    fielterData={(value) => onFinish(value)}
                    reset={() => onReset()}
                  >
                    <Row gutter={24}>
                      <Col xs={24}>
                        <FloatLabel
                          label="Name"
                          placeholder="Enter Template Name"
                          name="name"
                        >
                          <Form.Item name="name">
                            <Input size="large" />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                    </Row>
                  </CommonFilter>
                </Drawer>
              </div>
            </Form>
          </Col>
        </Row>

        <Card className="rnw-card table-card">
          <TableComponent
            columns={columns}
            dataSource={data?.rows || []}
            loading={loading}
            meta={data?.meta}
          />
        </Card>
        <div className="rnw-main-content">
          {show && (
            <DrawerComponent
              className="template-drawer"
              title={
                row ? "Edit SubCourse Template" : "Create SubCourse Template"
              }
              onClose={() => {
                setShow(false);
              }}
              visible={show}
              permission={disable}
              footer={true}
              label={row ? "Update" : "Submit"}
            >
              <SubCourseTemplateAdd
                data={row}
                onSubmit={updatePage}
                setDisable={setDisable}
              />
            </DrawerComponent>
          )}
        </div>
      </Skeleton>
    </>
  );
};

export default SubCourseTemplate;
