import { createSlice } from "@reduxjs/toolkit";
import { IStudentState } from "./admissionMicroService.model";
import { getStudentData } from "./admissionMicroService.action";
import { RootState } from "../app.model";

export const initialState: IStudentState = {
  studentData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      id: 0,
      gr_id: 0,
      first_name: "",
      middle_name: "",
      last_name: "",
    },
  },
};

export const admissionMicroServiceSlice = createSlice({
  name: "admissionMicroService",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {},
  },
  extraReducers: {
    [getStudentData.pending.type]: (state) => {
      state.studentData.loading = true;
    },
    [getStudentData.fulfilled.type]: (state, action) => {
      state.studentData.data = action.payload.data;
      state.studentData.message = action.payload.message;
      state.studentData.loading = false;
      state.studentData.hasErrors = false;
    },
    [getStudentData.rejected.type]: (state, action) => {
      state.studentData.loading = false;
      state.studentData.hasErrors = true;
      state.studentData.message = action.error.message;
    },
  },
});

// A selector
export const admissionMicroServiceSelector = (state: RootState) =>
  state.admissionMicroService;

export const { clearRemoveMessage } = admissionMicroServiceSlice.actions;

// The reducer
export default admissionMicroServiceSlice.reducer;
