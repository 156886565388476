import request from "src/lib/axios/request";
import { IBatchRecord } from "src/pages/Batch/batches.model";
import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import { IBatch, IBatchStatus, IHoldBatchPayload } from "./batch.model";

class BatchService {
  ENDPOINT = config.baseApiMasters + "/batches";

  public searchBatchData = async (data: any): Promise<IApiResponse<IBatch>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public batchDataCount = async (
    data: IBatch | URLSearchParams | {}
  ): Promise<IApiResponse<IBatch>> => {
    const newData = new URLSearchParams(data.toString());
    newData.delete("batches_status");
    const url = `${this.ENDPOINT}/status-counting`;
    return request({ url, method: "GET", params: newData }).then((res) => {
      return res.data;
    });
  };

  public createNewBatch = async (
    data: IBatchRecord
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public editBatchById = async (
    data: IBatchRecord
  ): Promise<IApiResponse<any>> => {
    const url = `${this.ENDPOINT}/${data.id}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public removeBatchById = async (id: number) => {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public updateBatchStatus = async (
    data: IBatchStatus
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}/status`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };
}

export default new BatchService();
