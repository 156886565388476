import request from "../../lib/axios/request";
import { IApiResponse } from "../../utils/models/common";
import { config } from "src/utils";
import { IPaymentMode } from "./paymentMode.model";

class PaymentModeService {
  ENDPOINT = config.baseApiMasters+ "/common/payment-mode";
    public searchPaymentModeData = async (
        data: any
      ): Promise<IApiResponse<IPaymentMode>> => {
        const url = `${this.ENDPOINT}`;
        return request({ url, method: "GET", params: data }).then((res) => {
          return res.data;
        });
      };
}
export default new PaymentModeService();
