import { config } from "src/utils";
import request from "../../lib/axios/request";
import { ISubcategoryRecord } from "../../pages/SubCategory/Subcategory.model";
import { IApiResponse } from "../../utils/models/common";
import {
  ISubCategoryDetails,
  ISubCategoryStatus,
  IUpdateSubCategory,
} from "./subCategory.model";

class SubCategoryService {
  ENDPOINT = config.baseApiExpense + "/sub-categorie";

  public removeSubCategoryById = async (id: number) => {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public updateSubCategoryStatus = async (
    data: ISubCategoryStatus
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}/status`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

  public editSubCategoryById = async (
    data: ISubcategoryRecord
  ): Promise<IApiResponse<IUpdateSubCategory>> => {
    const url = `${this.ENDPOINT}/${data.id}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public createNewSubCategory = async (
    data: ISubcategoryRecord
  ): Promise<IApiResponse<number>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public searchSubCategoryData = async (
    data: ISubCategoryDetails
  ): Promise<IApiResponse<ISubCategoryDetails[]>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
}

export default new SubCategoryService();
