import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { IHardwareState } from "./hardware.model";
import { createNewHardware, editHardwareById, searchHardwareData } from "./hardware.action";

export const initialState: IHardwareState = {
    hardwareData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  createHardware: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  editById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
};

export const hardwareSlice = createSlice({
  name: "hardware",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.editById.message = "";
      state.hardwareData.message = "";
      state.createHardware.message = "";
    },
  },
  extraReducers: {
    //get hardware
    [searchHardwareData.pending.type]: (state) => {
      state.hardwareData.loading = true;
    },
    [searchHardwareData.fulfilled.type]: (state, action) => {
      state.hardwareData.data = action.payload.data;
      state.hardwareData.message = action.payload.message;
      state.hardwareData.loading = false;
      state.hardwareData.hasErrors = false;
    },
    [searchHardwareData.rejected.type]: (state, action) => {
      state.hardwareData.loading = false;
      state.hardwareData.hasErrors = true;
      state.hardwareData.message = action.error.message;
    },

    //create hardware
    [createNewHardware.pending.type]: (state) => {
      state.createHardware.loading = true;
    },
    [createNewHardware.fulfilled.type]: (state, action) => {
      state.createHardware.message = action.payload.message;
      state.createHardware.hasErrors = action.payload.error;
      state.createHardware.loading = false;
      state.createHardware.hasErrors = false;
    },
    [createNewHardware.rejected.type]: (state, action) => {
      state.createHardware.loading = false;
      state.createHardware.hasErrors = true;
      state.createHardware.message = action.error.message;
    },

    //edit hardware
    [editHardwareById.pending.type]: (state) => {
      state.editById.loading = true;
    },
    [editHardwareById.fulfilled.type]: (state, action) => {
      state.editById.message = action.payload.message;
      state.editById.loading = false;
      state.editById.hasErrors = false;
    },
    [editHardwareById.rejected.type]: (state, action) => {
      state.editById.loading = false;
      state.editById.hasErrors = true;
      state.editById.message = action.error.message;
    },
  },
});

// A selector
export const hardwareSelector = (state: RootState) => state.hardware;

export const { clearRemoveMessage } = hardwareSlice.actions;

// The reducer
export default hardwareSlice.reducer;
