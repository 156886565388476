export enum LoginPageMeta {
  PAGE_TITLE = "Login",
  MAIN_TITLE = "Home"
}

export enum TransferAdmissionPageMeta {
  PAGE_TITLE = "Transfer Admission",
}

export enum IncomePageMeta {
  PAGE_TITLE = "Income",
}

export enum ICourseAdmissionLetterMeta {
  PAGE_TITLE = "Admission Course Letter",
}

export enum IFeesAdmissionLetterMeta {
  PAGE_TITLE = "Admission Fees Letter",
}

export enum IInstallmentReportMeta {
  PAGE_TITLE = "Installment Report"
}