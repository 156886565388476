import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import {
  createNewTemplateSigningSheet,
  deleteTemplateShiningSheetId,
  editTemplateShiningSheetBySubCourseIdAndId,
  getTemplateShiningSheetBySubCourseIdAndId,
  searchTemplateSigningSheetDataByScID,
  sendTemplateInMail,
  updateTemplateShiningSheetStatus,
  updateTemplateShiningSheetTopicStatus,
} from "./templateSignningsheet.action";
import { ITemplateSigningSheetState } from "./templateSignningsheet.model";

export const initialState: ITemplateSigningSheetState = {
  templateSigningSheetData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  createTemplate: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  getById: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {},
  },
  editById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  removeById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  updateTemplateStatus: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  updateTemplateTopicStatus: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  templatePdfData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  isSignIn: {
    data: [],
  },
};

export const templateSigningSheetSlice = createSlice({
  name: "templateSigningSheet",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.templateSigningSheetData.message = "";
      state.createTemplate.message = "";
      state.getById.message = "";
      state.editById.message = "";
      state.removeById.message = "";
      state.updateTemplateStatus.message = "";
      state.updateTemplateTopicStatus.message = "";
      state.templatePdfData.message = "";
    },
    setIsSignIn: (state, action) => {
      state.isSignIn.data = action.payload;
    },
  },
  extraReducers: {
    //Get Template Signing sheets
    [searchTemplateSigningSheetDataByScID.pending.type]: (state) => {
      state.templateSigningSheetData.loading = true;
    },
    [searchTemplateSigningSheetDataByScID.fulfilled.type]: (state, action) => {
      state.templateSigningSheetData.data = action.payload.data;
      state.templateSigningSheetData.message = action.payload.message;
      state.templateSigningSheetData.loading = false;
      state.templateSigningSheetData.hasErrors = false;
    },
    [searchTemplateSigningSheetDataByScID.rejected.type]: (state, action) => {
      state.templateSigningSheetData.loading = false;
      state.templateSigningSheetData.hasErrors = true;
      state.templateSigningSheetData.message = action.error.message;
    },

    //create template
    [createNewTemplateSigningSheet.pending.type]: (state) => {
      state.createTemplate.loading = true;
    },
    [createNewTemplateSigningSheet.fulfilled.type]: (state, action) => {
      state.createTemplate.message = action.payload.message;
      state.createTemplate.hasErrors = action.payload.error;
      state.createTemplate.loading = false;
      state.createTemplate.hasErrors = false;
    },
    [createNewTemplateSigningSheet.rejected.type]: (state, action) => {
      state.createTemplate.loading = false;
      state.createTemplate.hasErrors = true;
      state.createTemplate.message = action.error.message;
    },

    //Get Template Signing sheet By id
    [getTemplateShiningSheetBySubCourseIdAndId.pending.type]: (state) => {
      state.getById.loading = true;
    },
    [getTemplateShiningSheetBySubCourseIdAndId.fulfilled.type]: (
      state,
      action
    ) => {
      state.getById.data = action.payload.data;
      state.getById.message = action.payload.message;
      state.getById.loading = false;
      state.getById.hasErrors = false;
    },
    [getTemplateShiningSheetBySubCourseIdAndId.rejected.type]: (
      state,
      action
    ) => {
      state.getById.loading = false;
      state.getById.hasErrors = true;
      state.getById.message = action.error.message;
    },

    //Edit template
    [editTemplateShiningSheetBySubCourseIdAndId.pending.type]: (state) => {
      state.editById.loading = true;
    },
    [editTemplateShiningSheetBySubCourseIdAndId.fulfilled.type]: (
      state,
      action
    ) => {
      state.editById.message = action.payload.message;
      state.editById.hasErrors = action.payload.error;
      state.editById.loading = false;
      state.editById.hasErrors = false;
    },
    [editTemplateShiningSheetBySubCourseIdAndId.rejected.type]: (
      state,
      action
    ) => {
      state.editById.loading = false;
      state.editById.hasErrors = true;
      state.editById.message = action.error.message;
    },

    //UPDATE template status
    [updateTemplateShiningSheetStatus.pending.type]: (state) => {
      state.updateTemplateStatus.loading = true;
    },
    [updateTemplateShiningSheetStatus.fulfilled.type]: (state, action) => {
      state.updateTemplateStatus.message = action.payload.message;
      state.updateTemplateStatus.hasErrors = action.payload.error;
      state.updateTemplateStatus.loading = false;
      state.updateTemplateStatus.hasErrors = false;
    },
    [updateTemplateShiningSheetStatus.rejected.type]: (state, action) => {
      state.updateTemplateStatus.loading = false;
      state.updateTemplateStatus.hasErrors = true;
      state.updateTemplateStatus.message = action.error.message;
    },

    //UPDATE template topic status
    [updateTemplateShiningSheetTopicStatus.pending.type]: (state) => {
      state.updateTemplateTopicStatus.loading = true;
    },
    [updateTemplateShiningSheetTopicStatus.fulfilled.type]: (state, action) => {
      state.updateTemplateTopicStatus.message = action.payload.message;
      state.updateTemplateTopicStatus.hasErrors = action.payload.error;
      state.updateTemplateTopicStatus.loading = false;
      state.updateTemplateTopicStatus.hasErrors = false;
    },
    [updateTemplateShiningSheetTopicStatus.rejected.type]: (state, action) => {
      state.updateTemplateTopicStatus.loading = false;
      state.updateTemplateTopicStatus.hasErrors = true;
      state.updateTemplateTopicStatus.message = action.error.message;
    },

    //signing sheet remove by id
    [deleteTemplateShiningSheetId.pending.type]: (state) => {
      state.removeById.loading = true;
    },
    [deleteTemplateShiningSheetId.fulfilled.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = false;
      state.removeById.message = action.payload.message;
    },
    [deleteTemplateShiningSheetId.rejected.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = true;
      state.removeById.message = action.error.message;
    },

    //Send template in mail
    [sendTemplateInMail.pending.type]: (state) => {
      state.templatePdfData.loading = true;
    },
    [sendTemplateInMail.fulfilled.type]: (state, action) => {
      state.templatePdfData.loading = false;
      state.templatePdfData.hasErrors = false;
      state.templatePdfData.message = action.payload.message;
    },
    [sendTemplateInMail.rejected.type]: (state, action) => {
      state.templatePdfData.loading = false;
      state.templatePdfData.hasErrors = true;
      state.templatePdfData.message = action.error.message;
    },

  },
});

// A selector
export const templateSigningSheetSelector = (state: RootState) =>
  state.templateSigningSheet;

export const { clearRemoveMessage, setIsSignIn } =
  templateSigningSheetSlice.actions;

// The reducer
export default templateSigningSheetSlice.reducer;
