import { Col, Row } from "antd";
import DashboardTableCard from "../DashboardTableCard";
import { useEffect, useState } from "react";
import {
  IBranchWiseAllMissingAdmissionReport,
  IMissingCountData,
} from "../BranchWiseAllMissingAdmissionReport/BranchWiseAllMissingAdmissionReport.model";
import { MissingDetailsData } from "src/services/students/student.model";

const MissingAdmissionAnalysisCard = (props: IBranchWiseAllMissingAdmissionReport) => {
  const { missingAdmissionReportStatics } = props;

  const [countData, setCountData] = useState<IMissingCountData>();

  useEffect(() => {
    if (missingAdmissionReportStatics.length > 0) {
      missingAdmissionReportStatics.map((admission: MissingDetailsData) => {
        setCountData({
          totalAdmission: admission.totalAdmissionCount,
          totalMissingAdmission: admission.missingCount,
          totalCompleteAdmission: admission.completedCount,
        });
      });
    }
  }, [missingAdmissionReportStatics]);
  return (
    <>
      <div className="dashboard-card-main">
        <h4 className="gx-pb-2">Branch Wise Admission</h4>
        <Row gutter={24} className="gx-px-0 gx-justify-content-between ">
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            className="gx-h-auto gx-mb-2 gx-w-100 gx-px-1"
          >
            <DashboardTableCard
              title="Total Admission"
              value={countData?.totalAdmission}
              classData={""}
            />
          </Col>
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            className="gx-h-auto gx-mb-2 gx-w-100 gx-px-1"
          >
            <DashboardTableCard
              title="Total Missing Admission"
              value={countData?.totalMissingAdmission}
              percentageValue={
                (Number(countData?.totalMissingAdmission) /
                  Number(countData?.totalAdmission)) *
                  100 || 0
              }
              classData={""}
            />
          </Col>
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            className="gx-h-auto gx-mb-2 gx-w-100 gx-px-1"
          >
            <DashboardTableCard
              title="Total Complete Admission"
              value={countData?.totalCompleteAdmission}
              percentageValue={
                (Number(countData?.totalCompleteAdmission) /
                  Number(countData?.totalAdmission)) *
                  100 || 0
              }
              classData={""}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MissingAdmissionAnalysisCard;
